<template>
  <v-row>
    <v-col cols="12">
      <v-form>
        <v-container>
          <v-row style="justify-content: space-between;">
            <v-select
              v-model="localScanType"
              label="Workflow"
              :items="SCAN_WORKFLOW"
              outlined
              dense
              class="col-md-4 mr-2"
            />
            
            <v-text-field
              v-model="barcode"
              label="Scan a packing slip or enter an order number."
              class="mr-2"
              outlined
              clearable
              dense
              hide-details
              @keydown.enter.prevent="manualScan"
            />

            <v-btn
              color="primary"
              :loading="loading"
              @click="manualScan"
            >
              Find Order
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import SCAN_CONSTANTS from '@/constants/scan';

export default {
  props: {
    scanType: {
    type: String,
    default() {
      return SCAN_CONSTANTS.SCAN_TO_PRINT;
    }
  }
  },
  data() {
    return {
      barcode: null,
      loading: false,
      locked: false
    };
  },
  computed: {
    SCAN_WORKFLOW() {
      return SCAN_CONSTANTS.SCAN_WORKFLOW;
    },
    localScanType: {
      get() {
        return this.scanType;
      },
      set(value) {
        this.$emit('update:scanType', value);
      }
    }
  },
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    if(!this.$barcodeScanner.hasListener()){
      this.$barcodeScanner.init(this.onBarcodeScanned);
    }
  },
  destroyed() {
    // Remove listener when component is destroyed
    if(this.$barcodeScanner.hasListener()){
      this.$barcodeScanner.destroy();
    }
  },
  mounted() {
    const queryParams = new URLSearchParams(window.location.search);
    const orderId = queryParams.get('orderId');
    const scanType = queryParams.get('type');

    // Map the scanType query parameter to SCAN_CONSTANTS
    const mappedScanType = (() => {
      switch (scanType) {
        case 'scan_to_print':
          return SCAN_CONSTANTS.SCAN_TO_PRINT;
        case 'scan_to_verify':
          return SCAN_CONSTANTS.SCAN_TO_VERIFY;
        case 'scan_to_verify_and_print':
          return SCAN_CONSTANTS.SCAN_TO_VERIFY_AND_PRINT;
        default:
          console.warn('Invalid or missing scan type in query parameters - must not have been supplied.');
          return null;
      }
    })();

    if (orderId && mappedScanType) {
      this.onBarcodeScanned(orderId);
      this.localScanType = mappedScanType; // Use the mapped constant
    }

    if (!this.$barcodeScanner.hasListener()) {
      this.$barcodeScanner.init(this.onBarcodeScanned);
    }
  },
  methods: {
    manualScan() {
      console.log('Manual scan:', this.barcode);
      this.onBarcodeScanned(this.barcode);
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      console.log('Scanned barcode:', barcode)
      if (!barcode || this.locked) {
        return;
      }

      this.locked = true;

      this.scan(barcode).then(res => {
        // console.log(res);
        this.$emit('scanShipment', {
          order: res.order,
          scanType: this.localScanType
        });
      }).finally(() => {
        this.locked = false;
        this.barcode = null;
      });
    },
    scan(barcode) {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.$http
          .post('/order-verification/' + barcode + '/prepare')
          .then(response => response.data)
          .then(response => {
            this.playSuccessSound();
            resolve({
              order: response
            });
          })
          .catch(err => {
            this.playErrorSound();
            console.log(err.response);
            this.onError(err.response?.data?.errors || 'Order Not Found Error');
            reject({});
          })
          .finally(() => {
            this.loading = false
          });
      });
    },
    onError(err) {
      this.$notify({
        group: 'main',
        text: err,
        duration: 3000,
        type: 'error'
      });
    }
  } 
};
</script>
