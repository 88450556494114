<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined>
        <v-card-subtitle>
          <v-row>
            <v-col class="text-right">
              <span v-if="autoRefresh">
                <v-icon left>
                  mdi-loading mdi-spin
                </v-icon>
                Auto Refresh in {{ countDown }}
              </span>
              <span v-else>
                <v-icon left>
                  mdi-check
                </v-icon>
                Auto Refresh is off
              </span>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            item-key="id"
            :options.sync="options"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [5, 10, 15]
            }"
            :items-per-page="25"
            dense
            @click:row="showAdjustments"
          >
            <template #item.id="{ item }">
              {{ item.id }}
            </template>

            <template #item.completed_at="{ item }">
              <span v-if="item.completed_at">
                {{ item.completed_at | datetime_short }}
              </span>
            </template>

            <template #item.total="{ item }">
              {{ item.total | currency }}
            </template>

            <template #item.file="{ item }">
              <a
                v-if="item.file"
                href="javascript:void(0);"
                @click="!loading && download(item.file)"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      small
                      color="primary"
                      v-on="on"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download Calculated Charged</span>
                </v-tooltip>
               
              </a>
            </template>

            <template #item.error_logs="{ item }">
              <a
                v-if="item.error_logs"
                href="javascript:void(0);"
                @click="!loading && download(item.error_logs)"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon
                      small
                      color="primary"
                      v-on="on"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download Error Logs</span>
                </v-tooltip>
               
              </a>
            </template>

            <template #item.status="{ item }">
              <span v-if="item.error">
                <v-tooltip
                  bottom
                  color="error"
                >
                  <template #activator="{ on }">
                    <div style="display: flex; align-items: center;">
                      <v-icon
                        small
                        color="red"
                        class="mr-1"
                        v-on="on"
                      >
                        mdi-alert
                      </v-icon>
                      <span>Error</span>
                    </div>
                  </template>
                  <span>{{ item.error }}</span>
                </v-tooltip>
                 
              </span>
              <span v-else-if="item.processing === 0">
                <div style="display: flex; align-items: center;">
                  <v-icon
                    small
                    class="mr-1"
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                  <span>Completed</span>
                </div>
              </span>
              <span v-else>
                <div style="display: flex; align-items: center;">
                  <v-icon
                    small
                    class="mr-1"
                    color="primary"
                  >
                    mdi-loading mdi-spin
                  </v-icon>
                  <span>Processing</span>
                </div>
              </span>
            </template>

            <template #item.calculation_status="{ item }">
              <div style="display: flex; align-items: center;">
                <v-icon
                  v-if="item.calculation_status === 'Failed'"
                  small
                  class="mr-1"
                  color="red"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-else-if="item.calculation_status === 'Pending'"
                  small
                  class="mr-1"
                >
                  mdi-clock-outline
                </v-icon>
                <v-icon
                  v-else-if="item.calculation_status === 'Calculated'"
                  small
                  class="mr-1"
                  color="success"
                >
                  mdi-check
                </v-icon>
                <span>{{ item.calculation_status }}</span>
              </div>
            </template>

            <template #item.charge_status="{ item }">
              <div style="display: flex; align-items: center;">
                <v-icon
                  v-if="item.charge_status === 'Failed'"
                  small
                  class="mr-1"
                  color="red"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-else-if="item.charge_status === 'Pending'"
                  small
                  class="mr-1"
                >
                  mdi-clock-outline
                </v-icon>
                <v-icon
                  v-else-if="item.charge_status === 'Charged'"
                  small
                  class="mr-1"
                  color="success"
                >
                  mdi-check
                </v-icon>
                <span>{{ item.charge_status }}</span>
              </div>
            </template>
            

            <template #item.already_charged="{ item }">
              <span v-if="item.already_charged">
                Yes
              </span>
              <span v-else>
                No
              </span>
            </template>

            <template #item.actions="{ item }">
              <div style="display: flex;">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      small
                      :disabled="item.charge_status === 'Charged' || item.processing === 1"
                      @click.stop="recalculateCharges(item.id)"
                      v-on="on"
                    >
                      <v-icon small>
                        mdi-calculator
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Recalculate Charges</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      small
                      icon
                      :disabled="item.charge_status === 'Charged' || item.processing === 1 || item.calculation_status !== 'Calculated'"
                      @click.stop="saveCharges(item)"
                      v-on="on"
                    >
                      <v-icon small>
                        mdi-account-cash
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Submit Charges</span>
                </v-tooltip>

                <!-- delete -->
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-btn
                      small
                      icon
                      :disabled="item.processing === 1"
                      @click.stop="deletePostageAdjustment(item.id)"
                      v-on="on"
                    >
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Adjustment</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      v-if="selectedAdjustment"
      cols="12"
    >
      <v-card outlined>
        <v-card-text>
          <v-row>
            <v-col>
              <h2>Adjustment: {{ selectedAdjustment.id }} - {{ selectedAdjustment.type }}</h2>
              <h3> {{ selectedAdjustment.original_file_name }}</h3>
            </v-col>
            <v-col cols="auto">
              <v-btn
                class="float-right"
                color="primary"
                :disabled="selectedAdjustment.charge_status !== 'Pending' || selectedAdjustment.processing === 1 || selectedAdjustment.calculation_status !== 'Calculated'"
                @click="saveCharges(selectedAdjustment)"
              >
                <v-icon
                  small
                  left
                >
                  mdi-send
                </v-icon>Submit Charges
              </v-btn>
            </v-col>
          </v-row>
        

           
     
          <v-row dense>
            <v-col cols="12">
              Subtotal: {{ selectedAdjustment.subtotal | currency }}
              <br>
              Tax: {{ selectedAdjustment.tax | currency }}
              <br>
              Total {{ selectedAdjustment.total | currency }}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col />
            <v-col cols="auto">
              <v-btn
                class="float-right"
                color="success"
                
                small
                :disabled="selected.length === 0 || selectedAdjustment.charge_status === 'Charged'"
                @click="addCharges"
              >
                <v-icon
                  small
                  left
                >
                  mdi-check
                </v-icon> Enable
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn
                small
              
                class="float-right"
                color="error"
                :disabled="selected.length === 0 || selectedAdjustment.charge_status === 'Charged'"
                @click="removeCharges"
              >
                <v-icon
                  small
                  left
                >
                  mdi-close
                </v-icon> Disable
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        
        <v-data-table
          v-model="selected"
          dense
          :loading="loadingCharges"
          loading-text="Processing"
          :headers="charges_headers"
          :items="chargeItems"
          :server-items-length="totalChargeItems"
          :items-per-page="50"
          show-select
        >
          <template #item.subtotal="{ item }">
            {{ item.subtotal | currency }}
          </template>

          <template #item.tax="{ item }">
            {{ item.tax | currency }}
          </template>

          <template #item.total="{ item }">
            {{ item.total | currency }}
          </template>

          <template #item.already_charged="{ item }">
            <span v-if="item.already_charged == 'Yes'">
              <v-icon
                small
                class="mr-1"
                color="success"
              >
                mdi-check
              </v-icon>
            </span>
            <span v-else>
              <v-icon
                small
                class="mr-1"
                color="error"
              >
                mdi-close
              </v-icon>
            </span>
          </template>

          <template #item.apply_charge="{ item }">
            <span v-if="item.apply_charge">
              <v-icon
                small
                class="mr-1"
                color="success"
              >
                mdi-check
              </v-icon>
            </span>
            <span v-else>
              <v-icon
                small
                class="mr-1"
                color="error"
              >
                mdi-close
              </v-icon>
            </span>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
const COUNTDOWN = 10;

export default {
  props: {
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      headers: [
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Original File', value: 'original_file_name', sortable: true },
        { text: 'Line Count', value: 'line_count', sortable: true },
        { text: 'Charge Count', value: 'charge_count', sortable: true },
        { text: 'Total', value: 'total', align: 'right', sortable: false },
        { text: 'Revised Total', value: 'revised_total', align: 'right', sortable: false },
        { text: 'Type', value: 'type', sortable: true },
        { text: 'File', value: 'file', sortable: true },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Error Logs', value: 'error_logs', sortable: false },
        { text: 'Calculation Status', value: 'calculation_status', sortable: false },
        { text: 'Charge Status', value: 'charge_status', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ],
      charges_headers: [
        { text: 'Ship Code', value: 'ship_code' },
        { text: 'User', value: 'user_name' },
        { text: 'Description', value: 'description' },
        { text: 'Amount', value: 'subtotal' },
        { text: 'Tax', value: 'tax' },
        { text: 'Total', value: 'total' },
        { text: 'Already Charged', value: 'already_charged' },
        { text: 'Apply Charge', value: 'apply_charge' }

      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      selected: [],
      subtotal: 0,
      tax: 0,
      totalAmount: 0,
      loading: false,
      loadingCharges: false,
      totalItems: 0,
      items: [],
      currentChargesId: null,
      alreadyCharged: 0,
      chargeItems: [],
      totalChargeItems: 0,
      countDown: COUNTDOWN,
      autoRefresh: false,
      selectedAdjustment: null,
      timer: null,
      timerActive: false
    };
  },
  computed: {
    disableSaveCharge() {
      return this.chargeItems.length === 0 || this.alreadyCharged === 1;
    }
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.updateTable();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        this.timerActive = true;
        this.timer = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.stopTimer();
        this.updateTable();
      }
    },
    stopTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
        this.timerActive = false;
      }
    },
    updateTable() {
      this.selected = [];
      this.getDataFromApi().then(data => {
        this.items = data.items;
        this.totalItems = data.total;

        this.autoRefresh = this.items.some(item => item.processing === 1);
        if (this.autoRefresh && !this.timerActive) {
          this.countDown = COUNTDOWN;
          this.countDownTimer();
        }
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const params = {
          page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0]
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .get('/admin/postage-adjustments', {
            params,
            cancelToken: this.cancelSource.token
          })
          .then(response => response.data)
          .then(response => {
            resolve({
              items: response.data,
              total: response.total,
              pages: response.last_page
            });
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    deletePostageAdjustment(id) {
      if (!confirm('Are you sure you want to delete this adjustment?')) {
        return;
      }

      this.$http
        .delete(`/admin/postage-adjustments/${id}`)
        .then(() => {
          this.updateTable();
        })
        .catch(err => {
          const errorMessage = err.response && err.response.status === 422
            ? err.response.data.error
            : 'There was an error deleting the adjustment';
          this.errorNotify(errorMessage);
        });
    },
    saveCharges(adjustmentRequest) {
      // confirm
      if (!confirm('Are you sure you want to save the charges?')) {
        return;
      }

      const params = {
        postage_adjustment_request_id: adjustmentRequest.id
      };

      this.$http
        .post('admin/postage-adjustments/charge-users', params)
        .then(() => {
         this.successNotify('Charges Saved');
          this.updateTable();
        })
        .catch(err => {
          const errorMessage = err.response && err.response.status === 422
            ? err.response.data.error
            : 'There was an error submitting the charges';
          this.errorNotify(errorMessage);
        })
        .finally(() => {
          this.updateTable();
        });
    },
    removeCharges() {
      const postage_adjustment_ids = this.selected.map(pa => pa.id);

      const params = { postage_adjustment_ids };

      this.$http
        .post('admin/postage-adjustments/remove-charge', params)
        .then(() => {
          this.successNotify('Charges removed successfully');
        })
        .catch(err => {
          const errorMessage = err.response && err.response.status === 422
            ? err.response.data.error
            : 'There was an error removing the charge';
          this.errorNotify(errorMessage);
          console.log('test', this.selectedAdjustment);
        })
        .finally(() => {
           this.showAdjustments(this.selectedAdjustment);
        });
    },

    addCharges() {
      const postage_adjustment_ids = this.selected.map(pa => pa.id);

      const params = { postage_adjustment_ids };

      this.$http
        .post('admin/postage-adjustments/add-charge', params)
        .then(() => {
          this.successNotify('Charges added successfully');
        })
        .catch(err => {
          const errorMessage = err.response && err.response.status === 422
            ? err.response.data.error
            : 'There was an error removing the charge';
          this.errorNotify(errorMessage);
          console.log('test', this.selectedAdjustment);
        })
        .finally(() => {
           this.showAdjustments(this.selectedAdjustment);
        });
    },

    download(data) {
      this.loading = true
      this.$http
        .post('/admin/postage-adjustments/download', { file: data })
        .then(response => {
          this.downloadFileUrl(response.data, data);
        })
        .finally(() => {
          this.loading = false
        });
    },

    showAdjustments(adjustmentRequest) {
      this.selectedAdjustment = adjustmentRequest;
      this.loadingCharges = true;

      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const params = {
          postage_adjustment_request_id: adjustmentRequest.id,
          page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0]
        };

        this.$http
          .get('/admin/postage-adjustments/charges', { params })
          .then(response => response.data)
          .then(response => {
            this.chargeItems = response.charge_data;
            this.totalChargeItems = response.total;
            this.pages = response.last_page;
            this.subtotal = response.subtotal;
            this.tax = response.tax;
            this.totalAmount = response.totalAmount;

            resolve(response);
          })
          .catch(err => {
            const errorMessage = err.response && err.response.status === 422
              ? err.response.data.error
              : 'There was an error submitting the charges';
            this.errorNotify(errorMessage);

            reject(err);
          })
          .finally(() => {
            this.loadingCharges = false;
          });
      });
    },
    recalculateCharges(id) {
      const params = {
        postage_adjustment_request_id: id
      };

      this.$http
        .post('admin/postage-adjustments/recalculate-charges', params)
        .then(() => {
          this.updateTable();
        })
        .catch(err => {
          const errorMessage = err.response && err.response.status === 422
            ? err.response.data.error
            : 'There was an error recalculating the charges';
          this.errorNotify(errorMessage);
        })
        .finally(() => {
          this.updateTable();
        });
    },
    retryCharges(id) {
      const params = {
        postage_adjustment_request_id: id
      };

      this.$http
        .post('admin/postage-adjustments/retry-charges', params)
        .then(() => {
          this.successNotify('Charges Saved');
        })
        .catch(err => {
          const errorMessage = err.response && err.response.status === 422
            ? err.response.data.error
            : 'There was an error submitting the charges';

          this.errorNotify(errorMessage);
        })
        .finally(() => {
          this.updateTable();
        });
    }
  }
};
</script>
