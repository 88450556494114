<template>
  <FullPageLayout
    title="Referral Dashboard"
    :bread-crumbs="breadCrumbs"
  >
    <template #default>
      <v-row
        v-if="$auth.user?.reference_code !== null"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
          >
            <v-card-title>
              <h3 class="mb-3">
                Refer Friends
              </h3>
            </v-card-title>
            <v-card-text>
              <h4>Share your personal referral link</h4>
              <strong class="url-reference">{{ url }}</strong>

              <v-container class="px-0">
                <v-btn
                  v-clipboard:copy="url"
                  v-clipboard:success="onCopy"
                  outlined
                  small
                  color="primary"
                >
                  {{ btnText }}
                </v-btn>
              </v-container>

              <v-divider />
              <span>Total Credits Earned</span>

              <strong
                v-if="stats"
                style="padding-top: 5px"
                class="strong-reference"
              >{{ stats.total_earned | currency }}</strong>
              <span>Total Referrals</span>

              <v-dialog
                v-model="dialog1"
                width="500"
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h3 mb-3 lighten-2">
                    Total Referrals
                  </v-card-title>

                  <v-card-text>
                    This is the total amount of people that have completed
                    registration using your referral code.
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="dialog1 = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <strong
                v-if="stats"
                class="strong-reference"
              >{{
                stats.total_referred
              }}</strong>

              <span>Activated Referrals</span>
              <v-dialog
                v-model="dialog2"
                width="500"
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    small
                    v-on="on"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h3 mb-3 lighten-2">
                    Activated Referrals
                  </v-card-title>

                  <v-card-text>
                    This is the total amount of people that have completed
                    registration using your referral code AND have loaded the
                    required amount to active the payout.
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="dialog2 = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <strong
                v-if="stats"
                class="strong-reference"
              >{{
                stats.total_active
              }}</strong>
              <v-divider />

              <div class="text-center">
                <h4>Follow Us</h4>

                <v-tooltip
                  v-for="(button, index) in buttons"
                  :key="index"
                  top
                >
                  <template #activator="{ on }">
                    <v-btn
                      text
                      icon
                      :color="button.color"
                      target="_blank"
                      :href="button.url"
                      class="mr-2"
                      v-on="on"
                    >
                      <v-icon large>
                        {{ button.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Follow us on {{ button.text }}</span>
                </v-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Graph Card -->
        <v-col
          cols="12"
          lg="8"
        >
          <v-card
            class="elevation-0"
            style="border: 1px solid #e7e7e7"
            height="100%"
          >
            <v-card-title>
              <h3 class="mb-3">
                Referral Earnings
              </h3>
            </v-card-title>

            <v-card-text v-if="referred.length > 0">
              <bar-chart
                :chart-data="creditsByDate"
                :options="defaultOptions"
              />
            </v-card-text>
            <v-card-text v-else>
              <v-row

                class="mt-12"
              >
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <v-icon :size="100">
                    fas fa-piggy-bank
                  </v-icon>
                </v-col>
              </v-row>
              <v-row
                justify-center
                class="mt-12 mb-12"
              >
                <v-col
                  cols="12"
                  class="text-center"
                >
                  <h2>Start earning by referring friends to Stallion Express</h2>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- <v-col xs6>
          <v-card
            class="elevation-0"
            style="border: 1px solid #e7e7e7"
            height="100%"
            v-if="referred.length > 0"
          >
            <v-card-title>
              <h3 class="mb-3">Total Referrals</h3>
            </v-card-title>
            <v-card-text>
              <line-chart
                :height="200"
                :chartData="referralsByDate"
                :options="defaultOptionsLine"
              ></line-chart>
            </v-card-text>
          </v-card>
        </v-col> -->

        <v-col cols="12">
          <v-card
            v-if="referred.length > 0"
            class="elevation-0"
            style="border: 1px solid #e7e7e7"
            height="100%"
          >
            <v-card-title>
              <h3 class="mb-3">
                Activated Referrals
              </h3>
            </v-card-title>
            <v-card-text>
              <line-chart
                :height="200"
                :chart-data="activeReferralsByDate"
                :options="defaultOptionsLine"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card
            v-if="referred.length > 0"
            class="elevation-0"
            style="border: 1px solid #e7e7e7"
            height="100%"
          >
            <v-card-title>
              <h3 class="mb-3">
                Users Referred
              </h3>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="referred"
                :items-per-page="5"
              >
                <template #item.created_at="{ item }">
                  {{ item.created_at | date_pretty }}
                  <br>
                  ({{ item.created_at | datetime_from }})
                </template>

                <template #item.client="{ item }">
                  {{ item | client }}
                </template>

           
                <template #item.payout_at="{ item }">
                  <div v-if="item.payout_at">
                    {{ item.payout_at | date_pretty }}
                    <br>
                    ({{ item.payout_at | datetime_from }})
                  </div>
                  <div v-else>
                    N/A
                  </div>
                </template>
                <template #item.is_activated="{ item }">
                  <div v-if="item.is_activated">
                    Activated
                  </div>
                  <div v-else>
                    Not Activated
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-else
      >
        <v-col
          md8
          offset-md2
          mt-4
        >
          <v-card
            class="elevation-0 pa-5"
            style="border: 1px solid #e7e7e7"
          >
            <v-card-text>
              <v-col cols="12">
                <h1>Get your reference code.</h1>
              </v-col>
              <v-col cols="12">
                Hi there and welcome to your Stallion Express referrals
                dashboard!
                <br>
                <br>To refer someone and earn rewards, click on the button
                below to get your unique URL and share it with whoever you want.
                <br>
                <br>Once the person you referred uses your unique link to
                register and loads $100 (added up over time or as a one time
                payment) into their account, their referral status will be
                activated and you will both receive $25.
              </v-col>
              <v-col
                cols="12"
                text-center
              >
                <v-btn
                  large
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="getReferalCode"
                >
                  Activate Referral Code
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="commissionStructureCount > 0">
        <v-col
          cols="12"
          md="12"
        >
          <commission-index />
        </v-col>
      </v-row>
    </template>
  </FullPageLayout>
</template>

<script>
import CommissionIndex from '../commissions/index.vue';

export default {
  metaInfo: {
    title: "Referral Dashboard | Stallion Express"
  },
  components: {
    CommissionIndex
  },
  data() {
    return {
      commissionStructureCount: 0,
      dialog1: null,
      dialog2: null,
      headers: [
        { text: 'Client', value: 'client' },
        // { text: 'Status', value: 'is_activated' },
        // { text: 'Activated At', value: 'payout_at' },
        { text: 'Referred At', value: 'created_at' }
      ],
      referred: [],
      creditsByDate: null,
      activeReferralsByDate: null,
      referralsByDate: null,

      referenceCode: '',
      numOfReferrals: 0,
      numToNextTier: 0,
      current_tier_id: 0,

      host: window.location.origin,
      stats: null,
      btnText: 'Copy',
      history: [],
      loading: false,
      referrals: [],
      buttons: [
        {
          text: 'Instagram',
          color: '#E1306C',
          icon: 'fab fa-instagram',
          url: 'https://www.instagram.com/shipstallion'
        },
        {
          text: 'TikTok',
          color: 'black',
          icon: 'fab fa-tiktok',
          url: 'https://www.tiktok.com/@shipstallion'
        },
        {
          text: 'Facebook',
          color: '#4267B2',
          icon: 'fab fa-facebook',
          url: 'https://www.facebook.com/ShipStallionExpress/'
        },
        {
          text: 'YouTube',
          color: '#ff0200',
          icon: 'fab fa-youtube',
          url: 'https://www.youtube.com/channel/UCOqj6yyha2lKUkvjmaYHmSA'
        }
      ],

      defaultOptions: {
        responsive: true, // my new default options
        maintainAspectRatio: false, // my new default options
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return '$' + value;
                }
              },
              gridLines: {
                display: true // my new default options
              },
              maxBarThickness: 30
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
        //  title: {
        //     display: true,
        //     text: 'Previous 12 Months'
        // }
      },
      defaultOptionsLine: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                precision: 0
              },
              gridLines: {
                display: true
              }
            }
          ],

          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      },
      breadCrumbs: [
        {
          text: 'Dashboard',
          exact: true,
          to: '/dashboard'
        },
        {
          text: 'Referrals',
          exact: true,
          to: '/referrals'
        }
      ]
    };
  },
  computed: {
    url: function () {
      return `${this.host}/register/${this.$auth.user?.reference_code}`;
    }
  },

  mounted() {
    this.getReferralStats();
    this.getCommissionStructureCount()
  },
  methods: {
    onCopy: function () {
      this.btnText = 'Copied!';
    },

    getReferralStats() {
      this.loading2 = true;
      this.$http
        .get(`/referrals/stats`)
        .then((response) => response.data)
        .then((response) => {
          this.stats = response;

          this.creditsByDate = {
            labels: response.last_6_months_active.map((a) => a.date),
            datasets: [
              {
                stack: 1,
                label: 'Credits Earned',
                backgroundColor: '#fabe6b',
                borderColor: '#f9ac40',
                borderWidth: 1,
                data: response.last_6_months_active.map((a) => a.amount)
              }
            ]
          };

          this.activeReferralsByDate = {
            labels: response.last_6_months_active.map((a) => a.date),
            datasets: [
              {
                stack: 1,
                label: '# Activated Referrals',
                backgroundColor: '#81C784',
                borderColor: '#1B5E20',
                borderWidth: 1,
                data: response.last_6_months_active.map((a) => a.count)
              }
            ]
          };

          this.referralsByDate = {
            labels: response.all_referrals.map((a) => a.date),
            datasets: [
              {
                stack: 1,
                label: '# Referrals',
                backgroundColor: '#E0E0E0',
                borderColor: '#212121',
                borderWidth: 1,
                data: response.all_referrals.map((a) => a.count)
              }
            ]
          };

          this.referred = response.all_referrals;
        });
    },

    getReferalCode() {
      this.loading = true;
      this.$http
        .get('/referrals/code')
        .then(() => {
          this.getUser();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getCommissionStructureCount() {
      this.$http
        .get('/commission-structures-count')
        .then((response) => response.data)
        .then((response) => {
          this.commissionStructureCount = response.count
        });
    }
  }
};
</script>

<style>
.card-text-format {
  font-size: 16px !important;
  color: #4a4a4a;
}
.strong-reference {
  font-weight: bold;
  font-size: 16px;
  color: #4a4a4a;
  display: block;
  padding-bottom: 1rem;
}
.url-reference {
  font-weight: bold;
  font-size: 16px;
  color: #4a4a4a;
  display: block;
  /* padding: 5px; */
  /* border: 5px ridge #ebebeb; */
}
.reference-portal-info-details {
  font-weight: bold;
  font-size: 16px;
  color: #4a4a4a;
}
/* .reference-portal-margin-left {
	margin-left: 15px;
	padding-bottom: 0px !important;
} */
/* .membership-flex {
	width: 90%;
} */
</style>
