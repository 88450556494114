<template>
  <v-container
    pa-0
    style="margin-bottom: 400px;"
  >
    <v-row v-if="loading">
      <div class="loading-overlay">
        <div class="loading-content">
          <atom-spinner
            style="margin: auto"
            :animation-duration="1000"
            :size="100"
            :color="'#f9ac40'"
          />
          <div style="text-align: center; color: #9d9da6">
            Loading
          </div>
        </div>
      </div>
    </v-row>

    <v-row
      v-else-if="order !== null"
      dense
    >
      <v-col cols="12">
        <div class="d-flex flex-row justify-space-between">
          <div class="order-title">
            <h4>Order ID</h4>
          </div>
          <div v-if="order.merged_order">
            <v-select
              v-model="defaultOrderId"
              :items="order.merged_orders"
              item-text="display_order_id"
              item-value="display_order_id"
              outlined
              dense
              class="small-select no-arrow"
            >
              <template #item="{ item }">
                <v-icon small>
                  {{ getIcon(item.store_id) }}
                </v-icon>
                <strong><span
                  class="order-title ml-1"
                  v-html="$options.filters.orderLink(item)"
                /></strong>
              </template>

              <template #selection="{ item }">
                <v-icon small>
                  {{ getIcon(item.store_id) }}
                </v-icon>
                <strong><span
                  class="order-title ml-1"
                  v-html="$options.filters.orderLink(item)"
                /></strong>
              </template>
            </v-select>
          </div>
          <div v-else-if="order.store_id">
            <span v-if="order.store_id == 1">
              <v-icon small>fab fa-amazon</v-icon>
            </span>
            <span v-if="order.store_id == 2">
              <v-icon small>fab fa-ebay</v-icon>
            </span>
            <span v-if="order.store_id == 3">
              <v-icon small>fab fa-etsy</v-icon>
            </span>
            <span v-if="order.store_id == 4">
              <v-icon small>fab fa-shopify</v-icon>
            </span>
            <span v-if="order.store_id == 5">
              <v-icon small>mdi-cogs</v-icon>
            </span>
            <span v-if="order.store_id == 6">
              <v-icon small>$bigcommerce</v-icon>
            </span>
            <span v-if="order.store_id == 7">
              <v-icon small>$woocommerce</v-icon>
            </span>
            <span v-if="order.store_id == 99">
              <v-icon small>mdi-api</v-icon>
            </span>

            <v-tooltip top>
              <template #activator="{ on }">
                <strong><span
                  class="order-title ml-1"
                  v-on="on"
                  v-html="$options.filters.orderLink(order)"
                /></strong>
              </template>
              <span>View in store</span>
            </v-tooltip>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="showTabs && !lockedTab"
        cols="12"
      >
        <v-card outlined>
          <v-tabs
            v-model="tabSelected"
            grow
            hide-slider
            :height="35"
          >
            <v-tab :href="'#'+quickerShipTabs.view">
              <v-icon left>
                mdi-clipboard-outline
              </v-icon>
              Order
            </v-tab>

            <v-tab
              :href="'#'+quickerShipTabs.edit"
              @click="prepareEditShipmentDetails"
            >
              <v-icon left>
                mdi-cube
              </v-icon>
              Shipment
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-tabs-items
          v-model="tabSelected"
        >
          <v-tab-item
            :value="quickerShipTabs.view"
            :transition="false"
          >
            <view-order-details-tab
              :order="order"
              :can-edit="canEdit"
              :tax-identifiers="$store.getters['main/tax_identifiers']"
              :update-shipment-handler="updateShipment"
              @autoPrepareShipment="autoPrepareShipment"
            />
          </v-tab-item>

          <v-tab-item
            :value="quickerShipTabs.edit"
            :transition="false"
          >
            <edit-shipment-details-tab
              v-if="canEdit && shipment"
              ref="refEditShipmentDetailsTab"
              :can-edit="canEdit"
              :shipment="shipment"
              :order="order"
              :tax-identifiers="$store.getters['main/tax_identifiers']"
              :update-shipment-handler="updateShipment"
              :show-overlay="showOverlay"
              @update="updateOrders"
            />
            <div v-else-if="order && order.shipment">
              <view-shipment-details-tab
                :shipment="order.shipment"
                :tax-identifiers="$store.getters['main/tax_identifiers']"
                :update-shipment-handler="updateShipment"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <bulk-apply-preset
          v-if="orders.length > 1 && canCreateLabelsFromOrders(orders)"
          :selected="orders"
          source="orders"
          @update-shipments="updateShipments"
        />
        <div
          v-else
          class="text-center"
          style="padding: 100px 0 100px 0"
        >
          <h2>{{ orders.length }} orders selected</h2>
        </div>
      </v-col>
    </v-row>

    <div v-if="order">
      <print
        v-if="autoPrintEnabled"
        ref="print"
        v-model="showPrint"
        :shipments="[order.shipment]"
      />
      <quick-ship-complete-dialog
        v-else
        v-model="showQuickShipCompleteDialog"
        :shipment.sync="order.shipment"
      />
    </div>
    <v-overlay
      :z-index="1"
      :value="showOverlay"
      :opacity="0.2"
      :absolute="true"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { AtomSpinner } from 'epic-spinners';
import ViewOrderDetailsTab from './tabs/view-order-details.vue';
import EditShipmentDetailsTab from '@/components/main/shipments/details/tabs/edit-shipment-details.vue';
import ViewShipmentDetailsTab from '@/components/main/shipments/details/tabs/view-shipment-details.vue';
import QuickShipCompleteDialog from '@/components/main/shipments/quick-ship/QuickShipCompleteDialog.vue';
import BulkApplyPreset from '@/components/main/shipments/BulkApplyPreset.vue';
import CONSTANTS from '@/constants/index';
import PRINTER from '@/constants/printer';

export default {
  components: {
    AtomSpinner,
    ViewOrderDetailsTab,
    EditShipmentDetailsTab,
    ViewShipmentDetailsTab,
    QuickShipCompleteDialog,
    BulkApplyPreset,
  },
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    showTabs: {
      type: Boolean,
      default: true
    },
    showOverlay: {
      type: Boolean,
      default: false
    },
    lockedTab: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      loading: false,
      showQuickShipFormDialog: false,
      shipment: null,
      tabSelected: this.lockedTab || CONSTANTS.QUICKER_SHIP_TABS.view,
      order: null,
      showPrint: false,
      showQuickShipCompleteDialog: false,
    };
  },
  computed: {
    ...mapGetters({ preSelectedQuickerShipTab: 'main/quickerShipTab' }),
    canEdit() {
      if (!this.order) return false;
      if (this.order.shipment && this.order.shipment.status_id != 3) {
        return false;
      }
      return true;
    },
    quickerShipTabs() {
      return CONSTANTS.QUICKER_SHIP_TABS;
    },
    isPrintable() {
      if (!this.order) return false;
  
      return this.order.shipment && this.is_printable(this.order.shipment)
    },
    autoPrintEnabled() {
      return this.isPrintable && this.isUserSettingEnabled('print_after_single_flow')
    },
    defaultOrderId() {
      return this.order.merged_orders.length > 0 ? this.order.merged_orders[0].display_order_id : null;
    }
  },
  watch: {
    orders: {
      immediate: true,
      handler(value, oldValue) {
        // Ensure value is defined and is an array
        if (!Array.isArray(value) || value.length === 0) {
          this.order = null;
          return;
        }

        // Ensure oldValue is an array to avoid accessing properties of undefined
        if (!Array.isArray(oldValue)) {
          oldValue = [];
        }

        // Check if the order has not changed
        if (
          value.length === 1 &&
          oldValue.length === 1 &&
          value[0].id === oldValue[0].id
        ) {
          console.log('watch orders same order');
          return;
        }

        // Handle case where only one order is selected
        if (value.length === 1) {
          console.log('watch orders', value);
          this.getOrder(value[0].id);
        } else {
          this.order = null;
        }
      }
    },
    preSelectedQuickerShipTab: {
      handler() {
        this.setPreSelectedTab();
      },
      deep: true,
      immediate: true
    },
    lockedTab(newVal) {
      if (newVal) {
        this.tabSelected = newVal;
      }
    },
    tabSelected(newVal) {
      if (this.lockedTab && newVal !== this.lockedTab) {
        this.tabSelected = this.lockedTab;
      }
    },
  },
  methods: {
    openQuickShipCompleteDialog() {
      if (this.autoPrintEnabled) {
        setTimeout(() => {
          this.$nextTick(function() {
            this.showPrint = true
            this.$store.dispatch('qz/updateCurrentDocType', PRINTER.DOCUMENT_FORMATS.LABEL);
            this.$refs.print.setPrintType(PRINTER.DOCUMENT_FORMATS.LABEL)
            this.$refs.print.getLabel('print', () => {
              this.showPrint = false
            });
          })
        }, 500)
      } else if (this.isPrintable) {
        this.showQuickShipCompleteDialog = true;
      }
    },
    setPreSelectedTab() {
      if (this.lockedTab) {
        this.tabSelected = this.lockedTab;
        return;
      }
      switch (this.preSelectedQuickerShipTab) {
        case this.quickerShipTabs.view:
        case this.quickerShipTabs.edit:
          this.tabSelected = this.preSelectedQuickerShipTab;
          break;
        default:
          this.tabSelected = this.quickerShipTabs.edit;
      }
    },
    prepareEditShipmentDetails() {
      if (!this.canEdit) return;

      // quick ship existing shipment
      if (this.order.shipment) {
        this.activateEditShipmentDetails(this.order.shipment);
        return;
      }

      // auto prepare order shipment
      this.autoPrepareShipment(this.activateEditShipmentDetails);
    },

    /**
     * Get shipment data from backend and apply any automation
     */
     autoPrepareShipment(callback) {
      this.loading = true
      this.$http
        .post('/shipments/quick-ship-order', {
          id: this.order.id
        })
        .then((response) => {
          const shipment = response.data;

          // parse for dropdown values
          shipment.insured = shipment.insured === true ? 1 : 0;
          shipment.signature_confirmation =
            shipment.signature_confirmation === true ? 1 : 0;

          if (callback instanceof Function) {
            callback(shipment)
          }
        })
        .finally(() => {
          this.loading = false
        });
    },

    activateEditShipmentDetails(shipment) {
      this.shipment = { ...shipment }

      // IMPORTANT: wait for shipment to get properly initialized
      setTimeout(() => {
        const refEditShipmentDetailsTab = this.$refs.refEditShipmentDetailsTab
        if (refEditShipmentDetailsTab) {
          refEditShipmentDetailsTab.activateQuickShipForm()
        }
      }, 500)
    },

    /**
     * Update local shipment copy
     */
    updateShipment(shipment) {
      if (!this.order) {
        return;
      }

      this.order.shipment = { ...shipment };

      // event registered @orders-table.vue
      Event.fire('update-single', this.order);

      if (!this.canEdit) {
        this.tabSelected = this.quickerShipTabs.view
      }

      this.openQuickShipCompleteDialog()
    },

    updateShipments(orders) {
      // event registered @orders-table.vue
      Event.fire('update-multiple', orders);
    },

    /**
     * Update orders datatable
     */
    updateOrders() {
      // event registered @orders-table.vue
      // reload datatable
      Event.fire('update-orders');
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
      }
    },
    getOrder(id) {
      console.log('getOrder', id);
      this.cancelSearch();
      this.cancelSource = axios.CancelToken.source();
      this.order = null;
      this.loading = true;
      this.$http
        .get(`/orders/${id}`, {
          cancelToken: this.cancelSource.token
        })
        .then((response) => response.data)
        .then((response) => {
          this.order = response;

          this.shipment = null

          this.setPreSelectedTab()

          this.prepareEditShipmentDetails()
        })
        .finally(() => {
          this.loading = false;
        })
    },

    getIcon(storeId) {
      switch (storeId) {
        case 1: return 'fab fa-amazon';
        case 2: return 'fab fa-ebay';
        case 3: return 'fab fa-etsy';
        case 4: return 'fab fa-shopify';
        case 5: return 'mdi-cogs';
        case 6: return '$bigcommerce';
        case 7: return '$woocommerce';
        case 99: return 'mdi-api';
        default: return '';
      }
    }
    
  }
};
</script>

<style>
/* Optional: Make the v-select smaller */
.small-select {
  max-width: 100px; /* Adjust as needed */
  font-size: 0.875rem; /* Adjust font size */
}

/* Hide the arrow icon */
.no-arrow .v-select__selections {
  padding-right: 0 !important;
}

.no-arrow .v-select__append-inner {
  display: none !important;
}
</style>
