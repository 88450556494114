<template>
  <StallionDialog
    v-model="dialogDisplay"
    :width="600"
    :disabled="dialogDisabled"
    :fullscreen="$vuetify.breakpoint.mobile"
    title="Add Card"
  >
    <template #content>
      <div>
        <v-form id="payment-form">
          <v-container>
            <v-row>
              <v-col
                v-if="dropinInstance"
                cols="12"
              >
                <p>
                  Your credit card information is securely stored by our payment
                  provider. Stallion Express does not have access to this
                  information.
                </p>
              </v-col>

              <v-col
                v-if="!dropinInstance && processing"
                cols="12"
              >
                <v-alert
                  outlined
                  type="info"
                  color="primary"
                >
                  Generating Payment Form...
                </v-alert>
              </v-col>

              <v-col cols="12">
                <div
                  :id="dropinContainerId"
                  style="margin-top: -30px"
                />
                <v-switch
                  v-if="dropinInstance"
                  v-model="is_default"
                  :disabled="!hasCard"
                  label="Set as default"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </template>

    <template #actions>
      <v-btn
        :id="dropinSubmitBtnId"
        color="primary"
        :loading="processing"
        class="pay-with-stripe"
        type="submit"
        :disabled="processing"
      >
        Add
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import braintreeWeb from 'braintree-web';
import dropin from 'braintree-web-drop-in';

export default {
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    hasCard: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      dialogDisplay: false,
      dialogDisabled: this.disabled,
      processing: false,
      is_default: true,
      dropinInstance: null,
      dropinContainerId: 'dropin-container',
      dropinSubmitBtnId: 'submit-button',
      createDropinCallback: null,
      deviceData: null,
    };
  },

  watch: {
    dialogDisplay(val) {
      if (val) {
        this.initializeBraintree();
      } else {
        this.destroyDropinInstance();
      }
    },
  },

  methods: {
    openDialog() {
      this.dialogDisplay = true;
    },

    initializeBraintree() {
      if (this.dropinInstance) return;
      this.processing = true;

      // Fetch the client token from your server
      this.$http
        .post('/credit-card/auth-token')
        .then((res) => {
          if (res.data.success) {
            this.createDataCollector(res.data.token);
          } else {
            window.swal({
              title: 'Error!',
              text: res.data.error,
              icon: 'error',
            });
          }
        })
        .catch(() => {
          window.swal({
            title: 'Error!',
            text: 'There was a problem generating payment token',
            icon: 'error',
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },

    createDataCollector(token) {
      // Initialize the Data Collector
      braintreeWeb.client
        .create({
          authorization: token,
        })
        .then((clientInstance) => {
          return braintreeWeb.dataCollector.create({
            client: clientInstance,
            kount: true, // Enable Kount fraud detection
          });
        })
        .then((dataCollectorInstance) => {
          this.deviceData = dataCollectorInstance.deviceData;
          this.createDropinInstance(token);
        })
        .catch((err) => {
          // Handle errors in Data Collector creation
          console.error('Data Collector Error:', err);
          window.swal({
            title: 'Error!',
            text: 'There was a problem initializing the fraud detection.',
            icon: 'error',
          });
        });
    },

    createDropinInstance(authToken) {
      const submitButton = document.querySelector(`#${this.dropinSubmitBtnId}`);
      if (this.createDropinCallback)
        submitButton.removeEventListener('click', this.createDropinCallback);

      this.processing = true;

      dropin
        .create({
          authorization: authToken,
          container: `#${this.dropinContainerId}`,
          card: {
            cardholderName: {
              required: true,
            },
          },
        })
        .then((dropinInstance) => {
          this.dropinInstance = dropinInstance;
          this.createDropinCallback = () => {
            dropinInstance.requestPaymentMethod().then((payload) => {
              // Add device data to payload
              payload.deviceData = this.deviceData;
              // Send payload.nonce and device data to your server
              this.addCard(payload);
            });
          };
          submitButton.addEventListener('click', this.createDropinCallback);
        })
        .catch(() => {
          // Handle any errors that might've occurred when creating Drop-in
          window.swal({
            title: 'Error!',
            text: 'There was a problem creating payment gateway',
            icon: 'error',
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },

    destroyDropinInstance() {
      if (this.dropinInstance) {
        this.dropinInstance.teardown().then(() => {
          this.dropinInstance = null;
        });
      }
    },

    addCard(dropinPayload) {
      this.processing = true;
      this.$http
        .post('/credit-card/add', {
          nonce: dropinPayload.nonce,
          device_data: dropinPayload.deviceData,
          card_type: dropinPayload.details.cardType,
          expiration_month: dropinPayload.details.expirationMonth,
          expiration_year: dropinPayload.details.expirationYear,
          last_four: dropinPayload.details.lastFour,
          is_default: this.is_default,
        })
        .then((res) => {
          if (res.data.success) {
            Event.fire('card-created', res.data.card);
            this.dialogDisplay = false;
            this.destroyDropinInstance();
            window.swal({
              title: 'Success',
              text: res.data.message,
              icon: 'success',
            });
            this.$emit('getUserCards');
          } else {
            window.swal({
              title: 'Error',
              text: res.data.error,
              icon: 'error',
            });
          }
        })
        .catch(() => {
          window.swal({
            title: 'Error!',
            text: 'Could not add your card.',
            icon: 'error',
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
