<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template #activator="{ on }">
      <v-btn
        color="primary"
        dark
        v-on="on"
      >
        Show Details
      </v-btn>
    </template>

    <v-card>
      <div v-if="shipment !== null">
        <ul
          id="shipment-details"
          class="details-block"
        >
          <!-- Shipment Status -->
          <li
            class="details-title"
            style="margin-bottom: 10px"
          >
            <strong>Shipment Status</strong>
            <span class="float-right">{{ shipment.ship_code }}</span>
          </li>

          <li
            v-if="can('access.admin') && shipment.seik"
            class="details-title"
            style="margin-bottom: 10px"
          >
            <strong>SEIK</strong>
            <span class="float-right">{{ shipment.seik }}</span>
          </li>

          <li
            v-if="can('access.admin') && shipment.truck"
            class="details-title"
            style="margin-bottom: 10px"
          >
            <strong>Truck</strong>
            <span class="float-right">{{ shipment.truck.description }}</span>
          </li>

          <!-- Tracking -->
          <li v-html="$options.filters.status(shipment.status)" />

          <li v-if="shipment.status_id == 3">
            <v-flex
              xs12
              text-center
              mt-4
            >
              <v-btn
                small
                depressed
                color="primary"
                @click="editShipment(shipment, step)"
              >
                Resume
              </v-btn>
            </v-flex>
          </li>

          <li v-if="shipment.tracking_code">
            <hr>
          </li>
          <li
            v-if="shipment.tracking_code"
            class="details-title"
          >
            <strong>Tracking</strong>
          </li>
          <li v-if="shipment.event_tracking">
            Tracking Number:
            <span class="float-right">
              <v-tooltip
                v-if="shipment.event_carrier == 'USPS'"
                top
              >
                <template #activator="{ on }">
                  <a
                    :href="
                      'https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=' +
                        shipment.event_tracking
                    "
                    target="”_blank”"
                    v-on="on"
                  >{{ shipment.event_tracking }}</a>
                </template>
                <span>Track on USPS</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="
                  shipment.event_carrier == 'PostNL' &&
                    shipment.postage_type_id != 30
                "
                top
              >
                <template #activator="{ on }">
                  <a
                    :href="
                      'https://postnl.post/track/?barcodes=' +
                        shipment.event_tracking
                    "
                    target="”_blank”"
                    v-on="on"
                  >{{ shipment.event_tracking }}</a>
                </template>
                <span>Track on PostNL</span>
              </v-tooltip>
              <span v-else-if="shipment.event_carrier == 'To Be Routed'">{{
                shipment.event_tracking
              }}</span>
              <v-tooltip
                v-else-if="shipment.event_carrier == 'Apple Express'"
                top
              >
                <template #activator="{ on }">
                  <a
                    :href="
                      'https://goapple.appleexpress.com/clientordertracking.aspx?OrderNo=' +
                        shipment.event_tracking
                    "
                    target="”_blank”"
                    v-on="on"
                  >{{ shipment.event_tracking }}</a>
                </template>
                <span>Track on Apple Express</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="shipment.event_carrier == 'Canada Post'"
                top
              >
                <template #activator="{ on }">
                  <a
                    :href="
                      'https://www.canadapost.ca/trackweb/en#/resultList?searchFor=' +
                        shipment.event_tracking
                    "
                    target="”_blank”"
                    v-on="on"
                  >{{ shipment.event_tracking }}</a>
                </template>
                <span>Track on Canada Post</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="shipment.event_carrier == 'CanPar'"
                top
              >
                <template #activator="{ on }">
                  <a
                    :href="
                      'https://www.canpar.ca/en/track/TrackingAction.do?reference=' +
                        shipment.event_tracking
                    "
                    target="”_blank”"
                    v-on="on"
                  >{{ shipment.event_tracking }}</a>
                </template>
                <span>Track on CanPar</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="shipment.event_carrier == 'Sprintstar Canada'"
                top
              >
                <template #activator="{ on }">
                  <a
                    :href="
                      'https://sprintstar.ca/?OrderNo=' +
                        shipment.event_tracking
                    "
                    target="”_blank”"
                    v-on="on"
                  >{{ shipment.event_tracking }}</a>
                </template>
                <span>Track on Sprintstar</span>
              </v-tooltip>
              <span v-else>{{ shipment.tracking_code }}</span>
            </span>
          </li>
          <li v-else-if="shipment.tracking_code">
            Tracking Number:
            <span class="float-right">{{ shipment.tracking_code }}</span>
          </li>
          <li v-if="shipment.event_carrier">
            Carrier:
            <span class="float-right">{{ shipment.event_carrier }}</span>
          </li>
          <li v-if="shipment.event_datetime">
            Updated:
            <span class="float-right">{{
              shipment.event_datetime | datetime_short
            }}</span>
          </li>
          <li v-if="shipment.event_status">
            Status:
            <span class="float-right">{{ shipment.event_status }}</span>
          </li>
          <li
            v-if="shipment.tracking_code"
            class="text-right mt-4 mb-4"
          >
            <router-link
              router
              :to="{ name: 'track', params: { code: shipment.ship_code } }"
            >
              View History
            </router-link>
          </li>

          <!-- Fees -->
          <div
            v-if="
              (shipment.handling_fee || shipment.postage_rate || shipment.tax) >
                0
            "
          >
            <li>
              <hr>
            </li>
            <li v-if="shipment.postage_type">
              {{ shipment.postage_type }}
              <span class="float-right">{{
                shipment.postage_rate | currency
              }}</span>
            </li>
            <li class="details-title">
              <strong>Shipment Fees</strong>
              <span
                v-if="shipment.status_id == 3 && shipment.postage_rate != ''"
              >
                <a
                  class="float-right"
                  href="javascript:void(0)"
                  @click="editShipment(shipment, 'postage')"
                >Edit</a>
              </span>
            </li>
            <li v-if="shipment.postage_rate">
              {{ shipment.postage_type }}
              <span class="float-right">{{
                shipment.postage_rate | currency
              }}</span>
            </li>
            <li>
              Stallion Fee:
              <span class="float-right">{{
                shipment.handling_fee | currency
              }}</span>
            </li>
            <li>
              Tier:
              <span class="float-right">{{ shipment.tier }}</span>
            </li>
            <li v-if="shipment.tax">
              Tax:
              <span class="float-right">{{ shipment.tax | currency }}</span>
            </li>

            <li>
              Total:
              <span class="float-right">{{
                (Number(shipment.handling_fee) +
                  Number(shipment.postage_rate) +
                  Number(shipment.tax))
                  | currency
              }}</span>
            </li>
          </div>
          <div v-if="shipment.package_type !== null">
            <li>
              <hr>
            </li>
            <li class="details-title">
              <strong>Package</strong>
              <span
                v-if="shipment.status_id == 3 && shipment.package_type !== null"
              >
                <a
                  class="float-right"
                  href="javascript:void(0)"
                  @click="editShipment(shipment, 'package')"
                >Edit</a>
              </span>
              <!---->
            </li>

            <!-- Package -->
            <li>{{ shipment.package_type }}</li>
            <li v-if="shipment.length && shipment.width && shipment.height">
              Dimensions:
              <span
                class="float-right"
              >{{ shipment.length }} x {{ shipment.width }} x
                {{ shipment.height }} {{ shipment.size_unit }}</span>
            </li>
            <li>
              Weight:
              <span
                class="float-right"
              >{{ shipment.weight }} {{ shipment.weight_unit }}</span>
            </li>
            <li>
              Contents:
              <span class="float-right">{{ shipment.package_contents }}</span>
            </li>
            <li>
              Value:
              <span
                class="float-right"
              >{{ shipment.value | currency }} {{ shipment.currency }}</span>
            </li>

            <!-- Store -->
            <div v-if="shipment.order_id !== null">
              <li
                v-if="shipment.order_id"
                class="details-title"
              >
                <hr>

                <strong>Store</strong>
              </li>
              <li v-if="shipment.order_id">
                Order ID:
                <span class="float-right">
                  <v-icon
                    v-if="shipment.store_id == 2"
                    small
                  >fab fa-ebay</v-icon>
                  <v-icon
                    v-if="shipment.store_id == 1"
                    small
                  >mdi-amazon</v-icon>
                  <v-icon
                    v-if="shipment.store_id == 4"
                    small
                  >mdi-shopify</v-icon>
                  <v-icon
                    v-if="shipment.store_id == 3"
                    small
                  >mdi-etsy</v-icon>
                  {{ shipment.order_id }}
                  <v-icon
                    v-if="shipment.store_id == 99"
                    small
                  >mdi-api</v-icon>
                </span>
              </li>
              <li v-if="shipment.fulfillment_status_id !== null">
                Fulfillment Status:
                <span class="float-right">{{
                  shipment.fulfillment_status?.description || ''
                }}</span>
              </li>
              <li
                v-if="
                  shipment.fulfill_at !== null &&
                    shipment.fulfillment_status_id === 1
                "
              >
                Fulfillment At:
                <span class="float-right">{{
                  shipment.fulfill_at | datetime_from
                }}</span>
              </li>
              <li v-if="shipment.fulfillment_status_id !== null">
                Fulfillment Tracking:
                <span class="float-right">
                  <span
                    v-if="shipment.stallion_fulfillment"
                  >Stallion Tracking</span>
                  <span
                    v-if="!shipment.stallion_fulfillment"
                  >Carrier Tracking</span>
                </span>
              </li>
              <li
                v-if="
                  shipment.user_store_id && shipment.fulfillment_status_id !== 2
                "
              >
                <span class="float-right">
                  <a
                    v-if="
                      shipment.fulfillment_status_id === 1 &&
                        shipment.fulfillment_delay > 0
                    "
                    href="javascript:void(0);"
                    class="mt-4"
                    @click="cancelFulfillment"
                  >Cancel Fulfillment</a>
                  <a
                    v-else-if="
                      shipment.fulfillment_status_id === 3 ||
                        shipment.fulfillment_status_id === 4
                    " 
                    href="javascript:void(0);"
                    class="mt-4"
                    @click="fulfillNow"
                  >Fulfill Now</a>
                </span>
              </li>
            </div>
          </div>
          <!-- Dropoff Date -->
          <div v-if="shipment.dropoff_date !== null">
            <li>
              <hr>
            </li>
            <li class="details-title">
              <strong>Dropoff</strong>
            </li>
            <li>{{ shipment.dropoff_date | date_pretty }}</li>
            <li>{{ shipment.dropoff_location }}</li>
          </div>

          <!-- Recipient -->
          <div>
            <li>
              <hr>
            </li>
            <li class="details-title">
              <strong>Recipient</strong>
              <span v-if="shipment.status_id == 3">
                <a
                  class="float-right"
                  href="javascript:void(0)"
                  @click="editShipment(shipment, 'recipient')"
                >Edit</a>
              </span>
            </li>
            <li>{{ shipment.name }}</li>
            <li>{{ shipment.address1 }}</li>
            <li v-if="shipment.address2">
              {{ shipment.address2 }}
            </li>

            <li>
              {{
                shipment.city +
                  ', ' +
                  shipment.province_code +
                  ' ' +
                  shipment.postal_code
              }}
            </li>
            <li v-if="shipment.phone">
              Phone: {{ shipment.phone | phone }}
            </li>
            <li v-if="shipment.country">
              {{ shipment.country.country_name }}
            </li>
            <div v-if="shipment.return_address1">
              <li>
                <hr>
              </li>

              <li class="details-title">
                <strong>Return Address</strong>
              </li>
              <li v-if="shipment.return_name">
                {{ shipment.return_name }}
              </li>
              <li v-if="shipment.return_address1">
                {{ shipment.return_address1 }}
              </li>
              <li v-if="shipment.return_address2">
                {{ shipment.return_address2 }}
              </li>
              <li v-if="shipment.return_city">
                {{
                  shipment.return_city +
                    ' ' +
                    shipment.return_province_code +
                    ' ' +
                    shipment.return_postal_code
                }}
              </li>
              <li v-if="shipment.return_phone">
                Phone: {{ shipment.return_phone | phone }}
              </li>
              <li v-if="shipment.return_country_code">
                {{ shipment.return_country_code }}
              </li>
            </div>
          </div>
        </ul>
      </div>
      <div
        v-else
        class="text-center"
        style="padding: 100px 0 100px 0"
      >
        <h2>{{ shipments.length }} shipments selected</h2>
      </div>
      <v-card-actions class="mb-3">
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    selected: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      module: 'View Shipments',
      tracking: [],
      shipment: null,
      dialog: false
    };
  },
  computed: {
    step() {
      if (
        (this.shipment.handling_fee ||
          this.shipment.postage_rate ||
          this.shipment.tax) > 0
      ) {
        return 'postage';
      } else if (this.shipment.postage_type) {
        return 'package';
      } else {
        return 'recipient';
      }
    }
  },
  watch: {
    shipment: {
      immediate: true,
      handler() {
        this.shipment = this.selected;
      }
    }
  },
  created() {},
  methods: {
    cancelFulfillment() {
      this.$http
        .post(
          `/shipments/${this.shipment.id}/cancel-fulfillment`,
          this.shipment
        )
        .then(() => {
          Event.fire('update-shipments');

          this.shipment.fulfillment_status_id = 4;
          if (!this.shipment.fulfillment_status) {
            this.shipment.fulfillment_status = {}
          }
          this.shipment.fulfillment_status.description = 'Canceled';

          this.$notify({
            group: 'main',
            title: 'Fulfillment Canceled',
            text: `The fulfillment for shipment ${
              this.shipment.ship_code
            } has been canceled`,
            duration: 5000,
            type: 'success'
          });
        })
        .catch(err => {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: err,
            duration: 5000,
            type: 'error'
          });
        });
    },
    fulfillNow() {
      this.$http
        .post(`/shipments/${this.shipment.id}/fulfill-now`, this.shipment)
        .then(() => {
          this.shipment.fulfillment_status_id = 1;
          this.shipment.fulfill_at = null;
          if (!this.shipment.fulfillment_status) {
            this.shipment.fulfillment_status = {}
          }
          this.shipment.fulfillment_status.description = 'Pending';

          this.$notify({
            group: 'main',
            title: 'Fulfillment Requested',
            text: `The fulfillment for shipment ${
              this.shipment.ship_code
            } has been requested`,
            duration: 5000,
            type: 'success'
          });
        })
        .catch(err => {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: err,
            duration: 5000,
            type: 'error'
          });
        });
    }
  }
};
</script>

<style scoped>
#shipment-details li {
  font-size: 0.8rem;
}
</style>
