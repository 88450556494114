<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit Box' : 'Create Box'"
  >
    <template #content>
      <v-form @submit.prevent>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="box.name"
                outlined
                dense
                :error-messages="errs.collect('name')"
                label="Name"
                data-vv-name="name"
                :placeholder="`Box (${box.length || 0} x ${box.width || 0} x ${box.height || 0})`"
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="box.package_type_id"
                v-validate="'required'"
                outlined
                dense
                :items="packageTypes"
                item-text="description"
                item-value="id"
                :error-messages="errs.collect('package_type_id')"
                label="Package Type"
                data-vv-name="package_type_id"
                required
              />
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid>
          <v-row align="center">
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                v-model="box.length"
                v-validate="'required'"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('length')"
                label="Length"
                data-vv-name="length"
                required
              />
            </v-col>
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                v-model="box.width"
                v-validate="'required'"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('width')"
                label="Width"
                data-vv-name="width"
                required
              />
            </v-col>
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                v-model="box.height"
                v-validate="'required'"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('height')"
                label="Height"
                data-vv-name="height"
                required
              />
            </v-col>

            <v-col
              cols="4"
              md="3"
            >
              <v-select
                v-model="box.size_unit"
                v-validate="'required'"
                outlined
                dense
                :error-messages="errs.collect('size_unit')"
                label="Unit"
                data-vv-name="size_unit"
                required
                :items="$sizeUnits()"
              />
            </v-col>

            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                v-model="box.weight"
                v-validate="'required'"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('weight')"
                label="Weight (lb)"
                data-vv-name="weight"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        :loading="submitting"
        @click="updateBox()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="submitting"
        @click="saveBox()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  data() {
    return {
      box: this.getDefaultBox(),
      edit: false,
      dialog: false,
      submitting: false
    };
  },
  computed: {
    packageTypes: {
      get() {
        const packageTypes = this.$store.getters['main/package_types'];

        return packageTypes
          .filter((item) => item.package_provider === 'own')
          .map((pkg) => ({
            ...pkg,
            id: parseInt(pkg.id)
          }));
      }
    }
  },
  methods: {
  getDefaultBox() {
    return {
      name: '', // Optional, placeholder shows default
      package_type_id: 20,
      length: null,
      width: null,
      height: null,
      size_unit: 'in', // Default unit to 'in'
      weight: 0 // Default weight to 0
    };
  },
  cleanDimension(value) {
    // Convert to a number, remove trailing zeros, and return as a string
    return parseFloat(value || 0).toString();
  },
  generateDefaultName() {
    // Generate a clean name using dimensions
    const length = this.cleanDimension(this.box.length);
    const width = this.cleanDimension(this.box.width);
    const height = this.cleanDimension(this.box.height);
    const sizeUnit = this.box.size_unit || 'in';

    return `${length}x${width}x${height}${sizeUnit}`;
  },
  initDialog() {
    this.dialog = true;
    this.submitting = false;
    this.$validator.reset();
  },
  closeDialog() {
    this.dialog = false;
    this.$emit('update-boxes');
    this.$store.dispatch('boxes/getBoxes');
  },
  startNew() {
    this.initDialog();
    this.box = { ...this.getDefaultBox() };
    this.edit = false;
  },
  startEdit(box) {
    this.initDialog();
    this.box = { ...box };
    this.edit = true;
  },
  async validateBox() {
    return this.$validator.validate().then((result) => !!result);
  },
  async saveBox() {
    if (!(await this.validateBox())) return;

    // Set default name if not provided
    if (!this.box.name) {
      this.box.name = this.generateDefaultName();
    }

    this.submitting = true;
    this.$http
      .post(`/boxes`, this.box)
      .then(() => {
        this.closeDialog();
        this.$notify({
          group: 'main',
          title: 'Box Created',
          text: 'Box has been created successfully',
          duration: 5000,
          type: 'success'
        });
      })
      .catch(this.onError)
      .finally(() => {
        this.submitting = false;
      });
  },
  async updateBox() {
    if (!(await this.validateBox())) return;

    // Set default name if not provided
    if (!this.box.name) {
      this.box.name = this.generateDefaultName();
    }

    this.submitting = true;
    this.$http
      .put(`/boxes/${this.box.id}`, this.box)
      .then(() => {
        this.closeDialog();
        this.$notify({
          group: 'main',
          title: 'Box Updated',
          text: 'Box has been updated successfully',
          duration: 5000,
          type: 'success'
        });
      })
      .catch(this.onError)
      .finally(() => {
        this.submitting = false;
      });
  },
  onError(err) {
    if (
      err.response.status === 422 &&
      typeof err.response.data.errors !== 'undefined'
    ) {
      const errors = err.response.data.errors;
      const message = Object.values(errors)
        .map((field) => field[0])
        .join('\n');
      this.errorMessage(message);
    }
  }
}
};
</script>
