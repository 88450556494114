<template>
  <div>
    <div
      v-if="[60, 61, 30, 31, 32, 33, 37, 38, 170].includes(shipment.postage_type_id)"
      class="row"
    >
      <v-col md="12">
        <v-alert type="info">
          4x6 Thermal Labels are required for
          {{ postageText(shipment.postage_type_id) }}. Please do not print any
          labels using paper.
        </v-alert>
      </v-col>
    </div>

    <div
      v-if="[71, 80, 81, 82, 83].includes(shipment.postage_type_id)"
      class="row"
    >
      <v-col md="12">
        <v-alert type="info">
          Canada Post labels purchased from Stallion Express must be dropped off
          at Stallion Express location otherwise chargebacks may apply.
        </v-alert>
      </v-col>
    </div>

    <div
      v-if="showUntrackedAlert"
      class="row"
    >
      <v-col md="12">
        <v-alert type="info">
          USPS First-Class Mail (Untracked) is non-refundable once purchased.
          Please ensure the correct information is entered before proceeding.
        </v-alert>
      </v-col>
    </div>

    <div class="row">
      <v-col
        md="12"
        class="weglot-ignore"
      >
        <v-alert
          v-if="shipment.insured"
          type="success"
          outlined
        >
          Your shipment is covered by
          <a
            style="color: #4caf50"
            href="https://help.stallionexpress.ca/article/98-terms-conditions"
            target="_blank"
          >Stallion Protection!</a>
        </v-alert>
        <v-alert
          v-else
          type="warning"
          outlined
        >
          Your shipment is not covered by
          <a
            style="color: #fb8c00"
            href="https://help.stallionexpress.ca/article/98-terms-conditions"
            target="_blank"
          >Stallion Protection!</a>
          You agree to waive any claim to refund shipments that are not insured
          if the shipment is lost or damaged before receiving the package and
          after during transit.
        </v-alert>
      </v-col>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    showUntrackedAlert() {
      return (
        this.shipment.postage_type_id == 1 &&
        [17, 18].includes(this.shipment.package_type_id)
      );
    }
  },
  methods: {
    postageText(id) {
      if ([60, 61].includes(id)) {
        return 'USPS Economy';
      } else if ([30, 31, 32, 33, 37, 38].includes(id)) {
        return 'PostNL International';
      }
      return '';
    }
  }
};
</script>
