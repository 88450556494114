<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Bulk Closeout Selected Batches"
  >
    <template #content>
      <v-form>
        <v-container fluid>
          <v-row dense>
            <v-col
              v-if="batchIds.length > 0"
              cols="12"
            >
              <div>
                Are you sure that you want to closeout the following batches?
                <br>
                {{ batchIds.join(', ') }}
                <br><br>
                You will not be able to modify the batches after it has been closed.
              </div>
            </v-col>

            <v-col
              v-else
              cols="12"
            >
              <div class="dialog-info">
                No valid batch for closeout.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="batchIds.length == 0 || loading"
        color="primary"
        @click="closeout()"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    batchIds: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    closeout() {
      this.loading = true;
      this.$http
        .post(`/batches/bulk-closeout`, { ids: this.batchIds })
        .then(() => {
          this.successMessage(`Closeout process has been initiated to the batches: \n${this.batchIds.join(', ')}`);
          this.show = false;
          // event registered at batch-table.vue
          Event.fire('update-batches');
        })
        .catch((err) => {
          this.errorMessage(err.response?.data?.message || 'Failed to closeout batches');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
