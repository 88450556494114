<template>
  <StallionDialog
    v-model="$store.state.main.ups_request_dialog"
    :width="600"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    eager
    title="UPS Pickup Request"
    fluid
    :show-actions="false"
  >
    <template #content>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            :step="1"
          >
            Location
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 2"
            :step="2"
          >
            Shipments
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 3"
            :step="3"
          >
            Rate
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 4"
            :step="4"
          >
            Summary
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-stepper
        v-model="step"
        style="background-color: rgba(248, 248, 248, 0.5); min-height: 1000px"
      >
        <div
          v-if="processing > 0"
          class="loading-overlay"
        >
          <div class="loading-content">
            <v-progress-circular
              :size="100"
              :width="10"
              indeterminate
              color="primary"
            />
          </div>
        </div>
        <v-stepper-items>
          <v-container>
            <v-stepper-content step="1">
              <v-layout
                justify-center
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  md6
                  mb-12
                  mt-4
                >
                  <v-card class="elevation-5">
                    <v-card-text>
                      <v-flex
                        xs12
                        text-center
                      >
                        <h2 class="font-weight-bold mt-4">
                          Verify Your Pickup Location
                        </h2>
                      </v-flex>
                      <v-form data-vv-scope="pickup1">
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="pickup_request.name"
                                v-validate="'required'"
                                :error-messages="
                                  errs.collect('pickup1.pickup_name')
                                "
                                label="Name"
                                data-vv-name="pickup_name"
                              />
                            </v-col>

                            <!-- <v-col cols="12">
                              <h4 text-center>Pickup Address</h4>
                            </v-col>-->
                            <v-col cols="12">
                              <v-text-field
                                id="address1_pickup"
                                ref="autocomplete"
                                v-model="pickup_request.address1"
                                v-validate="'required|max:50'"
                                :error-messages="
                                  errs.collect('pickup1.address1')
                                "
                                label="Address1"
                                data-vv-name="address1"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.address2"
                                label="Address2"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.city"
                                v-validate="'required'"
                                :error-messages="errs.collect('pickup1.city')"
                                label="City"
                                data-vv-name="city"
                                required
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.country_code"
                                readonly
                                label="Country"
                                data-vv-name="country"
                                :error-messages="
                                  errs.collect('pickup1.country')
                                "
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-select
                                v-model="pickup_request.province_code"
                                v-validate="'required'"
                                :error-messages="
                                  errs.collect('pickup1.province_code')
                                "
                                data-vv-name="province_code"
                                :items="[
                                  {
                                    name: 'Alberta(AB)',
                                    code: 'AB'
                                  },
                                  {
                                    name: 'British Columbia(BC)',
                                    code: 'BC'
                                  },
                                  {
                                    name: 'Manitoba(MB)',
                                    code: 'MB'
                                  },
                                  {
                                    name: 'New Brunswick(NB)',
                                    code: 'NB'
                                  },
                                  {
                                    name: 'Newfoundland and Labrador(NL)',
                                    code: 'NL'
                                  },
                                  {
                                    name: 'Northwest Territories(NT)',
                                    code: 'NT'
                                  },
                                  {
                                    name: 'Nova Scotia(NS)',
                                    code: 'NS'
                                  },
                                  {
                                    name: 'Nunavut(NU)',
                                    code: 'NU'
                                  },
                                  {
                                    name: 'Ontario(ON)',
                                    code: 'ON'
                                  },
                                  {
                                    name: 'Prince Edward Island(PE)',
                                    code: 'PE'
                                  },
                                  {
                                    name: 'Quebec(QC)',
                                    code: 'QC'
                                  },
                                  {
                                    name: 'Saskatchewan(SK)',
                                    code: 'SK'
                                  },
                                  {
                                    name: 'Yukon Territory(YT)',
                                    code: 'YT'
                                  }
                                ]"
                                label="Province"
                                item-text="name"
                                item-value="code"
                                dense
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.postal_code"
                                v-mask="'A#A #A#'"
                                v-validate="{
                                  required: true,
                                  regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                                }"
                                validate-on-blur
                                :error-messages="
                                  errs.collect('pickup1.postal_code')
                                "
                                label="Postal Code"
                                data-vv-name="postal_code"
                              />
                            </v-col>

                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.phone"
                                v-mask="'##########'"
                                v-validate="'required|numeric|min:10|max:10'"
                                :error-messages="errs.collect('pickup1.phone')"
                                label="Phone"
                                data-vv-name="phone"
                              />
                            </v-col>
                          </v-row>
                          <v-layout
                            align-center
                            class="mt-12 mb-4"
                          >
                            <v-flex
                              xs12
                              text-center
                            >
                              <v-btn
                                large
                                depressed
                                color="primary"
                                @click="saveAddress()"
                              >
                                Next
                                <v-icon dark>
                                  mdi-arrow-right
                                </v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-layout
                justify-center
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  mb-12
                  mt-4
                >
                  <v-card class="elevation-5">
                    <v-card-text>
                      <v-flex
                        xs12
                        text-center
                      >
                        <h2 class="font-weight-bold mt-4">
                          Items
                        </h2>
                      </v-flex>
                      <v-form
                        data-vv-scope="pickup2"
                        @submit.prevent
                      >
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              md="12"
                            >
                              <h4>
                                You can consolidate individual packages into a
                                single box. Enter the weight and size details
                                for your consolidated package/s below:
                              </h4>
                              <!-- <p>
                                Postage adjustments may occur when you provide
                                inaccurate information which results in an
                                incorrect rate for your consolidated packages.
                              </p> -->
                            </v-col>
                          </v-row>

                          <transition-group
                            name="fade"
                            tag="div"
                          >
                            <v-row
                              v-for="(item, index) in pickup_request.packages"
                              :key="'key' + index"
                            >
                              <v-col cols="12">
                                <v-text-field
                                  v-model="item.description"
                                  v-validate="'required|max:255'"
                                  required
                                  label="Description"
                                  :data-vv-name="`description${index}`"
                                  :error-messages="
                                    errs.collect(`pickup2.description${index}`)
                                  "
                                  persistent-hint
                                />
                              </v-col>
                              <v-col>
                                <v-text-field
                                  v-model="item.weight"
                                  v-validate="'required|min_value:0.001'"
                                  label="Weight"
                                  item
                                  persistent-hint
                                  required
                                  type="number"
                                  min="1"
                                  :data-vv-name="`weight${index}`"
                                  :error-messages="
                                    errs.collect(`pickup2.weight${index}`)
                                  "
                                />
                              </v-col>
                              <v-col>
                                <v-select
                                  v-model="item.weight_unit"
                                  :items="['lbs', 'kg', 'g', 'oz']"
                                  label="Unit"
                                  :data-vv-name="`weight_unit${index}`"
                                />
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-model="item.length"
                                  v-validate="'required|min_value:0.001'"
                                  label="Length"
                                  required
                                  type="number"
                                  min="1"
                                  :data-vv-name="`length${index}`"
                                  :error-messages="
                                    errs.collect(`pickup2.length${index}`)
                                  "
                                />
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-model="item.width"
                                  v-validate="'required|min_value:0.001'"
                                  label="Width"
                                  required
                                  type="number"
                                  min="1"
                                  :data-vv-name="`width${index}`"
                                  :error-messages="
                                    errs.collect(`pickup2.width${index}`)
                                  "
                                />
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-model="item.height"
                                  v-validate="'required|min_value:0.001'"
                                  label="Height"
                                  required
                                  type="number"
                                  min="1"
                                  :data-vv-name="`height${index}`"
                                  :error-messages="
                                    errs.collect(`pickup2.height${index}`)
                                  "
                                />
                              </v-col>
                              <v-col>
                                <v-select
                                  v-model="item.size_unit"
                                  :items="['in', 'cm']"
                                  label="Unit"
                                  :data-vv-name="`size_unit${index}`"
                                />
                              </v-col>
                              <v-col align-center>
                                <v-btn
                                  v-if="index != 0"
                                  text
                                  icon
                                  dark
                                  color="red"
                                  @click="deleteRow(index)"
                                >
                                  <v-icon dark>
                                    remove
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </transition-group>
                          <v-row>
                            <v-col>
                              <v-btn
                                text
                                color="primary"
                                @click="addRow()"
                              >
                                <v-icon dark>
                                  add
                                </v-icon>
                                Add another Piece
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-menu
                                v-model="menu1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template #activator="{ on }">
                                  <v-text-field
                                    v-model="pickup_request.pickup_date"
                                    v-validate="'required'"
                                    :error-messages="
                                      errs.collect('pickup2.dropoff_date')
                                    "
                                    data-vv-name="dropoff_date"
                                    label="Pickup Date"
                                    prepend-inner-icon="event"
                                    readonly
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker
                                  id="edDropoff_date"
                                  v-model="pickup_request.pickup_date"
                                  :allowed-dates="allowedDates"
                                  :min="allowedStartDate"
                                  @input="menu1 = false"
                                />
                              </v-menu>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                v-model="pickup_request.pickup_time"
                                v-validate="'required'"
                                :items="times"
                                label="Pickup Time"
                                :error-messages="
                                  errs.collect('pickup2.pickup_time')
                                "
                                data-vv-name="pickup_time"
                              />
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="pickup_request.instructions"
                                v-validate="'max:50'"
                                label="Instructions for the UPS Driver (optional)"
                                data-vv-name="instructions"
                                :error-messages="
                                  errs.collect('pickup2.instructions')
                                "
                              />
                            </v-col>
                          </v-row>
                          <br>

                          <v-layout
                            align-center
                            class="mt-12 mb-4"
                          >
                            <v-flex
                              xs12
                              text-center
                            >
                              <v-btn
                                large
                                text
                                @click="step = 1"
                              >
                                <v-icon dark>
                                  mdi-arrow-left
                                </v-icon>Back
                              </v-btn>

                              <v-btn
                                large
                                depressed
                                color="primary"
                                @click="saveShipments()"
                              >
                                Next
                                <v-icon dark>
                                  mdi-arrow-right
                                </v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-layout
                justify-center
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  md6
                  mb-12
                  mt-4
                >
                  <v-card
                    v-if="rate"
                    class="elevation-5"
                  >
                    <v-card-text>
                      <v-flex
                        xs12
                        text-center
                      >
                        <h2 class="font-weight-bold mt-4">
                          Rates
                        </h2>
                      </v-flex>
                      <v-list
                        two-line
                        subheader
                      >
                        <v-list-item
                          ripple
                          @click="pickup_request.service_id = rate.serviceId"
                        >
                          <v-list-item-action>
                            <v-radio-group v-model="pickup_request.service_id">
                              <v-radio :value="rate.serviceId" />
                            </v-radio-group>
                          </v-list-item-action>

                          <v-list-item-avatar>
                            <v-icon>fab fa-ups</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ rate.serviceName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ rate.transitDays }} business
                              {{ rate.transitDays == 1 ? 'day' : 'days' }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            CAD {{ rate.cost | currency }}
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>

                      <v-flex xs12>
                        <div>
                          <strong>Rate:</strong>
                          {{ rate.cost | currency }}
                        </div>

                        <div>
                          <strong>Tax:</strong>
                          {{ rate.tax | currency }}
                        </div>

                        <div>
                          <strong>Total:</strong>
                          {{ rate.total | currency }}
                        </div>
                      </v-flex>
                      <v-layout
                        align-center
                        class="mt-12 mb-4"
                      >
                        <v-flex
                          xs12
                          text-center
                        >
                          <v-btn
                            large
                            text
                            @click="step = 2"
                          >
                            <v-icon dark>
                              mdi-arrow-left
                            </v-icon>Back
                          </v-btn>

                          <v-btn
                            large
                            depressed
                            color="primary"
                            @click="step = 4"
                          >
                            Next
                            <v-icon dark>
                              mdi-arrow-right
                            </v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-layout
                justify-center
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  md6
                  mb-12
                  mt-4
                >
                  <v-card class="elevation-5">
                    <v-card-text>
                      <v-flex
                        xs12
                        text-center
                      >
                        <h2 class="font-weight-bold mt-4">
                          Summary
                        </h2>
                      </v-flex>

                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <h4>Address</h4>
                            <v-divider />
                            <ul style="list-style: none; padding-left: 0">
                              <li>{{ pickup_request.name }}</li>
                              <li>{{ pickup_request.address1 }}</li>
                              <li v-if="pickup_request.address2">
                                {{ pickup_request.address2 }}
                              </li>
                              <li>
                                {{
                                  pickup_request.city +
                                    ', ' +
                                    pickup_request.province_code +
                                    ' ' +
                                    pickup_request.postal_code
                                }}
                              </li>

                              <li>{{ pickup_request.country_code }}</li>
                              <li v-if="pickup_request.phone">
                                Phone: {{ pickup_request.phone | phone }}
                              </li>
                            </ul>
                          </v-col>

                          <v-col cols="6">
                            <h4>Shipment Details</h4>
                            <v-divider />

                            <div>
                              <strong># Items:</strong>
                              {{ pickup_request.packages.length }}
                            </div>

                            <div v-if="pickup_request.pickup_date">
                              <strong>Pickup Date:</strong>
                              {{ pickup_request.pickup_date | date_pretty_non_timezone }}
                            </div>

                            <div>
                              <strong>Pickup Time:</strong>
                              {{ pickup_request.pickup_time }}
                            </div>
                          </v-col>

                          <v-col
                            v-if="rate"
                            cols="12"
                          >
                            <h4>{{ rate.serviceName }}</h4>
                            <v-divider />

                            <div>
                              <strong>Rate:</strong>
                              {{ rate.cost | currency }}
                            </div>

                            <div>
                              <strong>Tax:</strong>
                              {{ rate.tax | currency }}
                            </div>

                            <div>
                              <strong>Total:</strong>
                              {{ rate.total | currency }}
                            </div>
                          </v-col>

                          <v-col
                            v-if="pickup_request.instructions"
                            cols="12"
                          >
                            <h4>Instructions</h4>
                            <v-divider />
                            {{ pickup_request.instructions }}
                          </v-col>
                        </v-row>
                        <br>

                        <!-- <div>
                          <p
                            style="text-align: center; font-size: 14px; color: rgb(0, 0, 0); background-color: rgb(255, 193, 7); padding: 10px;"
                          >
                            Pickup time window will be between 9am - 1pm.
                            <br />
                            <br />
                            We require a minimum of 10 FBA boxes to schedule the
                            pickup. The pickup service is available for the GTA
                            only and is complimentary.
                            <br />
                            <br />All pickup requests must be submitted before
                            6pm the day before the requested pickup date.
                          </p>
                        </div> -->

                        <v-layout
                          align-center
                          class="mt-12 mb-4"
                        >
                          <v-flex
                            xs12
                            text-center
                          >
                            <v-btn
                              large
                              text
                              @click="step = 3"
                            >
                              <v-icon dark>
                                mdi-arrow-left
                              </v-icon>Back
                            </v-btn>

                            <v-btn
                              large
                              depressed
                              color="primary"
                              @click="complete()"
                            >
                              Pay
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-stepper-content>
          </v-container>
        </v-stepper-items>
      </v-stepper>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  data() {
    return {
      module: 'Schedule Pickup Request',
      user: {},
      result: false,
      address: {},
      times: [
        {
          value: '9:00',
          text: '9:00AM - 1:00PM'
        },
        {
          value: '10:00',
          text: '10:00AM - 2:00PM'
        },
        {
          value: '11:00',
          text: '11:00AM - 3:00PM'
        },
        {
          value: '12:00',
          text: '12:00PM - 4:00PM'
        },
        {
          value: '13:00',
          text: '1:00PM - 5:00PM'
        },
      ],

      showPasswordFields: false,
      newlyRegistered: true,
      processing: false,
      addressNotFound: false,
      nextDays: [],
      pickupDay: null,
      editGroup1: false,
      rate: null,
      shipment: {},
      total: 0,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      weeks: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],

      allowedStartDate: moment().format('YYYY-MM-DD'),
      // allowedEndDate: new Date(new Date().setDate(new Date().getDate() + 60)).toString(),

      menu1: null,
      enabled: [],
      provinces: [
        {
          name: 'Ontario',
          abbreviation: 'ON'
        }
      ],
      pickup_request: {
        address1: null,
        address2: null,
        city: null,
        postal_code: null,
        province_code: 'ON',
        country_code: 'CA',
        pickup_date: null,
        name: null,
        phone: null,
        instructions: null,
        pickup_time: null,

        packages: []
      },
      errors: {},
      step: 1, // 1-5
      stepStatus: {
        form: true,
        pickdetails: false,
        summary: false,
        complete: false
      },
      disabledDate: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        days: [0]
      },
      package_total: 0,
      package_nm: [],
      availableCredits: 0,
      pick_fees: true
    };
  },
  watch: {
    '$store.state.main.ups_request_dialog': {
      handler(newValue) {
        if (newValue) {
          // set default pickup location using the account address
          Object.assign(this.pickup_request, {
            address1: this.$auth.user.address1,
            address2: this.$auth.user.address2,
            city: this.$auth.user.city,
            postal_code: this.$auth.user.postal_code,
            province_code: this.$auth.user.province_code,
            country_code: this.$auth.user.country_code,
            name: this.$auth.user.name,
            phone: this.$auth.user.phone
          });

          // override default pickup location if user has one
          if (
            this.$auth.user.user_pickup_location &&
            this.$auth.user.user_pickup_location.is_default
          ) {
            Object.assign(
              this.pickup_request,
              this.$auth.user.user_pickup_location
            );
          }

          if (this.pickup_request.packages.length == 0) {
            this.addRow();
          }
        }
      }
    }
  },
  mounted() {
    if (typeof google !== 'undefined') {
      try {
        // alert(google);
        var returnData = {
          street_number: '',
          route: '',
          locality: '',
          administrative_area_level_1: '',
          country: '',
          postal_code: ''
        };

        var element = document.getElementById('address1_pickup');

        var autocomplete = new google.maps.places.Autocomplete(element, {
          types: ['geocode']
        });

        element.addEventListener('keydown', (e) => {
          console.log('keydown');
          if (e.keyCode === 13) {
            e.preventDefault();
          }
        });

        element.addEventListener('blur', (e) => {
          console.log('blur');
          returnData.route = e.target.value;
          returnData.street_number = '';
        });

        autocomplete.addListener('place_changed', () => {
          console.log('autocomplete');
          returnData = {
            street_number: '',
            route: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: ''
          };

          let place = autocomplete.getPlace();
          if (!place.geometry) {
            return;
          }
          let addressComponents = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            postal_code: 'short_name',
            postal_town: 'short_name'
          };
          if (place.address_components !== undefined) {
            for (let i = 0; i < place.address_components.length; i++) {
              let addressType = place.address_components[i].types[0];
              if (addressComponents[addressType]) {
                let val =
                  place.address_components[i][addressComponents[addressType]];
                returnData[addressType] = val;
              }
            }
            returnData['latitude'] = place.geometry.location.lat();
            returnData['longitude'] = place.geometry.location.lng();
          }

          element.placeholder = '';

          this.updateAddress(returnData, place);
        });
      } catch (ex) {
        console.log(ex);
      }
    }
    this.user = this.$auth.user;
  },

  methods: {
    updateAddress(value, value2) {
      console.log('update');

      if (['AS', 'GU', 'MP', 'PR', 'VI', 'UM'].includes(value.country)) {
        this.pickup_request.country_code = 'US';
        this.pickup_request.province_code = value.country;
      } else {
        this.pickup_request.country_code = value.country;
        this.pickup_request.province_code = value.administrative_area_level_1;
      }

      this.pickup_request.address1 = value.street_number + ' ' + value.route;

      if (value.locality) {
        this.pickup_request.city = value.locality;
      } else {
        this.pickup_request.city = value2.address_components[2].long_name;
      }

      this.pickup_request.postal_code = value.postal_code;
    },

    addRow: function () {
      document.createElement('div.package-details');
      this.pickup_request.packages.unshift({
        description: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        weight_unit: 'lbs',
        size_unit: 'in'
      });
    },
    deleteRow: function (index) {
      this.pickup_request.packages.splice(index, 1);
    },

    allowedDates: (val) => ![5, 6].includes(new Date(val).getDay()),

    saveAddress: function () {
      console.log('saveAddress');
      // this.processing = true;
      this.$validator.validateAll('pickup1').then((result) => {
        if (result) {
          console.log(result);
          this.step = 2;
        } else {
          this.errorMessage('Some of the form fields are invalid.');
        }
      });
    },

    saveShipments: function () {
      this.$validator.validateAll('pickup2').then((result) => {
        if (result) {
          this.processing = true;
          this.$http
            .post('/pickups/schedule', this.pickup_request)
            .then((res) => {
              if (res.data.success) {
                console.log(res.data);
                this.rate = res.data.rate;
                this.step = 3;

                this.pickup_request.service_id = this.rate.serviceId;
              } else {
                this.errorMessage(res.data.error);
              }
            })
            .catch((err) => {
              this.$notify({
                group: 'main',
                title: 'Error',
                text: err.response.data.message || 'Something went wrong. Please try again.',
                duration: 5000,
                type: 'error'
              });

              if (err.status == 422) {
                this.errors = err.data;
              }
            })
            .finally(() => {
              this.processing = false;
            });
        } else {
          this.errorMessage('Validation error');
        }
      });
    },
    complete() {
      if (!this.creditCheck(this.rate.total)) {
        return null;
      }

      this.processing = true;
      this.$http
        .post('/pickups/schedule/submit', this.pickup_request)
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.processing = true;
            var ids = [res.data.pickup.id];

            this.$http({
              method: 'post',
              url: '/pickups/label',
              data: { ids: ids }
            })
              .then((response) => {
                this.printUrl(response.data);
                this.print_loading = false;

                this.reset();
              })
              .catch(() => {
                this.errorMessage('Could not schedule the pickup');
              })
              .finally(() => {
                this.print_loading = false;
              });

            this.$router.push({
              name: 'pickups'
              // query: {
              //   id: response.shipment_return.id
              // }
            });

            Event.fire('update-pickups');
          } else {
            this.step = 1;
            this.errorMessage(res.data.error);
          }
        })
        .catch(() => {
          this.errorMessage('Could not schedule the pickup');
        })
        .finally(() => {
          this.processing = false;
        });
    },
    reset() {
      this.pickup_request.address1 = '';
      this.pickup_request.address2 = '';
      this.pickup_request.city = '';
      this.pickup_request.postal_code = '';
      this.pickup_request.province_code = 'ON';
      this.pickup_request.country_code = 'CA';
      this.pickup_request.pickup_date = null;
      this.pickup_request.name = '';
      this.pickup_request.phone = '';
      this.pickup_request.instructions = '';
      this.pickup_request.carriers = {};
      this.pickup_request.package_fee = 0;
      this.processing = false;
      this.errors = {};
      this.step = 1;
      this.stepStatus.form = true;
      this.stepStatus.pickdetails = false;
      this.stepStatus.confirmationDetails = false;
      this.stepStatus.summary = false;
      this.stepStatus.complete = false;

      this.$store.state.main.ups_request_dialog = false;
      this.$validator.reset();
    }
  }
};
</script>

<style scoped>
/* .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border: none;
}
.v-stepper__content {
  margin: 0 !important;
  padding: 0 !important;
} */
</style>
