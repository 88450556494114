<template>
  <div>
    <v-row dense>
      <v-col class="d-flex flex-wrap float-left">
        <v-checkbox
          v-model="filters.show_archived"
          class="mr-3"
          dense
          label="Show Archived"
          hide-details
          :value="true"
          @change="submitSearch()"
        />
        <v-checkbox
          v-model="address_issue"
          :value="true"
          dense
          label="Address Issue"
          class="mr-3"
          @change="submitSearch()"
        />
      </v-col>


      <v-col cols="auto">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              small
              :disabled="selected.length === 0"
              v-on="on"
            >
              Edit Tags
            </v-btn>
          </template>

          <v-card>
            <v-autocomplete
              v-model="selected_tags"
              small-chips
              prepend-inner-icon="mdi-tag"
              clearable
              data-vv-name="order_tag"
              :items="tags.tags"
              multiple
              item-text="name"
              item-value="id"
              hide-details
              outlined
              :disabled="!can(PERMISSIONS.SHIPPING)"
            >
              <template #item="{ item, on, attrs }">
                <v-list-item
                  :style="attrs.inputValue ? { backgroundColor: '#D3D3D3' } : ''"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-simple-checkbox
                      :value="attrs.inputValue"
                      color="primary"
                      :ripple="false"
                      v-on="on"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      :style="{
                        backgroundColor: item.color_code,
                        padding: '10px',
                        borderRadius: '7px',
                        color: getTextColor(item.color_code),
                        maxWidth: '200px',
                        border: '1px solid #161616'
                      }"
                    >
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template #selection="{ item, index }">
                <v-chip
                  :key="item.id"
                  :style="{
                    backgroundColor: item.color_code,
                    color: getTextColor(item.color_code),
                    border: '1px solid #161616'
                  }"
                  close
                  @click:close="removeTag(index)"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-card>
        </v-menu>
        <slot name="top-buttons" />
      </v-col>
      <v-col cols="auto">
        <!-- buildManageColumnHeaders is from mixin.js -->
        <stallion-table-settings
          :table="manageColumnTableKey"
          :columns="buildManageColumnHeaders(headers, manageColumnSettings, manageColumnTableKey)"
          :update-column-handler="updateColumns"
        />
      </v-col>
      <v-col cols="auto">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              small
              color="primary"
              v-bind="attrs"
              :disabled="totalItems === 0"
              v-on="on"
              @click="show_export= true"
            >
              Export
            </v-btn>
          </template>
        </v-menu>
      </v-col>

      <shipment-export
        v-model="show_export"
        :selected="selected"
        type="order"
        :total="totalItems"
        :params="params"
        endpoint="/orders"
        method="get"
      />
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <!-- v-bind.sync="filters" -->
        <order-search
          ref="searchComponent"
          :search-order-filters.sync="filters"
          :loading.sync="loading"
          :selected="selected"
          @reset-selected="resetSelected()"
          @apply-search="submitSearch()"
        />
      </v-col>
      <v-col cols="8">
        <filter-buttons
          v-model="filter"
          :filters="statuses"
        />
      </v-col>



      <v-col cols="12">
        <div v-if="condensed">
          <v-card-title>
            <span class="text-h3">Orders</span>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              @input="submitSearch()"
            />

            <!-- buildManageColumnHeaders is from mixin.js -->
            <stallion-table-settings
              :table="manageColumnTableKey"
              :columns="buildManageColumnHeaders(headers, manageColumnSettings, manageColumnTableKey)"
              :update-column-handler="updateColumns"
            />
          </v-card-title>
        </div>

        <v-data-table
          v-model="selected"
          class="table-striped mt-0"
          :elevation="0"
          :headers="filteredHeaders"
          :items="items"
          item-key="id"
          show-select
          :loading="loading"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500]
          }"
          :items-per-page="25"
          @click:row="rowClick"
          @item-selected="bulkSelect"
          @current-items="current = $event"
        >
          <template #item.address="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  v-if="item.address_verification_status == 'Verified'"
                  small
                  color="success"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else-if="item.address_verification_status == 'Unverified'"
                  small
                  color="warning"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-else-if="item.address_verification_status == 'Verification Failed'"
                  small
                  color="error"
                  v-on="on"
                >
                  mdi-alert-circle
                </v-icon>
                {{ item.name }}
              </template>
              <span v-if="item.address_verification_status == 'Verified'">
                Verified
              </span>
              <span v-else-if="item.address_verification_status == 'Unverified'">
                Unverified
              </span>
              <span v-else-if="item.address_verification_status == 'Verification Failed'">
                Verification Failed
              </span>
            </v-tooltip>
            <br>
            <span v-if="item.company">
              {{ item.company }}
              <br>
            </span>
            {{ item.address1 }}
            <br>
            <span v-if="item.address2">
              {{ item.address2 }}
              <br>
            </span>
            {{
              item.city + ' ' + item.province_code + ', ' + item.postal_code
            }}
            <br>
            {{ item.country_code }}
          </template>
          <template #item.order_id="{ item }">
            <order-id-link
              :order-id="item.order_id"
              :display-order-id="item.display_order_id"
              :open-quicker-tab="(id) => openQuickerTab(item, quickerShipTabs.view)"
            />
          </template>

          <template #item.user_store.identifier="{ item }">
            <span v-if="item.user_store">
              <v-icon small>
                {{ getIcon(item.store_id) }}
              </v-icon>
              <span v-if="item.user_store.nickname">
                {{ item.user_store.nickname }}
              </span>
              <span v-else>
                {{ item.user_store.identifier }}
              </span>
            </span>
          </template>

          <template #item.shipment="{ item }">
            <div v-if="item.shipment">
              <!-- <div>{{ item.shipment.ship_code }}</div> -->
              <a
                href="javascript:void(0)"
                @click.stop="openQuickerTab(item, quickerShipTabs.edit)"
              >
                {{ item.shipment.ship_code }}
              </a>
              <div>
                (<span v-html="$options.filters.status(item.shipment.status_id)" />)
              </div>
            </div>
          </template>

          <template #item.item_name="{ item }">
            <div v-if="item.items_count > 1">
              Multiple Items
            </div>
            <div v-else>
              {{ item.item_name }}
            </div>
          </template>

          <template #item.sku="{ item }">
            <div v-if="item.items_count > 1">
              Multiple SKUs
            </div>
            <div v-else>
              {{ item.sku }}
            </div>
          </template>

          <template #item.order_at="{ item }">
            {{ item.order_at | datetime_short }}
          </template>

          <template #item.age="{ item }">
            {{ item.order_at | datetime_from }}
          </template>

          <template #item.value="{ item }">
            <div>{{ item.value | currency }}</div>
          </template>

          <template #item.display_tag_ids="{ item }">
            <div style="display: flex">
              <span
                v-for="(tag, index) in item.tags"
                :key="`tag-${index}`"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <div
                      :style="{
                        backgroundColor: tag.color,
                        width: '10px',
                        height: '20px',
                        borderRadius: '10%',
                        marginRight: '2px',
                        border: '1px solid #161616'
                      }"
                      v-on="on"
                    />
                  </template>
                  <span>
                    {{ tag.name }}
                  </span>
                </v-tooltip>
              </span>
            </div>
          </template>

          <template #item.note="{ item }">
            <div v-if="item.note">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    class="mdi mdi-comment-text"
                    v-on="on"
                  />
                </template>
                <span>
                  {{ item.note }}
                </span>
              </v-tooltip>
            </div>
            <!-- tooltip -->
            <div v-else>
              <v-icon
                class="mdi mdi-comment-text"
                color="#DEE3E7"
              />
            </div>
          </template>


          <template #item.name="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  v-if="item.address_verification_status == 'Verified'"
                  small
                  color="success"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else-if="item.address_verification_status == 'Unverified'"
                  small
                  color="warning"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-else-if="item.address_verification_status == 'Verification Failed'"
                  small
                  color="error"
                  v-on="on"
                >
                  mdi-alert-circle
                </v-icon>
                {{ item.name }}
              </template>
              <span v-if="item.address_verification_status == 'Verified'">
                Verified
              </span>
              <span v-else-if="item.address_verification_status == 'Unverified'">
                Unverified
              </span>
              <span v-else-if="item.address_verification_status == 'Verification Failed'">
                Verification Failed
              </span>
            </v-tooltip>
          </template>

          <template #item.shipment?.batch_id="{ item }">
            <router-link
              v-if="item.shipment.batch_id"
              class="font-weight-bold"
              :to="{ name: 'batch', params: { id: item.shipment.batch_id } }"
            >
              {{ item.shipment.batch_id }}
            </router-link>
          </template>

          <template #item.warehouse_location="{ item }">
            <div v-if="item.warehouse_location && item.items.length === 1">
              {{ item.warehouse_location }}
            </div>
            <div v-else-if="item.items.length > 1">
              <div
                v-for="(i, index) in item.items"
                :key="index"
                class="d-flex align-center my-2"
                :style="warehouseLocationStyle"
              >
                <div>
                  <span v-if="i.product?.warehouse_location">{{ i.product?.warehouse_location }}</span>
                  <span v-else>No Warehouse Location</span>
                </div>
              </div>
            </div>
            <div v-else>
              No Warehouse Location
            </div>
          </template>

          <template #item.items="{ item }">
            <div
              v-for="(i, index) in item.items"
              :key="index"
              class="d-flex align-center my-2"
            >
              <div>
                <v-badge
                  :content="i.quantity"
                  inline
                  :color="getQuantityColor(i.quantity)"
                  class="order-image-badge"
                >
                  <v-img
                    v-if="i.img_url"
                    :src="i.img_url"
                    min-height="40"
                    min-width="40"
                    max-height="40"
                    max-width="40"
                    aspect-ratio="1"
                    style="cursor: pointer; border: 1px solid #e0e0e0;"
                    class="mr-1"
                    @click="openImage(i.img_url)"
                  />
                  <!-- placeholder image -->
                  <v-img
                    v-else
                    src="https://placehold.co/40x40"
                    min-height="40"
                    min-width="40"
                    max-height="40"
                    max-width="40"
                    aspect-ratio="1"
                    style="border: 1px solid #e0e0e0;"
                    class="mr-1"
                  />
                </v-badge>
              </div>

            
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="ml-1 order-item-title"
                    style="cursor: pointer;"
                    v-on="on"
                  >
                    {{ i.title?.slice(0, 20) }}
                  </span>
                </template>
                <span><span v-if="i.sku">{{ i.sku }} <br> </span>{{ i.title }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import OrderSearch from './OrderSearch.vue';
import PERMISSIONS from '@/constants/client-permissions';
import FilterButtons from '@/components/shared/FilterButtons.vue';
import CONSTANTS from '@/constants/index'
import ShipmentExport from '@/components/shared/ShipmentExport.vue';
import OrderIdLink from '@/components/main/orders/order-id-link.vue';
import { max } from 'lodash';

export default {
  components: {
    OrderSearch,
    FilterButtons,
    ShipmentExport,
    OrderIdLink
  },
  props: {
    condensed: {
      default: false,
      type: Boolean
    },
    value: {
      default: null,
      type: Array
    },
    tablePreferenceKey: {
      default: 'orders',
      type: String
    },
  },
  data() {
    return {
      menu: false,
      manageColumnTableKey: 'table_columns_client_orders',
      manageColumnSettings: [],
      filter: 'Awaiting Shipment',
      PERMISSIONS: PERMISSIONS,
      loading: false,
      show_export: false,
      filters: {
        filter_id: null,
        user_store_id: [],
        search: null,
        search_type: 'Order ID',
        country_code_type: 'Custom',
        order_date_min: null,
        order_date_max: null,
        value_min: null,
        value_max: null,
        weight_min: null,
        weight_max: null,
        quantity_min: null,
        quantity_max: null,
        country_code: [],
        country_code_inclusive: true,
        order_tags: null,
        show_archived: false,
      },
      statuses: [
        {
          text: 'All',
          icon: 'mdi-package-variant-closed',
          value: null
        },

        {
          text: 'Awaiting Shipment',
          icon: 'mdi-circle-edit-outline',
          value: 'Awaiting Shipment'
        },
        {
          text: 'On Hold',
          icon: 'mdi-circle-edit-outline',
          value: 'On Hold'
        },
        {
          text: 'Shipped',
          icon: 'mdi-circle-edit-outline',
          value: 'Shipped'
        },
        {
          text: 'Voided',
          icon: 'mdi-circle-edit-outline',
          value: 'Voided'
        },
      ],
      isExporting: false,

      show: null,
      show2: null,

      options: {
        sortBy: ['order_at'],
        sortDesc: [true],
        itemsPerPage: 25
      },
      totalItems: 0,
      items: [],

      headers: [
        {
          text: 'Full Address',
          align: 'left',
          value: 'address',
          sortable: false
        },
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Address1',
          align: 'left',
          value: 'address1'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Province',
          align: 'left',
          value: 'province_code'
        },
        {
          text: 'Postal Code',
          align: 'left',
          value: 'postal_code'
        },

        {
          text: 'Country',
          align: 'left',
          value: 'country_code'
        },
        {
          text: 'Order ID',
          align: 'left',
          value: 'order_id'
        },
        {
          text: 'Store',
          align: 'left',
          value: 'user_store.identifier',
          sortable: false
        },
        {
          text: 'Tags',
          align: 'left',
          value: 'display_tag_ids',
          sortable: true
        },
        {
          text: 'SKU',
          align: 'left',
          value: 'sku',
        },
        {
          text: 'Item Name',
          align: 'left',
          value: 'item_name',
        },
        {
          text: '# Items',
          align: 'left',
          value: 'items_count',
          sortable: true
        },
        {
          text: 'Total',
          align: 'left',
          value: 'value'
        },
        {
          text: 'Shipping Cost',
          align: 'left',
          value: 'shipping_cost'
        },
        // {
        //   text: 'Shipment',
        //   align: 'left',
        //   value: 'shipment',
        //   sortable: false
        // },
        {
          text: 'Ordered At',
          value: 'order_at'
        },
        {
          text: 'Age',
          align: 'left',
          value: 'age'
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'note',
          sortable: false
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
        },
        {
          text: 'Batch',
          align: 'left',
          value: 'shipment.batch_id',
        },
        {
          text: 'Total Quantity',
          align: 'left',
          value: 'total_quantity',
          sortable: true
        },
        {
          text: 'Warehouse Location',
          align: 'left',
          value: 'warehouse_location',
          sortable: true,
        },
        {
          text: 'Items',
          align: 'left',
          value: 'items',
          sortable: false
        },
        {
          text: 'Shipping Method',
          align: 'left',
          value: 'postage_code',
          sortable: true
        },
      ],

      search: null,

      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      needs_postage: null,
      country_code: null,
      timer: null,

      cancelSource: null,
      showAdvanceSearch: false,
      postageType: null,
      packageType: null,

      shiftKeyOn: false,
      current: [],
      last: null,
      selected_tags: [],
      settingTags: false,
      address_issue: null,
    };
  },
  computed: {
    ...mapState(['main', 'tags']),
    ...mapGetters({ append_tags: 'tags/append_tags' }),
    // Define a style object for the warehouse location column
    warehouseLocationStyle() {
      // Check if the items column is visible using your column settings
      const isItemsVisible = this.manageColumnSettings.find(
        (column) => column.value === 'items' && column.visible
      );
      
      // Return the appropriate height style based on the visibility of items
      return {
        height: isItemsVisible ? '44.74px' : 'auto'
      };
    },
    filteredHeaders() {
      // filteredColumnHeaders is from mixin.js
      return this.manageColumnTableKey
        ? this.filteredColumnHeaders(this.headers, this.manageColumnSettings, this.manageColumnTableKey)
        : this.headers
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    quickerShipTabs() {
      return CONSTANTS.QUICKER_SHIP_TABS
    },
    params(){
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var paginate_params = {
        page: page,
        per_page: itemsPerPage,
        sort_by: sortBy[0],
        desc: sortDesc[0],
        status: this.filter,
        address_verification_status: this.address_issue ? 'Verification Failed' : null
      };

      const filters = this.filters;

      const params = { ...paginate_params, ...filters };

      return params;
    }
  },
  watch: {
    filter: function () {
      this.options.page = 1;
      this.updateTable();
      this.$emit('filter-changed', this.filter);
    },
    options: {
      handler(newOptions, oldOptions) {
        this.updateTable();

        const preference = {
      table_rows: {},
      table_sort: {}
    };

    let shouldUpdatePreferences = false;

    if (newOptions.itemsPerPage !== oldOptions.itemsPerPage) {
      preference.table_rows[this.tablePreferenceKey] = newOptions.itemsPerPage;
      shouldUpdatePreferences = true;
    }

    if (newOptions.sortBy !== oldOptions.sortBy) {
      preference.table_sort[this.tablePreferenceKey] = {
        sortBy: newOptions.sortBy[0],
        sortDesc: newOptions.sortDesc[0],
      };
      shouldUpdatePreferences = true;
    }

    if (newOptions.sortDesc !== oldOptions.sortDesc) {
      preference.table_sort[this.tablePreferenceKey] = {
        sortBy: newOptions.sortBy[0],
        sortDesc: newOptions.sortDesc[0],
      };
      shouldUpdatePreferences = true;
    }

    // Only dispatch setPreferences if there was a change
    if (shouldUpdatePreferences) {
      this.$store.dispatch('main/setPreferences', preference);
    }
      },
      deep: true
    },
    selected: function (orders) {
      if (orders) {
        const tags = [];
        for (let order of orders) {
          if (order.tag_ids) {
            tags.push(...order.tag_ids);
          }
        }

        tags.sort();

        // remove duplicates
        const uniqueTags = tags.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        this.selected_tags = uniqueTags;
        this.settingTags = true;
      }
    },
    selected_tags: function (newVal, oldVal) {
      if (!this.settingTags) {
        this.modifyOrderTags(newVal, oldVal);
      } else {
        this.settingTags = false;
      }
    },
    date_type: function (val) {
      this.start_date = null;
      this.end_date = null;
      if (val == 'Today') {
        this.start_date = moment().format('YYYY-MM-DD');
        this.end_date = moment().format('YYYY-MM-DD');
      } else if (val == 'Yesterday') {
        this.start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        this.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
      } else if (val > 0) {
        this.start_date = moment().format('YYYY-MM-DD');
        this.end_date = moment().subtract(val, 'days').format('YYYY-MM-DD');
      }
    },
  },

  async mounted() {
    this.main.selectedShipments = [];
    this.updateTable();
    Event.listen('update-orders', () => {
      this.updateTable();
    });

    Event.listen('update-single', (order) => {
      this.updateSingle(order);
    });

    Event.listen('update-multiple', (orders) => {
      orders.forEach((order) => {
        this.updateSingle(order);
      });
    });

    this.$root.$on('order-table-show-select', (select) => {
      if (!select) {
        this.main.selectedShipments = this.items;
      } else {
        this.main.selectedShipments = [];
      }
    });

    // getManageColumnSettings is from mixin.js
    this.manageColumnSettings = await this.getManageColumnSettings(this.manageColumnTableKey);

    // Reset the localStorage refresh element on load.
    localStorage.setItem('should_refresh_orders_after_print', '0');

    window.addEventListener('storage', this.handleStorageChange);
  },
  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener('storage', this.handleStorageChange);
  },
  created() {
    const table_rows = this.$store.getters['main/table_rows'];
    const table_sort = this.$store.getters['main/table_sort'];

    let options = {
      sortBy: [table_sort[this.tablePreferenceKey]?.sortBy] ?? this.options.sortBy,
      sortDesc: [table_sort[this.tablePreferenceKey]?.sortDesc] ?? this.options.sortDesc,
      itemsPerPage: table_rows[this.tablePreferenceKey] ?? this.options.itemsPerPage
    };

    this.options = Object.assign(this.options, options);

    const self = this;
    self.keyDownHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = true;
    };
    self.keyUpHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = false;
    };
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
  },
  methods: {
    updateColumns(columns) {
      this.manageColumnSettings = columns
    },
    handleStorageChange(event) {
      console.log(event);
      if (event.key === 'should_refresh_orders_after_print') {
        this.checkShouldRefreshOrders();
      }
    },
    checkShouldRefreshOrders() {
      const shouldRefresh = localStorage.getItem('should_refresh_orders_after_print');
      if (shouldRefresh === '1') {
        this.updateTable();

        // Reset the flag in localStorage
        localStorage.setItem('should_refresh_orders_after_print', '0');

        console.log('Refreshing orders after print from another tab - OrdersTable');
      }
    },
    setQuickerTab(tab) {
      this.$store.dispatch('main/setQuickerShipTab', tab)
    },
    openQuickerTab(item, tab) {
      this.setQuickerTab(tab)
      this.setSelectedRow(item)
    },
    setSelectedRow(item) {
      this.selected = [item];
      this.main.selectedShipments = this.selected;
    },
    rowClick(item) {
      this.setQuickerTab()
      this.setSelectedRow(item)
    },
    bulkSelect({ item: b, value }) {
      const { current, shiftKeyOn } = this;

      if (value == true && shiftKeyOn) {
        let [a] = this.main.selectedShipments;

        if (this.last !== null) {
          a = this.last;
        }

        let start = current.findIndex((item) => item == a);
        let end = current.findIndex((item) => item == b);

        if (start - end > 0) {
          let temp = start;
          start = end;
          end = temp;
        }
        for (let i = start; i <= end; i++) {
          this.main.selectedShipments.push(current[i]);
        }
      }

      this.last = b;
    },
    selectAll() {
      if (this.main.selectedShipments.length > 0) {
        this.main.selectedShipments = [];
      } else {
        this.main.selectedShipments = this.items.map((x) => x['id']);
      }
    },
    submitSearch() {
      // clearTimeout(this.timer);
      // var ms = 500;
      // var self = this;
      this.options.page = 1;
      // this.timer = setTimeout(function () {
      this.updateTable();
      // }, ms);
    },
    updateSingle(order) {
      const index = this.items.findIndex((x) => x.id === order.id);
      this.$set(this.items, index, order);
    },
    updateTable() {
      this.loading = true;
      // this.main.selectedShipments = [];
      this.totalItems = 0;
      // this.items = [];
      // this.resetSelected();

      this.getDataFromApi()
        .then((data) => {
          this.items = data.items;
          this.totalItems = data.total;

          const old = this.selected.map((x) => x['id']);

          this.selected = this.items.filter((value) => old.includes(value.id));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetSelected() {
      this.selected = [];
    },
    getDataFromApi() {
      // this.loading = true;
      return new Promise((resolve) => {
        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        let  params = this.params;

        if(params.sort_by === 'age'){
          params.sort_by = 'order_at';
      }

        this.$http
          .request(`/orders`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            console.log(response);
            response.data = this.append_tags(response.data);
            return response;
          })
          .then((response) => {

            response.data.forEach((item) => {
              item.tags = this.getFormattedTags(item.display_tag_ids);
            });

            items = response.data;

            total = response.total;
            pages = response.last_page;

            resolve({
              items,
              total,
              pages
            });
          })
          .finally(() => {
            // this.loading = false;
          });
      });
    },
    async modifyOrderTags(newTag, oldValues) {
      if (newTag.length !== oldValues.length) {
        const order_ids = this.selected.map((r) => r.id);

        if (newTag.length > oldValues.length) {
          const tag_ids = newTag[0]?.id ? newTag.map((i) => i.id) : newTag;
          const filtered = tag_ids.filter((x) => !oldValues.includes(x));
          const tag_id = filtered[0];
          await this.appendOrderTag(tag_id, order_ids);
        } else if (newTag.length < oldValues.length) {
          const tag_ids = oldValues[0]?.id
            ? oldValues.map((i) => i.id)
            : oldValues;
          const filtered = tag_ids.filter((x) => !newTag.includes(x));
          const tag_id = filtered[0];
          await this.removeOrderTag(tag_id, order_ids);
        }
      }
    },
    appendOrderTag(tag_id, order_ids) {
      this.$http
        .post('/orders/append-tag', {
          tag_id,
          order_ids
        })
        .then(() => {
          for (let id of order_ids) {
            const order = this.selected.find((i) => i.id === id);
            if (order) {
              // Ensure tag_ids exists and is an array, then add newTag if not present
              order.display_tag_ids = order.display_tag_ids || [];
              if (!order.display_tag_ids.includes(tag_id)) {
                order.display_tag_ids.push(tag_id);
              }

              // update tags
              order.tags = this.getFormattedTags(order.display_tag_ids);
            }
          }
        });
    },
    removeOrderTag(tag_id, order_ids) {
      this.$http
        .post('/orders/remove-tag', {
          tag_id,
          order_ids
        })
        .then(() => {
          for (let id of order_ids) {
            const order = this.selected.find((i) => i.id === id);
            if (order) {
              // Remove tag_id(s) from tag_ids array
              order.display_tag_ids = order.display_tag_ids.filter((i) => i !== tag_id);
            }

            // update tags
            order.tags = this.getFormattedTags(order.display_tag_ids);
          }
        });
    },
    // Retrieves the color code based on tag ID
    getTagColor(tagId) {
      const tag = this.tags.tags.find(t => t.id === tagId);
      return tag ? tag.color_code : '#GGGGGG';
    },
    // Retrieves the tag name based on tag ID
    getTagName(tagId) {
      const tag = this.tags.tags.find(t => t.id === tagId);
      return tag ? tag.name : tagId;
    },
    getIsHidden(tagId) {
      const tag = this.tags.tags.find(t => t.id === tagId);
      return tag ? tag.is_hidden : false;
    },
    // Removes a tag based on its index
    removeTag(index) {
      const oldValues = [...this.selected_tags]; // Capture the old tags before removing
      this.selected_tags.splice(index, 1); // Remove the tag at the specified index

      // Update the tags for the selected orders with correct old values
      this.modifyOrderTags(this.selected_tags, oldValues);
    },
    // Retrieves the tag details for display, including color and name
    getFormattedTags(tag_ids) {
      if (!Array.isArray(tag_ids)) {
        console.warn("Expected an array for tag_ids, but got:", tag_ids);
        return [];
      }
      return tag_ids
        .filter(id => !this.getIsHidden(id))
        .map(id => ({
          name: this.getTagName(id),
          color: this.getTagColor(id),
        }));
    },
    getIcon(storeId) {
      switch (storeId) {
        case 1: return 'fab fa-amazon';
        case 2: return 'fab fa-ebay';
        case 3: return 'fab fa-etsy';
        case 4: return 'fab fa-shopify';
        case 5: return 'mdi-cogs';
        case 6: return '$bigcommerce';
        case 7: return '$woocommerce';
        case 8: return 'fab fa-magento';
        case 9: 
        case 10: return '$walmart';
        case 11: return 'fab fa-bandcamp';
        case 99: return 'mdi-api';
        default: return '';
      }
    },
  }
};
</script>

<style>
.v-application p {
  margin-bottom: 0px;
}

@media only screen and (min-width: 1440px) {
  .order-buttons {
    max-width: 200px;
  }
}

.input-group--selection-controls__ripple {
  border-radius: 0 !important;
}
.order-image-badge .v-badge__badge {
  min-width: 30px;
}

.order-item-title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 3px;
}
</style>

<style scoped>
.v-data-table td {
  display: flex;
}

.text-light-gray {
  color: #DEE3E7;
}
</style>
