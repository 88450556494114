<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="type_local"
          outlined
          dense
          :items="rules"
          label="Action"
        />
      </v-col>
      <v-col
        v-if="type_local && field"
        cols="12"
        md="6"
      >
        <v-text-field
          v-if="field.type == 'text'"
          v-model="value_local"
          outlined
          dense
          type="text"
          label="Value"
        />
        <v-text-field
          v-else-if="field.type == 'number'"
          v-model="value_local"
          outlined
          dense
          type="number"
          label="Value"
        />
        <v-select
          v-else-if="field.type == 'select'"
          v-model="value_local"
          outlined
          dense
          :items="field.options"
          label="Value"
          :item-text="field.text"
          :item-value="field.key"
          :multiple="field.multiple ? true : false"
        />
        <v-menu
          v-else-if="field.type == 'date'"
          v-model="end_date_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="value_local"
              outlined
              dense
              style="padding: 0"
              label="End Date"
              readonly
              clearable
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="value_local"
            @input="end_date_menu = false"
          />
        </v-menu>
      </v-col>
      <v-col
        v-if="info"
        cols="12"
      >
        <v-alert
          dense
          type="info"
        >
          {{ info }}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONSTANTS from '@/constants';

export default {
  components: {},
  props: {
    type: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Array],
      default: null
    }
  },
  data() {
    return {
      info: null,
      field: null,
      dialog: false,
      tax_identifiers: [],
      rules_unsorted: [
        {
          text: 'Set Weight (lbs)',
          value: 'set_weight',
          type: 'number',
          field: {
            name: 'Weight_lbs',
            type: 'number'
          }
        },
        {
          text: 'Adjust Weight (lbs)',
          value: 'adjust_weight',
          type: 'number',
          field: {
            name: 'Adjust_weight_lbs',
            type: 'number'
          }
        },
        {
          text: 'Set Value',
          value: 'set_value',
          field: {
            name: 'Value',
            type: 'number'
          },
          hint: 'This will combine all items in the order to a single line item and set the value to the value provided.'
        },
        {
          text: 'Set Currency',
          value: 'set_currency',
          field: {
            name: 'Currency',
            type: 'select',
            multiple: false,
            options: CONSTANTS.CURRENCIES
          }
        },
        {
          text: 'Set Box',
          value: 'set_box',
          field: {
            name: 'Value',
            type: 'select',
            multiple: false,
            key: 'id',
            text: 'name',
            options: this.getBoxes()
          }
        },
        {
          text: 'Set Package Type',
          value: 'set_package_type',
          type: 'integer',
          field: {
            name: 'Value',
            type: 'select',
            key: 'id',
            text: 'description',
            options: this.getPackageTypes()
          }
        },
        {
          text: 'Set Postage Type',
          value: 'set_postage_type',
          field: {
            name: 'Value',
            type: 'select',
            key: 'id',
            text: 'description',
            options: this.getPostageTypes()
          }
        },
        {
          text: 'Set Postage Type (Cheapest)',
          value: 'set_lowest_postage_ids',
          field: {
            name: 'Value',
            type: 'select',
            multiple: true,
            key: 'id',
            text: 'description',
            options: this.getPostageTypes()
          }
        },
        {
          text: 'Set Length (in)',
          value: 'set_length',
          field: {
            name: 'Length',
            type: 'number'
          }
        },
        {
          text: 'Set Width (in)',
          value: 'set_width',
          field: {
            name: 'Width',
            type: 'number'
          }
        },
        {
          text: 'Set Height (in)',
          value: 'set_height',
          field: {
            name: 'Height',
            type: 'number'
          }
        },
        {
          text: 'Set Stallion Protection',
          value: 'set_stallion_protection',
          field: {
            name: 'Value',
            type: 'select',
            options: [
              {
                text: 'Yes',
                key: true
              },
              {
                text: 'No',
                key: false
              }
            ]
          }
        },
        {
          text: 'Set Signature Confirmation',
          value: 'set_signature_confirmation',
          field: {
            name: 'Signature Confirmation',
            type: 'select',
            options: [
              {
                text: 'Yes',
                key: true
              },
              {
                text: 'No',
                key: false
              }
            ]
          }
        },
        {
          text: 'Set Package Contents',
          value: 'set_package_contents',
          field: {
            name: 'Contents',
            type: 'text'
          },
          hint: 'This will combine all items in the order to a single line item and set the weight to the value provided.'
        },
        {
          text: 'Set Needs Postage',
          value: 'set_needs_postage',
          field: {
            name: 'Needs Postage',
            type: 'select',
            options: [
              {
                text: 'Yes',
                key: true
              },
              {
                text: 'No',
                key: false
              }
            ]
          }
        },
        {
          text: 'Do Not Import',
          value: 'do_not_import',
          field: {
            name: 'Do Not Import',
            type: 'null'
          }
        },
        {
          text: 'Add Tags',
          value: 'add_tags',
          field: {
            name: 'Tag_ids',
            type: 'select',
            key: 'id',
            text: 'name',
            options: this.getTags(),
            multiple: true
          }
        },
        {
          text: 'Set Assigned To',
          value: 'set_assigned_to',
          field: {
            name: 'Value',
            type: 'select',
            key: 'id',
            text: 'name',
            options: this.getSubUsers()
          }
        },
        {
          text: 'Set Tax Identifier',
          value: 'set_tax_identifier',
          field: {
            name: 'Tax Identifier',
            type: 'select',
            key: 'id',
            text: 'nickname',
            options: this.$store.getters['main/tax_identifiers']
          }
        },
        {
          text: 'Set From Name',
          value: 'set_return_name',
          field: {
            name: 'From Name',
            type: 'text'
          }
        },
        {
          text: 'Set Cheapest Maximum ETA in Days',
          value: 'set_maximum_delivery_days',
          field: {
            name: 'Maximum Delivery Days',
            type: 'number'
          }
        },
        {
          text: 'Set Cheapest Maximum Cost',
          value: 'set_maximum_cost',
          field: {
            name: 'Maximum Cost',
            type: 'number'
          }
        },
        {
          text: 'Put On Hold',
          value: 'put_on_hold',
          field: {
            name: 'Hold',
            type: 'null'
          }
        },
        {
          text: 'Apply Preset',
          value: 'apply_preset',
          field: {
            type: 'select',
            multiple: false,
            key: 'id',
            text: 'preset_name',
            options: this.getPresets()
          }
        },
      ]
    };
  },
  computed: {
    ...mapState(['tags']),
    type_local: {
      get() {
        return this.type;
      },
      set(type) {
        this.$emit('update:type', type);
      }
    },
    value_local: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      }
    },
    // sort rules by text
    rules() {
      return this.rules_unsorted.sort((a, b) => a.text.localeCompare(b.text));
    }
  },
  watch: {
    type_local: {
      immediate: true,
      handler(val) {
        if (val) {
          const index = this.rules.findIndex((x) => x.value === val);
          this.field = this.rules[index].field;
          // parse value from array to string, single select or multiple
          // prevents vue error when array is converted to string (vice versa)
          let tempVal = null;
          switch (this.type_local) {
            case 'set_lowest_postage_ids':
              tempVal = Array.isArray(this.value_local)
                ? this.value_local
                : [this.value_local];
              tempVal = tempVal.map(str => parseInt(str));
              break;
            case 'add_tags':
              tempVal = Array.isArray(this.value_local)
                ? this.value_local
                : [this.value_local];
              break;
            default:
              tempVal = Array.isArray(this.value_local)
                ? this.value_local[0]
                : this.value_local;
          }
          this.value_local = this.filterValue(tempVal);
          this.info = this.rules[index].hint ?? null;
        } else {
          this.field = null;
          this.value_local = null;
        }
      }
    },
    value_local: {
      immediate: true,
      handler(val) {
        if(Array.isArray(val)){
          if (typeof val[0] === "string") {
            this.value_local = val.map(str => parseInt(str))
          }
        }
      }
    }
  },
  mounted() {
    // this.getTaxIdentifiers();
  },
  methods: {
    getBoxes() {
      return this.$store.getters['boxes/boxes'];
    },
    getPresets() {
      return this.$store.getters['bulkImport/presets'];
    },
    getPostageTypes() {
      return this.$store.getters['main/postage_types'](true);
    },
    getTags() {
      return this.$store.getters['tags/filtered_tags'];
    },
    getPackageTypes() {
      return this.$store.getters['main/package_types'];
    },
    getSubUsers() {
      this.$store.dispatch('main/getSubUsers');
      return this.$store.getters['main/sub_users'];
    },
    // async getTaxIdentifiers() {
    //   const response = this.$store.getters['main/tax_identifiers']
    //   const ruleIndex = this.rules.findIndex(
    //     (rule) => rule.value == 'set_tax_identifier'
    //   );
    //   if (ruleIndex !== -1) {
    //     const rule = this.rules[ruleIndex];
    //     rule.field.options = response.data.map((identifier) => {
    //       identifier.id = identifier.id.toString();
    //       return identifier;
    //     });
    //   }
    // },
    /**
     * Filter and make sure all values of dropdown exists on the select options
     * it might not exist when changing input from select to textfield (vice versa)
     */
    filterValue(value) {
      let options = [];
      switch (this.type_local) {
        case 'set_lowest_postage_ids':
        case 'set_postage_type':
          options = this.getPostageTypes();
          break;
        case 'set_package_type':
          options = this.getPackageTypes();
          break;
        case 'add_tags':
          options = this.getTags();
          break;
        default:
          return value;
      }

      let filteredValue = null;
      if (Array.isArray(value)) {
        filteredValue = value.filter((id) => {
          return _.find(options, { id });
        });
      } else {
        filteredValue = _.find(options, { id: value }) ? value : null;
      }

      return filteredValue;
    }
  }
};
</script>
