<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-form @submit.prevent>
          <v-container>
            <v-row
              class="pb-2"
              style="justify-content: space-between"
            >
              <v-text-field
                v-model="search"
                pt-0
                label="Search"
                prepend-inner-icon="search"
                outlined
                clearable
                dense
                hide-details
                data-lpignore="true"
                @input="submitSearch()"
              />
            </v-row>
          </v-container>
        </v-form>

        <v-card
          class="elevation-0"
          style="border: 1px solid #e7e7e7"
        > 
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            :loading="loading"
            show-select
            item-key="id"
            :options.sync="options"
            class="table-striped"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [
                50, 100, 200, 500
              ]
            }"
            :items-per-page="25"
          >
            <template #item.pickup_at="{ item }">
              <div v-if="item.pickup_at">
                {{ item.pickup_at | date_pretty }}
                <br>
                ({{ item.pickup_at | datetime_from }})
              </div>
            </template>
            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>
            <template #item.tax="{ item }">
              {{ item.tax | currency }}
            </template>
            <template #item.total="{ item }">
              {{ item.total | currency }}
            </template>
            <template #item.handling_fee="{ item }">
              {{ item.handling_fee | currency }}
            </template>
            <template #item.disposal="{ item }">
              <span v-if="item.disposal">Dispose</span>
              <span v-else>Return</span>
            </template>
            <template #item.returns="{ item }">
              {{ item.returns.length }}
            </template>

            <template #item.id="{ item }">
              <router-link
                v-if="item.id"
                :to="{
                  name: 'user-return-orders-view',
                  params: { id: item.id }
                }"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.id }}</span>
                  </template>
                  <span>View Order</span>
                </v-tooltip>
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    filter: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      search: null,
      start_date: null,
      end_date: null,

      headers: [
        {
          text: 'Order #',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Returns',
          sortable: false,
          value: 'item_count'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'order_status'
        },
        {
          text: 'Handling Fee',
          value: 'handling_fee'
        },
        {
          text: 'Tax',
          value: 'tax'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Type',
          value: 'disposal'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: 'Pickup At',
          value: 'pickup_at'
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      totalItems: 0,
      items: []
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
  watch: {
    filter() {
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
  },
  mounted() {
    Event.listen('update-orders', () => {
      this.updateTable();
    });

    // Listen for the storage event to detect changes to localStorage
    window.addEventListener('storage', this.handleStorageChange);
  },
  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener('storage', this.handleStorageChange);
  },
  methods: {
    handleStorageChange(event) {
      console.log(event.key);
      if (event.key === 'should_refresh_orders_after_print') {
        this.checkShouldRefreshOrders();
      }
    },
    checkShouldRefreshOrders() {
      const shouldRefresh = localStorage.getItem('should_refresh_orders_after_print');
      if (shouldRefresh === '1') {
        this.updateTable();

        // Reset the flag in localStorage
        localStorage.setItem('should_refresh_orders_after_print', '0');

        console.log('Refreshing orders after print from another tab');
      }
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          filter: this.$route.query.filter
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/return-invoices`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
