<template>
  <SidePanelLayout
    v-if="batch" 
    :bread-crumbs="breadCrumbs"
    :title="`Batch ${batch?.id} ${batch?.name ? '- '.concat(batch?.name) : ''}`"
  >
    <template #side-panel>
      <view-shipment-details
        v-if="batch.processing != true"
        style="font-size: 14px"
        :shipments="selected"
      />
    </template>

    <template #page-links>
      <v-col
        v-show="batch.processing != true && batch.open == true"
        cols="auto"
      >
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
            
              color="primary"
              :disabled="batch.incomplete == 0"
              v-on="on"
              @click="validate()"
            >
              Get Rates ({{ batch.incomplete }})
            </v-btn>
          </template>
          <span>Validate and get rates for batch shipments</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
            
              color="primary"
              :disabled="batch.validated == 0"
              v-on="on"
              @click="confirmPurchase()"
            >
              Purchase ({{ batch.validated }})
            </v-btn>
          </template>
          <span>Purchase validated batch shipment</span>
        </v-tooltip>

        <v-tooltip
          v-if="batch.complete == 0"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
            
              color="primary"
              :disabled="canceling"
              :loading="canceling"
              v-on="on"
              @click="confirmDeleteBatch"
            >
              Cancel Batch
            </v-btn>
          </template>
          <span>Delete batch and all incomplete shipments </span>
        </v-tooltip>

        <v-tooltip
          v-else
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              :disabled="batch.total_count !== batch.complete || !Boolean(batch.open)"
              v-on="on"
              @click="show_close = true"
            >
              Close Batch
            </v-btn>
          </template>
          <span>Close batch and generate labels for batch</span>
        </v-tooltip>
      </v-col>

      <v-col
        v-if="batch.processing != true && batch.open == false"
        cols="auto"
      >
        <v-tooltip
          v-if="!batch.closeout || batch.closeout.received == false"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
            
              color="primary"
              outlined
              v-on="on"
              @click="show_open = true"
            >
              <v-icon
                small
                dense
                left
              >
                mdi-open-in-new
              </v-icon>Open Batch
            </v-btn>
          </template>
          <span>Open the batch to make changes to the shipments</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
            
              color="primary"
              :disabled="batch.unfulfill_shipments_count == 0"
              v-on="on"
              @click="show_retry_fulfillment = true"
            >
              <v-icon
                small
                dense
                left
              >
                mdi-redo
              </v-icon>
              Retry Fulfillment
            </v-btn>
          </template>
          <span>Retry failed fulfillments for shipments in batch</span>
        </v-tooltip>

        <v-tooltip
          v-if="batch.closeout_id"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
            
              color="primary"
              v-on="on"
              @click="closeout_show = true"
            >
              <v-icon
                small
                dense
                left
              >
                mdi-printer
              </v-icon>Batch Label
            </v-btn>
          </template>
          <span>Print batch label</span>
        </v-tooltip>

        <v-tooltip
          v-if="batch.label_id"
          bottom
          :disabled="batch.label.complete === 0 || batch.label.error === 1"
        >
          <template #activator="{ on }">
            <v-btn
            
              color="primary"
              :loading="batch.label.complete === 0"
              :disabled="batch.label.complete === 0"
              v-on="on"
              @click="getLabel('download', batch.label)"
            >
              <v-icon
                small
                dense
                left
              >
                mdi-download
              </v-icon>
              Shipment Labels
            </v-btn>
          </template>
          <span>Download combined label for all batched shipments</span>
        </v-tooltip>
      </v-col>
    </template>

    <template #default>
      <v-row
        v-if="batch.label && batch.label.complete === 0"
      >
        <v-col>
          <v-alert
            type="info"
            text
          >
            Label for shipments is being generated
          </v-alert>
        </v-col>
      </v-row>

      <v-row
        v-if="batch.processing == true"
      >
        <v-col>
          <v-progress-linear
            v-model="progressPercentage"
            height="30"
            class="mt-3 mb-3"
          >
            <template #default="{ value }">
              <strong>{{ Math.floor(value) }}%</strong>
            </template>
          </v-progress-linear>

          <div v-if="batch && batch.canceled">
            <h2>
              Canceling {{ batch.processed_count }} of
              {{ batch.count }} shipments
            </h2>
            <!-- Started {{ start_time | datetime_from }} -->
          </div>
          <div v-else-if="batch && batch.processed_count > 0">
            <h2>
              Processed {{ batch.processed_count }} of
              {{ batch.count }} shipments
            </h2>
            <!-- Started {{ start_time | datetime_from }} -->
          </div>
          <div v-else>
            <h2>Submitting batch for processing</h2>
            <br>
          </div>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            :disabled="batch.canceled == 1"
            outlined
            @click="cancelBatch()"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="batch.processing != true"
      >
        <v-col cols="12">
          <shipment-table
            ref="batchShipments"
            v-model="selected"
            manage-column-table-key="table_columns_client_batch_shipments"
            :batch-id="id"
            :items-per-page="1000"
            table-preference-key="batch_shipments"
            @update="getBatch()"
            @update_batch="getBatch(false)"
          />
        </v-col>
      </v-row>

      <batch-label-print
        v-model="closeout_show"
        :ids="[batch.closeout_id]"
      />
      <close-batch
        v-model="show_close"
        :batch="batch"
        :auto-close="autoClose"
        @update="getBatch()"
      />
      <open-batch
        v-model="show_open"
        :batch="batch"
        @update="getBatch()"
      />
      <retry-fulfillment
        v-model="show_retry_fulfillment"
        :batch="batch"
        @update="getBatch()"
      />

      <purchase-confirm-dialog
        v-model="showPurchaseConfirmDialog"
        :batch="batch"
        :confirm-purchase-handler="submitPurchase"
      />
    </template>

    <template #footer>
      <shipment-actions
        :shipments="selected"
        :hide-buttons="['process']"
        @update="getBatch()"
      />
    </template>
  </SidePanelLayout>
</template>

<script>
import CloseBatch from './CloseBatch.vue';
import OpenBatch from './OpenBatch.vue';
import RetryFulfillment from './RetryFulfillment.vue';
import PurchaseConfirmDialog from './PurchaseConfirmDialog.vue';
export default {
  metaInfo() {
    return {
      title: `Batch ${this.id} | Stallion Express`
    }
  },
  components: {
    CloseBatch,
    OpenBatch,
    RetryFulfillment,
    PurchaseConfirmDialog
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      canceling: false,
      showPurchaseConfirmDialog: false,
      show_open: false,
      closeout_show: false,
      show_close: false,
      autoClose: false,
      show_retry_fulfillment: false,
      pending: false,
      selected: [],
      batch: null,
      processing: false,
      progressPercentage: 0,
      count: 0,
      countDown: 5,
      loading: false,
      disabled: false,
      timer: '',
      // options: {
      //   sortBy: ['created_at'],
      //   sortDesc: [true],
      //   itemsPerPage: 200
      // },
      // headers: [
      //   {
      //     text: 'Address',
      //     align: 'left',
      //     value: 'name'
      //   },
      //   {
      //     text: 'Order ID',
      //     value: 'order_id',
      //     class: 'hidden-sm-and-down'
      //   },
      //   {
      //     text: 'Postage',
      //     value: 'postage_type_id'
      //   },
      //   {
      //     text: 'Package',
      //     value: 'package_type_id'
      //   },
      //   {
      //     text: 'Rate',
      //     value: 'postage_rate',
      //     class: 'hidden-sm-and-down'
      //   },
      //   {
      //     text: 'Status',
      //     value: 'status_id'
      //   },
      //   {
      //     text: 'Description',
      //     value: 'package_contents'
      //   },
      //   {
      //     text: 'Value',
      //     value: 'value'
      //   },

      //   {
      //     text: 'Created At',
      //     value: 'created_at'
      //   },
      //   {
      //     text: '',
      //     align: 'left',
      //     value: 'actions',
      //     sortable: false
      //   }
      // ],
      breadCrumbs: [
          {
            text: 'Home',
            exact: true,
            to: '/dashboard'
          },
          {
            text: 'Shipments',
            exact: true,
            to: '/shipments'
          },
          {
            text: 'All Batches',
            exact: true,
            to: '/batches'
          },
          {
            text: this.id,
            exact: true,
            to: '/batches/' + this.id
          }
        ]
    };
  },
  computed: {
    removable() {
      if (
        this.selected.length != 0 &&
        this.selected.every((r) => r.closeout_id == null && r.status_id == 2)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isCloseable() {
      if (!this.batch) return false;
      return (
        !this.batch.processing &&
        this.batch.open &&
        this.batch.total_count === this.batch.complete
      );
    }
  },
  watch: {
    id: function () {
      this.cancelAutoUpdate();
      this.getBatch();
    },
    batch: {
      deep: true,
      handler() {
        if (!this.batch) return;

        if (
          this.isCloseable &&
          this.isUserSettingEnabled('close_batch_on_complete')
        ) {
          this.autoClose = true;
          this.show_close = true;
        }
      }
    }
  },
  mounted() {
    this.getBatch();

    Event.listen('update-shipments', () => {
      this.getBatch();
    });
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  methods: {
    autoRefresh() {
      let self = this;
      this.timer = setTimeout(function () {
        self.getBatch();
      }, 2000);
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    getBatch(update_table = true) {
      this.axios
        .get(`/batches/${this.id}`)
        .then((response) => response.data)
        .then((batch) => {
          this.batch = Object.assign({}, {}, batch);

          this.progressPercentage = (batch.processed_count / batch.count) * 100;

          if ((batch.open === 0 && batch.label?.complete === 0)) {
            // currently closing the batch
            this.autoRefresh();
          } else if (!batch.processing) {
            // the batch is not processing
            if (update_table === true) {
              Event.fire('get-credits');
              if (this.$refs.batchShipments) {
                this.$refs.batchShipments.updateTable();
              }
            }
          } else {
            // the batch is processing
            this.autoRefresh();
          }
        })
        .catch(() => {});
    },
    validate() {
      const data = {
        shipments: []
      };

      this.axios
        .post(`/batches/${this.id}/validate`, data)
        .then((response) => response.data)
        .then(() => {
          this.getBatch();
        })
        .catch(() => {});
    },
    async confirmPurchase() {
      const valid = this.creditCheck(this.batch.validated_cost);

      if (valid) {
        this.showPurchaseConfirmDialog = true;
      }
    },

    async submitPurchase() {
      const valid = this.creditCheck(this.batch.validated_cost);

      if (valid) {
        const data = {
          shipments: []
        };

        this.loading = true;
        this.disabled = true;

        this.axios
          .post(`/batches/${this.id}/purchase`, data)
          .then((response) => response.data)
          .then(() => {
            this.getBatch();
          })
          .catch(() => {});
      }
    },

    async confirmDeleteBatch() {
      const isConfirm = await swal({
        title: 'Are you sure?',
        text:
          'This will delete the batch and all incomplete shipments.' +
          '\n Do you wish to continue?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });
      if (isConfirm) {
        this.canceling = true;
        this.axios
          .delete(`/batches/${this.id}`)
          .then((response) => response.data)
          .then(() => {
            this.$store.dispatch('batches/getOpenBatches');
            this.successMessage('Batch deleted');
            this.$router.push('/batches');
          }).finally(() => {
            this.canceling = false;
          });
      }
    },

    async cancelBatch() {
      const isConfirm = await swal({
        title: 'Are you sure?',
        text:
          'This will abort the processing of any unprocessed shipments.' +
          '.\n Do you wish to continue?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });
      if (isConfirm) {
        this.axios
          .get(`/batches/${this.id}/cancel`)
          .then((response) => response.data)
          .then(() => {
            this.batch.canceled = 1;
          });
      }
    },
    getLabel: function (action, label) {
      if (action == 'print') {
        this.printUrl(label.url);
        this.loading = false;
      } else {
        this.downloadFileUrl(label.url,label.filename);
      }
    }
  }
};
</script>
