<template>
  <a
    :href="link"
    :target="newTab ? '_blank' : '_self'"
    @click.stop="handleClick"
  >
    <span v-if="displayOrderId">
      {{ displayOrderId }}
    </span>
    <span v-else>
      {{ orderId }}
    </span>
  </a>
</template>
  
  <script>
  import SCAN_CONSTANTS from '@/constants/scan';

  export default {
    props: {
      orderId: {
        type: String,
        required: true
      },
      displayOrderId: {
        type: String,
        default: null
      },
      openQuickerTab: {
        type: Function,
        required: true // Ensure the parent provides this function
      }
    },
    data() {
      return {
        settings: this.getWorkflowSettings(), // Load workflow settings
      };
    },
    computed: {
      action() {
        return this.settings.orderIdLinkAction || 'no_action';
      },
      customUrl() {
        return this.settings.orderIdLinkActionCustomUrl || '';
      },
      newTab() {
        if (this.action === 'No action') {
            return false; // Disable newTab if no action is set
        }
        return this.settings.orderIdLinkActionNewTab || false;
      },
      link() {
        switch (this.action) {
          case SCAN_CONSTANTS.SCAN_TO_PRINT:
            return `/scan/?orderId=${this.orderId}&type=scan_to_print`;
          case SCAN_CONSTANTS.SCAN_TO_VERIFY:
            return `/scan/?orderId=${this.orderId}&type=scan_to_verify`;
          case SCAN_CONSTANTS.SCAN_TO_VERIFY_AND_PRINT:
            return `/scan/?orderId=${this.orderId}&type=scan_to_verify_and_print`;
          case 'Custom':
            if (this.customUrl.includes('{order_id}')) {
                // Ensure orderId is treated as a string
                const orderIdStr = String(this.orderId);
                return this.customUrl.replace('{order_id}', orderIdStr);
            }
            console.warn('Custom URL must include {order_id}');
            return 'javascript:void(0)';
          default:
            return 'javascript:void(0)';
        }
      }
    },
    methods: {
      getWorkflowSettings() {
        const settings = Object.values(this.$auth.user?.user_config_settings?.workflow_settings) || [];
        return {
          orderIdLinkAction: settings.find(setting => setting.key === 'order_id_link_action')?.value || 'no_action',
          orderIdLinkActionCustomUrl: settings.find(setting => setting.key === 'order_id_link_action_custom_url')?.value || '',
          orderIdLinkActionNewTab: settings.find(setting => setting.key === 'order_id_link_action_new_tab')?.value || false
        };
      },
      handleClick(event) {
        if (this.action === 'No action') {
          event.preventDefault();
          this.openQuickerTab(this.orderId); // Use parent's openQuickerTab
        }
      }
    }
  };
  </script>
  
  <style scoped>
  a {
    text-decoration: none;
  }
  </style>
  