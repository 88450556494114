<template>
  <SidePanelLayout>
    <template #title>
      <v-row>
        <v-col>
          <h1>{{ title }}<span v-if="totalQuantity"> - {{ totalQuantity }} {{ totalQuantity | pluralize('Item') }}</span></h1>
        </v-col>
        <v-col cols="auto text-right">
          <!-- address -->
          <div v-if="order && order.name">
            {{ order.name }}
          </div>
          <div v-if="order && order.address1">
            {{ order.address1 }}, {{ order.city }} {{ order.province_code }}, {{ order.postal_code }} {{ order.country_code }}
          </div>
        </v-col>
      </v-row>
    </template>
    <template #side-panel>
      <div>
        <order-details
          v-show="scanType !== 'Scan to Verify'"
          :orders="orders"
          locked-tab="tab-edit-shipment"
          :show-overlay="orderDetailsOverlay"
        />
      </div>
    </template>

    <template #default>
      <scan-shipment
        v-if="!order || !showVerifySection"
        ref="scanShipment"
        :scan-type.sync="scanType"
        @scanShipment="scanShipment"
      />

      <verify-items
        v-if="order && showVerifySection"
        :order="order"
        :scan-type="scanType"
        @close="reset"
      />

      <v-card
        v-if="!order || !showVerifySection"
        elevation="0"
        class="d-flex align-center justify-center"
        min-height="70vh"
        style="background: rgb(234 234 230);"
      >
        <!-- message order already shipped -->
        <div v-if="order && order.status === 'Shipped'">
          <v-alert type="info">
            <h2>Order {{ order.display_order_id }} has been processed.</h2>
            <p>The shipping label for order #{{ order.display_order_id }} has already been generated.</p>
          </v-alert>
        </div>
        <div v-else-if="order">
          <v-alert type="success">
            <h2>Order {{ order.display_order_id }} is ready for processing.</h2>
            <p>You can now generate the shipping label for this order.</p>
          </v-alert>
        </div>
        <div v-else>
          <h2>Scan a packing slip or enter an order number.</h2>
        </div>
      </v-card>
    </template>
  </SidePanelLayout>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
import SCAN_CONSTANTS from '@/constants/scan';
import ScanShipment from '../components/scan-shipment.vue';
import VerifyItems from '../components/verify-items.vue';
import OrderDetails from '../../orders/details/OrderDetails.vue';

export default {
  metaInfo: {
    title: "Shipments Scan to Verify & Print | Stallion Express"
  },
  components: {
    ScanShipment,
    VerifyItems,
    OrderDetails,
  },
  data() {
    return {
      order: null,
      scanType: SCAN_CONSTANTS.SCAN_TO_PRINT,
      PERMISSIONS: PERMISSIONS,
      loading: false,
    };
  },
  computed: {
    title() {
      return this.order ? `Order #${this.order.display_order_id}` : 'Scan a Packing Slip';
    },
    showVerifySection() {
      return this.order?.status === 'Awaiting Shipment' && (!this.order?.shipment || this.order?.shipment?.status_id === 3);
    },
    orders() {
      return !this.order ? [] : [this.order];
    },
    canEdit() {
      return this.order && this.showVerifySection && this.can(PERMISSIONS.ORDERS);
    },
    orderDetailsOverlay() {
      return this.order && !this.order.all_items_verified && this.scanType !== SCAN_CONSTANTS.SCAN_TO_PRINT;
    },
    totalQuantity() {
      return this.order ? this.order.items.reduce((acc, item) => acc + item.quantity, 0) : 0;
    }
  },
  mounted() {
    this.scanType = this.getUserSetting('default_scan_method', SCAN_CONSTANTS.SCAN_TO_PRINT);
    Event.listen('update-single', (order) => {
      if(this.order.all_items_verified) {
        order.all_items_verified = 1;
      }
      this.setOrder(order);
      this.loading = false;
    });
    Event.listen('mark-as-verified', (order) => {
      order.all_items_verified = 1;
      this.setOrder(order);
      this.loading = false;
    });
    Event.listen('enable-verification', (order) => {
      order.all_items_verified = 0;
      this.setOrder(order);
    });
    Event.listen('labels-printed', () => {
      console.log('Labels printed, setting refresh');

      // Set the flag in localStorage
      localStorage.setItem('should_refresh_orders_after_print', '1');
    });
  },
  methods: {
    setOrder(order) {
      if (order) {
        let o = { ...order, items: [] };
        o.items = [...order.items];

        this.order = o;
      }
    },
    scanShipment(payload) {
      this.order = { ...payload.order };
      // this.scanType = payload.scanType;
      this.setShipmentTab();
    },
    setShipmentTab() {
      setTimeout(() => {
        this.$nextTick(() => {
          let ref = this.$refs.refViewShipmentDetails;
          if (ref) {
            ref.setViewTab();
          }
          if (SCAN_CONSTANTS.PRINT_SCAN_WORKFLOW.includes(this.scanType) && !this.canEdit) {
            ref = this.$refs.refActionBar;
            if (ref) {
              ref.buildPrinter('label_only');
            }
          }
        });
      }, 300);
    },
    reset() {
      this.order = null;
    }
  }
};
</script>
