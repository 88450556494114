<template>
  <div>
    <!-- Main Tags Dialog -->
    <StallionDialog
      v-model="dialog"
      persistent
      :width="800"
      title="Edit Tags"
      fullscreen
      cancel-label="Close"
    >
      <template #content>
        <v-card-text>
          <v-container>
            <v-row dense> 
              <!-- Add/Edit Tag Section -->
              <v-col>
                <v-btn
                  v-if="!adding_tag"
                  color="primary"
                  
                  @click="addTagField"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>Add Tag
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-switch
                  v-model="show_hidden"
                  label="Show Hidden Tags"
                  @change="updateTable"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                After making changes to hidden tags, a resync is required for the updates to be reflected in the orders table.
              </v-col>
              <v-col
                md=""
                sm="12"
                style="flex: 0 100%"
              >
                <v-text-field
                  v-if="editTag.id || adding_tag"
                  v-model="editTag.name"
                  :rules="[rules.required]"
                  label="Tag Name"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                md="6"
                sm="12"
                style="flex: 0 100%"
              >
                <v-color-picker
                  v-if="editTag.id || adding_tag"
                  v-model="editTag.color_code"
                  class="ma-2"
                  hide-canvas
                  hide-inputs
                />
              </v-col>
              <v-col
                md="auto"
                sm="12"
                style="flex: 0 100%"
              >
                <v-btn
                  v-if="editTag.id || adding_tag"
                  color="primary"
                  :disabled="!can_add"
                  @click="addTag"
                >
                  Add
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <!-- Tags List -->
              <v-col
                v-for="(tag, index) in items"
                v-show="show_hidden || !tag.is_hidden"
                :key="`tag-${index}`"
                cols="12"
                md="4"
              >
                <v-card
                  class="tag_container"
                  :elevation="editTag.id === tag.id ? 23 : 2"
                >
                  <!-- Clickable Color Box -->
                  <div
                    :style="{
                      backgroundColor: tag.color_code,
                      borderRadius: '3px',
                      height: '25px',
                      width: '25px',
                      marginRight: '15px',
                      cursor: 'pointer',
                      border: '1px solid black'
                    }"
                    title="Click to edit color"
                    @click="openColorEditDialog(tag)"
                  />

                  <!-- Tag Name -->
                  <span>{{ tag.name }}</span>

                  <!-- Hidden Checkbox -->
                  <v-switch
                    v-model="tag.is_hidden"
                    class="mt-0 mr-3 dense-switch"
                    dense
                    hide-details
                    :label="tag.is_hidden ? 'Hide' : 'Show'"
                    @change="toggleHidden(tag)"
                  />
                  <!-- Delete Button -->
                  <!-- <v-btn
                   
                    class="mt-n7 mr-n1"
                    icon
                    x-small
                    @click="deleteTag(tag)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn> -->
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </template>

      <!-- <template #actions>
        <v-btn
          color="primary"
          :disabled="!can_save"
          @click="saveTags"
        >
          Save
        </v-btn>
      </template> -->
    </StallionDialog>

    <!-- Color Edit Dialog -->
    <StallionDialog
      v-model="colorEditDialog"
      persistent
      :width="400"
      title="Edit Tag Color"
    >
      <template #content>
        <v-card-text>
          <v-row>
            <v-col>
              <v-color-picker
                v-model="colorEditTag.color_code"
                hide-canvas
                hide-inputs
                @input="updateColor(colorEditTag)"
              />
            </v-col>
            <v-col
              cols="auto"
              class="d-flex align-center"
            >
              <!-- Random Color Button with Icon and Tooltip -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="generateRandomColor"
                  >
                    <v-icon color="secondary">
                      mdi-format-paint
                    </v-icon>
                  </v-btn>
                </template>
                <span>Generate Random Color</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </template>
      <template #actions>
        <v-btn
          color="primary"
          @click="closeColorEditDialog"
        >
          Save
        </v-btn>
      </template>
    </StallionDialog>
  </div>
</template>

<script>
const defaultTag = {
  id: null, // Assuming tags have unique IDs
  name: '',
  color_code: '#FFA427',
  is_hidden: false
};

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      items: [],
      editing: false,
      new_tag: { ...defaultTag },
      dirty: [],
      tags: [],
      editTag: { ...defaultTag },
      deleted: [],
      adding_tag: false,
      // Color Edit Dialog
      colorEditDialog: false,
      colorEditTag: { ...defaultTag },
      rules: {
        required: value => !!value || 'Required.'
      },
      show_hidden: false
    };
  },
  computed: {
    can_add() {
      return (
        this.editTag.name &&
        this.editTag.color_code &&
        !this.items.some(tag => tag.name === this.editTag.name)
      );
    },
    can_save() {
      return (
        this.adding_tag ||
        this.dirty.length > 0 ||
        this.deleted.length > 0
      );
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.resetEditState();
      } else {
        this.updateTable();
      }
    },
    editTag: {
      handler(newVal) {
        this.editing = !!newVal.name;
      },
      deep: true
    }
  },
  mounted() {
    this.updateTable();
  },
  methods: {
    // Reset edit state
    resetEditState() {
      this.editTag = { ...defaultTag };
      this.adding_tag = false;
      this.tags = [];
      this.dirty = [];
      this.deleted = [];
    },
    // Open  Tag Field
    addTagField() {
      this.editTag = { ...defaultTag };
      this.adding_tag = true;
    },
    // Add Tag
    async addTag() {
      if (this.can_add) {
        const newTag = { ...this.editTag };
        // Check for duplicate names
        const names = this.items.map(t => t.name.toLowerCase());
        if (names.includes(newTag.name.toLowerCase())) {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: 'Cannot add duplicated tags',
            duration: 5000,
            type: 'error'
          });
          return;
        }
        this.items.push(newTag);
        this.tags.push(newTag);
        this.editTag = { ...defaultTag };
        this.adding_tag = false;

        this.saveTags();
      }
    },
    // Update Tags Table
    updateTable() {
      this.$http
        .get('/tags')
        .then(response => {
          this.items = response.data;
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: 'Failed to fetch tags',
            duration: 5000,
            type: 'error'
          });
        });
    },
    // Save Tags
    async saveTags() {
      if (this.adding_tag) {
        await this.addTag();
      }
      // Handle Deleted Tags
      if (this.deleted.length > 0) {
        await this.trigger('delete', this.deleted);
      }
      // Handle New and Updated Tags
      if (this.tags.length > 0) {
        await this.trigger('post', this.tags);
      }
      // Handle Dirty Tags
      if (this.dirty.length > 0) {
        await this.trigger('put', this.dirty);
      }
    },
    // Trigger API Calls
    trigger(method, items) {
      return this.$http
        .request('/tags', {
          method,
          data: { items }
        })
        .then(response => {
          if (response.data.success) {
            this.$store.dispatch('tags/getTags');
            this.updateTable();
            // this.$notify({
            //   group: 'main',
            //   title: 'Success',
            //   text: 'Tags have been updated successfully',
            //   duration: 5000,
            //   type: 'success'
            // });
            this.resetEditState();
          } else {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: 'An error occurred while updating tags',
              duration: 5000,
              type: 'error'
            });
          }
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: 'Network error while updating tags',
            duration: 5000,
            type: 'error'
          });
        });
    },
    // Delete Tag
    deleteTag(tag) {
      const index = this.items.findIndex(t => t.id === tag.id);
      if (index !== -1) {
        this.items.splice(index, 1);
        this.deleted.push(tag);
      }
    },
    // Tag Clicked (for Editing Name and Color)
    tagClicked(tag) {
      this.editTag = { ...tag };
      this.adding_tag = false;
    },
    // Close Dialog
    close() {
      this.dialog = false;
      this.resetEditState();
    },
    // Open Dialog
    openDialog() {
      this.dialog = true;
    },
    // Toggle Hidden Status
    toggleHidden(tag) {
      if (!this.dirty.includes(tag)) {
        this.dirty.push(tag);
      }

      this.saveTags();
    },
    // Open Color Edit Dialog
    openColorEditDialog(tag) {
      this.colorEditTag = { ...tag };
      this.colorEditDialog = true;
    },
    // Update Color
    updateColor(tag) {
      const index = this.items.findIndex(t => t.id === tag.id);
      if (index !== -1) {
        this.items[index].color_code = tag.color_code;
        if (!this.dirty.includes(this.items[index])) {
          this.dirty.push(this.items[index]);
        }
      }
    },
    // Close Color Edit Dialog
    closeColorEditDialog() {
      this.colorEditDialog = false;
      this.colorEditTag = { ...defaultTag };

      this.saveTags();
    },
    generateRandomColor() {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    this.colorEditTag.color_code = randomColor;
  },
  }
};
</script>

<style scoped>
.tag_container {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  min-height: 60px;
}
.tag_container span {
  flex-grow: 1;
  font-size: 18px;
}
</style>
