function initialState() {
  return {
    complete: {
      shipments: [],
      errors: [],
      overAllProgress: 0,
      progressPercentage: 0,
      loading: false,
      disabled: false,
      count: null,
      start_time: null
    },
    validate: {
      shipments: [],
      errors: [],
      overAllProgress: 0,
      progressPercentage: 0,
      loading: false,
      disabled: false,
      count: null,
      start_time: null
    },
    selected: {
      shipments: [],
      errors: [],
      overAllProgress: 0,
      progressPercentage: 0,
      loading: false,
      disabled: false,
      count: null
    },
    purchased: false,
    handlingTotal: 0,
    postageTotal: 0,
    taxTotal: 0,
    total: 0,
    step: 1,
    file: null,
    presets: []
  };
}

const bulkImport = {
  namespaced: true,
  state: initialState(),
  getters: {
    presets: (state) => {
      return state.presets;
    }
  },
  actions: {
    getPresets({ commit }) {
      axios.get(`/import/presets`)
        .then((response) => response.data)
        .then((response) => {
          commit('updatePresets', response.presets);
        })
    },
    updatePresets({ commit }, presets) {
      commit('updatePresets', presets);
    }
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    updatePresets(state, presets) {
      state.presets = presets;
    }
  }
};

export default bulkImport;
