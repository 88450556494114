<template>
  <v-form
    data-vv-scope="location-selector"
    class="location-selector"
  >
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="localDropoffLocationId"
          v-validate="'required'"
          :error-messages="errs.collect('location-selector.dropoff_location')"
          data-vv-name="dropoff_location"
          dense
          outlined
          :items="dropoffLocations"
          label="Dropoff Location"
          hint="This location is where you will drop off your shipments."
          persistent-hint
          clearable
        />
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="localPickupLocationId"
          v-validate="'required'"
          :error-messages="errs.collect('location-selector.pickup_location')"
          data-vv-name="pickup_location"
          dense
          outlined
          :items="pickupLocations"
          label="Pickup Location"
          hint="This location is where you will pickup your returns and supplies."
          persistent-hint
          clearable
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="localRegionId"
          v-validate="'required'"
          disabled
          :error-messages="errs.collect('location-selector.region')"
          data-vv-name="region"
          dense
          outlined
          item-text="name"
          item-value="id"
          :items="regions"
          label="Region"
          hint="Your region is based on the location you will be dropping off your shipments."
          persistent-hint
          clearable
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

export default {
  props: {
    postalCode: {
      type: String,
      required: true,
    },
    dropoffLocationId: {
      type: Number,
      default: null
    },
    regionId: {
      type: Number,
      default: null
    },
    pickupLocationId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      allLocations: [],
      regions: [],
    };
  },
  computed: {
    pickupLocations() {
      return this.allLocations.filter(
        (branch) => branch.company_location && branch.region_id === this.regionId && branch.is_pickup_location
      );
    },
    dropoffLocations() {
      return this.allLocations.filter(
        (branch) => branch.is_dropoff_location
      );
    },
    localDropoffLocationId: {
      get() {
        return this.dropoffLocationId;
      },
      set(value) {
        this.$emit('update:dropoffLocationId', value);
      }
    },
    localRegionId: {
      get() {
        return this.regionId;
      },
      set(value) {
        this.$emit('update:regionId', value);
      }
    },
    localPickupLocationId: {
      get() {
        return this.pickupLocationId;
      },
      set(value) {
        this.$emit('update:pickupLocationId', value);
      }
    }
  },
  watch: {
    async postalCode(newPostalCode, oldPostalCode) {
      // remove whitespace from postal code
      if (newPostalCode === null || newPostalCode === undefined) {
        newPostalCode = '';
      }

      newPostalCode = newPostalCode.replace(/\s/g, '');

      if (newPostalCode !== oldPostalCode && newPostalCode.length === 6) {
        console.log('Postal code changed:', newPostalCode);
        await this.getBranches();
      }
    },
    localDropoffLocationId() {
      this.setRegionId();
    },
  },
  mounted() {
    this.getBranches();
    this.getRegions();
  },
  methods: {
    getRegions() {
      this.$http.get('/regions').then((response) => {
        this.regions = response.data;
      });
    },
    async getBranches() {
      try {
        const response = await this.$http.get(`/branches`);
        const branches = response.data;

        const originLatLng = await this.getLatLngForPostalCode(this.postalCode);
        const locationsWithDistance = await Promise.all(branches.map(async (branch) => {
          const branchLatLng = await this.getLatLngForPostalCode(branch.postal_code);
          if(originLatLng.lat === 0 && originLatLng.lng === 0) {
            return { ...branch, distance: 0 };
          }
          const distance = this.calculateDistance(
            originLatLng.lat,
            originLatLng.lng,
            branchLatLng.lat,
            branchLatLng.lng
          );
          return { ...branch, distance };
        }));

        this.allLocations = locationsWithDistance
          .sort((a, b) => a.distance - b.distance)
            .map((item) => {
              let distanceText = item.distance !== 0 ? `(${item.distance.toFixed(2)} km)` : '';
              return {
                value: item.id,
                text: `${item.city} - ${item.address1}, ${item.city} ${item.province_code}, ${item.postal_code} ${distanceText}`,
                lat: item.lat || null,
                lng: item.lng || null,
                region_id: item.region_id,
                postal_code: item.postal_code,
                company_location: item.company_location,
                is_dropoff_location: item.is_dropoff_location,
                is_pickup_location: item.is_pickup_location
              };
            });
      } catch (error) {
        console.error('Failed to get branches:', error);
      }
    },
    async findClosestBranches(targetPostalCode) {
      const targetLatLng = await this.getLatLngForPostalCode(targetPostalCode);
      let closestBranches = this.allLocations
        .map(location => ({
          ...location,
          distance: this.calculateDistance(
            targetLatLng.lat,
            targetLatLng.lng,
            location.lat,
            location.lng
          )
        }))
        .sort((a, b) => a.distance - b.distance)
        .filter((location, index, array) => location.distance === array[0].distance);

      return closestBranches.map(({ value, text }) => ({ value, text }));
    },
    async getLatLngForPostalCode(postalCode) {

      return new Promise((resolve, reject) => {
        if (!window.google || !window.google.maps || !window.google.maps.Geocoder) {
          console.error('Google Maps API is not loaded.');
          reject(new Error('Google Maps API is not loaded.'));
          return;
        }

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: postalCode }, (results, status) => {
          if (status === 'OK' && results && results.length > 0) {
            const location = results[0].geometry.location;

            console.log(`Postal code ${postalCode} found at ${location.lat()}, ${location.lng()}`);
            resolve({ lat: location.lat(), lng: location.lng() });
          } else {
            console.error(`Postal code ${postalCode} not found`);
            resolve({ lat: 0, lng: 0 });
          }
        });
      });
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = ((lat2 - lat1) * Math.PI) / 180;
      const dLon = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    },
    setRegionId() {
      const location = this.dropoffLocations.find(
        (location) => location.value === this.localDropoffLocationId
      );

      if (!location) {
        return;
      }

      this.localRegionId = location.region_id

      // if this is a company location, set the pickup location to the same location
      if (location.company_location) {
        this.localPickupLocationId = location.value;
      }
    },
    validate() {
      this.$validator.validateAll('location-selector').then(result => {
         this.$emit('validationResult', result);
      });
    }
  },
};
</script>
<style>
.location-selector .v-messages__message {
  font-size: 12px;
}
</style>