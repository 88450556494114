<template>
  <StallionDialog
    v-model="dialog"
    :width="800"
    :disabled="disabled"
    :title="dialogTitle"
    cancel-label="Close"
  >
    <template #content>
      <div style="min-height: 200px;">
        <v-layout v-if="initialLoading">
          <v-flex
            xs12
            text-center
            class="mt-4"
          >
            <v-progress-linear
              height="30"
              class="mb-3"
              indeterminate
            />
            <h3 class="ma-3">
              Preparing your shipments...
            </h3>
          </v-flex>
        </v-layout>

        <v-layout
          v-else-if="showErrorsAndWarnings"
          column
        >
          <ShipmentsTable
            ref="refShipmentsTable"
            :shipments="errorShipments"
            :batch="batch"
            @removeFromBatch="removeFromBatch"
          />
        </v-layout>

        <v-layout v-else-if="showCostSummary">
          <v-row justify="center">
            <v-col cols="12">
              <v-card outlined>
                <v-data-table
                  :items="groupedShipments"
                  :headers="[
                    { text: 'Postage Type', value: 'postage_type_id' },
                    { text: 'Count', value: 'count' },
                    { text: 'Total', value: 'total', align: 'end'}
                  ]"
           
                  :hide-default-footer="true"
                >
                  <template #item.postage_type_id="{ item }">
                    <span v-html="$options.filters.postage(item)" />
                  </template>

                  <template #item.total="{ item }">
                    <span v-if="item.total">
                      {{ item.total | currency }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>

              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="6">
                          <strong>Total Cost:</strong>
                        </v-col>
                        <v-col
                          cols="6"
                          class="text-right"
                        >
                          <strong>{{ batch?.validated_cost | currency }}</strong>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-layout>


        <v-layout v-else-if="batch && shipments.length > 0">
          <batch-details
            ref="refBatchDetails"
            :batch="batch"
            :shipments="shipments"
            :label-type="labelType"
            :progress-percentage="progressPercentage"
            :is-batch-validated="isBatchValidated"
            :is-batch-completed="isBatchCompleted"
            @getBatch="getBatch"
            @confirmPurchase="confirmPurchase"
          />
        </v-layout>

        <v-layout v-else>
          <v-row>
            <v-col class="text-center">
              <h2>
                No shipments to process.
              </h2>
            </v-col>
          </v-row>
        </v-layout>

        <purchase-confirm-dialog
          v-model="showPurchaseConfirmDialog"
          :batch="batch"
          :confirm-purchase-handler="submitPurchase"
        />
      </div>
    </template>

    <template #actions>
      <v-btn
        v-if="batch && stepValidation && !isBatchProcessing"
        color="primary"
        :disabled="deleteLoading"
        :loading="deleteLoading"
        outlined
        @click="deleteBatch"
      >
        Cancel
      </v-btn>

      <v-btn
        v-if="batch"
        color="primary"
        :disabled="deleteLoading"
        outlined
        @click="$router.push(`/batches/${batch.id}`)"
      >
        View
      </v-btn>

      <v-btn
        v-if="batch"
        color="primary"
        :disabled="!canCompleteBatch || deleteLoading"
        @click="confirmPurchase"
      >
        Complete 
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import ShipmentsTable from './ShipmentsTable.vue'
import BatchDetails from './BatchDetails.vue'
import PurchaseConfirmDialog from '../../../../../pages/main/batches/PurchaseConfirmDialog.vue';

const STEP_VALIDATION = 1;
const STEP_PURCHASE = 2;

export default {
  components: {
    ShipmentsTable,
    BatchDetails,
    PurchaseConfirmDialog
  },
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    labelType: {
      default: 'shipments',
      type: String
    }
  },

  data() {
    return {
      step: STEP_VALIDATION,
      batch: null,
      timer: null,
      showPurchaseConfirmDialog: false,
      deleteLoading: false,
      purchaseLoading: false,
      progressPercentage: 0,
      initialLoading: false,
      dialog: false,
      shipments: [],
    };
  },
  computed: {
    dialogTitle() {
      if (this.isBatchProcessing && this.stepValidation) {
        console.log('validating')
        return 'Label Batch Status: Validating'
      } else if (this.showErrorsAndWarnings) {
        console.log('errors and warnings')
        return 'Errors and Warnings'
      } else if (this.showCostSummary) {
        console.log('cost summary')
        return 'Cost Summary'
      } else if (this.isBatchProcessing && this.step == STEP_PURCHASE) {
        console.log('purchasing')
        return 'Label Batch Status: Purchasing'
      } else {
        console.log('default')
        return 'Label Batch Status';
      }
    },
    showErrorsAndWarnings() {
      return !this.isBatchProcessing && this.stepValidation && this.errorShipments.length > 0
    },
    showCostSummary() {
      return !this.isBatchProcessing && this.stepValidation && this.errorShipments.length === 0
    },
    stepValidation() {
      return this.step == STEP_VALIDATION
    },
    isBatchProcessing() {
      return this.batch && this.batch.processing == 1
    },
    incompleteShipments() {
      return this.shipments.filter(a => a.status_id == 3);
    },
    errorShipments() {
      return this.shipments.filter(a => a.error == 1);
    },
    isBatchValidated() {
      return !this.isBatchProcessing && this.batch.validated > 0
    },
    isBatchCompleted() {
      return !this.isBatchProcessing && this.batch.complete > 0
    },
    canCompleteBatch() {
      return this.isBatchValidated && this.errorShipments.length === 0 && !this.purchaseLoading
    },
    groupedShipments() {
      // Group shipments by postage type and sum total cost
      const grouped = this.shipments.reduce((acc, item) => {
        const key = item.postage_type_id;
        
        if (!acc[key]) {
          acc[key] = {
            postage_type_id: key,
            count: 0,
            total: 0
          };
        }

        acc[key].count++;
        
        // Convert item.total to a number before adding
        const itemTotal = Number(item.total);
        
        if (isNaN(itemTotal)) {
          console.warn(`Invalid total for item with postage_type_id ${key}:`, item.total);
          // Handle the error as needed, e.g., skip adding, set to 0, etc.
        } else {
          acc[key].total += itemTotal;
        }

        return acc;
      }, {});

      return Object.values(grouped);
    }
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.resetDialog();
        this.$emit('update');
      }
    }
  },

  methods: {
    resetDialog() {
      this.step = STEP_VALIDATION
      this.shipments = [];
      this.batch = null;
      this.showPurchaseConfirmDialog = false;
      this.purchaseLoading = false;
      this.processed_count = 0;
      this.progressPercentage = 0;
      this.initialLoading = false;
      this.cancelAutoUpdate()
    },
    removeFromBatch(items) {
      if (!Array.isArray(items)) {
        items = [items]
      }

      const ids = items.map(a => a.id);
      this.shipments = this.shipments.filter(a => !ids.includes(a.id));

      this.$store.dispatch('batches/getOpenBatches');
    },
    async openFromShipments(shipments) {
      // call from components/main/shipments/action-bar/ActionBar.vue
      this.resetDialog()
      this.shipments = _cloneDeep(shipments);
      this.dialog = true;

      this.initialLoading = true
      try {
        await this.createBatch()
        await this.validateBatch()
      } catch (e) {
        console.log(e)
        this.dialog = false
        this.errorMessage('Could not create batch from shipments')
      }
      this.initialLoading = false
    },
    async openFromOrders(orders) {
      // call from pages/main/orders/Index.vue
      this.resetDialog()

      this.dialog = true
      this.initialLoading = true
      try {
        const response = await this.$http
          .post('/shipments/quick-ship-order-labels', {
            ids: orders.map(a => a.id)
          })

        this.shipments = response.data

        await this.createBatch()
        await this.validateBatch()
      } catch (e) {
        console.log(e)
        this.dialog = false
        this.errorMessage('Could not create batch from orders')
      }
      this.initialLoading = false
    },
    async createBatch() {
      try {
        const ids = this.shipments.map(a => a.id);

        const response = await this.$http
          .post(`/shipments/batch-add`, {
            ids: ids
          })

        await this.getBatch(response.data.batch.id);
        this.$store.dispatch('batches/getOpenBatches');
      } catch (e) {
        console.log(e)
        throw 'Could not add selected shipments to the batch';
      }
    },
    async validateBatch() {
      if (!this.batch) {
        return;
      }

      this.step = STEP_VALIDATION

      try {
          await this.$http.post(`/batches/${this.batch.id}/validate`)
          await this.getBatch(this.batch.id);
      } catch (e) {
        console.log(e)
      }
    },
    async getBatch(id) {
      if (!id) {
        return;
      }

      try {
        const response = await this.$http.get(`/batches/${id}`)
        this.batch = _cloneDeep(response.data);

        if (this.batch.count >= this.batch.processed_count) {
          this.progressPercentage = (this.batch.processed_count / this.batch.count) * 100;
        }

        if (this.batch.processing == 0) {
          Event.fire('get-credits');

          if (this.step == STEP_VALIDATION) {
            await this.getShipments();
            this.progressPercentage = 0;
          }
        } else {
          // the batch is processing
          this.autoRefresh();
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getShipments() {
      if (!this.batch.id) {
        return;
      }

      try {
        const response = await this.$http.get(`/batches/shipments`, {
          params: {
            ids: [this.batch.id]
          }
        })

        this.shipments = response.data;
      } catch (e) {
        console.log(e)
      }
    },
    async confirmPurchase() {
      if (!this.batch) {
        return
      }

      const valid = this.creditCheck(this.batch.validated_cost);

      if (valid) {
        this.submitPurchase()
      }
    },
    async submitPurchase() {
      if (!this.batch) {
        return
      }

      const valid = this.creditCheck(this.batch.validated_cost);

      if (valid) {
        const data = {
          shipments: []
        };

        this.step = STEP_PURCHASE;

        this.purchaseLoading = true;
        try {
          await this.$http.post(`/batches/${this.batch.id}/purchase`, data)
          await this.getBatch(this.batch.id)
        } catch (e) {
          console.log(e)
        }
        this.purchaseLoading = false;
      }
    },
    async deleteBatch() {
      this.deleteLoading = true;
      try {
        await this.$http.delete(`/batches/${this.batch.id}`)
        this.$notify({
                group: 'main',
                title: 'Success',
                text: 'Batch has been deleted successfully',
                duration: 5000,
                type: 'success'
              });
        this.dialog = false

        this.$store.dispatch('batches/getOpenBatches');
      } catch (e) {
        console.log(e)
        this.errorMessage('Could not delete batch')
      }
      this.deleteLoading = false;
    },
    autoRefresh() {
      let self = this;
      this.timer = setTimeout(function () {
        self.getBatch(self.batch?.id);
      }, 1000);
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    }
  }
};
</script>
