<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Print"
    @onDialogClosed="$emit('onDialogClosed')"
  >
    <template #content>
      <v-layout
        v-if="labelsCount > max_ship"
        wrap
      >
        <v-flex
          xs12
          text-center
        >
          <h3>
            {{ labelsCount }} shipment
            {{ labelsCount | pluralize(pluralizePrintType) }} ready to be
            merged
          </h3>
        </v-flex>

        <v-flex
          v-if="localPrintType != 'label_only'"
          mt-2
          xs12
          text-center
        >
          <v-select
            v-model="packing_slip_template_id"
            name="template"
            label="Packing Slip Template"
            :items="packingSlipTemplates"
            item-text="name"
            item-value="id"
            :clearable="true"
            placeholder="Default Packing Slip"
            persistent-placeholder
          />
        </v-flex>

        <v-flex xs12>
          <v-select
            v-model="sort_by"
            label="Sort By"
            :items="sort_items"
          />
        </v-flex>
        <v-flex xs12>
          <v-select
            v-model="sort_order"
            label="Sort Order"
            :items="sort_order_items"
          />
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="name"
            label="Label Name (Optional)"
          />
        </v-flex>
        <v-flex xs12>
          <v-switch
            v-model="group_by_filter"
            label="Group by Filter"
          />
        </v-flex>
      </v-layout>

      <v-layout v-else-if="loading">
        <v-flex
          xs12
          text-center
          mt-12
          mb-12
        >
          <div
            class="loading-content"
            style="margin: auto"
          >
            <atom-spinner
              style="margin: auto"
              :animation-duration="1000"
              :size="100"
              color="#f9ac40"
            />
            <h3 style="text-align: center; color: #9d9da6">
              Generating your
              {{ labelsCount | pluralize(pluralizePrintType) }}
            </h3>
          </div>
        </v-flex>
      </v-layout>

      <v-layout
        v-else
        wrap
      >
        <v-flex
          xs12
          text-center
        >
          <h3>
            {{ labelsCount }} shipment
            {{ labelsCount | pluralize(pluralizePrintType) }} ready to be
            printed
          </h3>
        </v-flex>

        <v-flex
          v-if="localPrintType != 'label_only'"
          mt-2
          xs12
          text-center
        >
          <v-select
            v-model="packing_slip_template_id"
            name="template"
            label="Packing Slip Template"
            :items="packingSlipTemplates"
            item-text="name"
            item-value="id"
            :clearable="true"
            placeholder="Default Packing Slip"
            persistent-placeholder
          />
        </v-flex>

        <v-flex
          v-if="labelsCount > 1"
          xs12
        >
          <v-select
            v-model="sort_by"
            label="Sort By"
            :items="sort_items"
          />
        </v-flex>
        <v-flex
          v-if="labelsCount > 1"
          xs12
        >
          <v-select
            v-model="sort_order"
            label="Sort Order"
            :items="sort_order_items"
          />
        </v-flex>

        <v-flex
          v-if="labelsCount > 1"
          xs12
        >
          <v-switch
            v-model="group_by_filter"
            label="Group by Filter"
          />
        </v-flex>

        <v-flex
          v-show="showWarning"
          xs12
          text-center
          pa-3
        >
          <v-alert
            type="info"
            outlined
            dense
            style="font-size: .8rem; line-height: .9rem"
          >
            <strong>USPS Economy</strong>, <strong>UPS Economy</strong> and
            <strong>PostNL</strong> labels are required to be printed on 4x6
            thermal paper.
          </v-alert>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        v-if="labelsCount < max_ship"
        outlined
        color="primary"
        download
        :disabled="loading || validShipments.length === 0"
        @click.prevent="getLabel('download')"
      >
        <v-icon
          small
          dark
        >
          mdi-download
        </v-icon>Download
      </v-btn>

      <v-btn
        v-if="labelsCount < max_ship"
        color="primary"
        class="px-4"
        :disabled="loading || validShipments.length === 0"
        @click="getLabel('print')"
      >
        <v-icon
          small
          dark
        >
          mdi-printer
        </v-icon>Print
      </v-btn>

      <v-btn
        v-if="labelsCount >= max_ship"
        color="primary"
        download
        :disabled="loading || validShipments.length === 0"
        @click.prevent="getLabel('download')"
      >
        Generate
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';
import _isFunction from 'lodash/isFunction'
import _find from 'lodash/find'

export default {
  components: {
    AtomSpinner
  },
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Boolean,
      default: false
    },
    printType: {
      type: String,
      default: 'label_only'
    }
  },

  data() {
    return {
      loading: false,
      sort_items: [
        { text: 'Created At', value: 'id' },
        { text: 'Order ID', value: 'order_id' },
        { text: 'SKU', value: 'sku' },
        { text: 'Recipient', value: 'name' },
        { text: 'Import Order', value: 'row' },
        { text: 'Warehouse Location', value: 'warehouse_location' }
      ],
      sort_order_items: [
        { text: 'Descending', value: 'desc' },
        { text: 'Ascending', value: 'asc' }
      ],
      packing_slip_template_id: null,
      sort_by: 'id',
      sort_order: 'asc',
      label_name: null,
      status: null,
      action: null,
      max_ship: 100,
      name: null,

      // optional callback from parent component
      // ex: automatically closes the print dialog when auto print settings is enablead
      // ref: QuickShipCompleteDialog.vue@watch
      // ref: complete.vue@watch
      printCallBack: null,
      group_by_filter: false,
      localPrintType: this.printType
    };
  },
  computed: {
    packingSlipTemplates() {
      return this.$store.getters['packingSlipTemplates/templates'];
    },
    labelsCount: function () {
      var count = 0;
      this.validShipments.forEach(function () {
        count++;
      });
      return count;
    },
    validShipments: function () {
      return this.shipments.filter(shipment => {
        return this.is_printable(shipment);
      });
    },
    showWarning: function () {
      let show = false;
      this.validShipments.forEach(function (shipment) {
        if ([30, 31, 32, 33, 60, 61, 170].includes(shipment.postage_type_id)) {
          show = true;
        }
      });

      return show;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    queueLabelUrl: function () {
      let url = '/shipments/printLarge';
      return url;
    },
    smallLabelUrl: function () {
      let url = '/shipments/print';
      return url;
    },
    pluralizePrintType: function () {
      // label_only
      // packing_slip
      // label_with_packing_slip
      return this.localPrintType == 'label_only'
        ? ' label'
        : ' ' + this.localPrintType.split('_').join(' ');
    },
    labelFormat: function () {
      let f = this.$store.state.qz.printerFormat ?? 'pdf';

      // not pdf or zpl
      if (f !== 'pdf' && f !== 'zpl') {
        return 'pdf';
      }
      // lowercase the format
      return f.toLowerCase();
    }
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.setDefaultPackingSlipTemplate();
        }
      }
    }
  },
  mounted() {
    console.log('PrintShipments mounted');
    Event.fire('labels-printed');
  },

  methods: {
    setDefaultPackingSlipTemplate() {
      if (this.packingSlipTemplates.length == 0) {
        return;
      }

      const defaultTemplate = _find(this.packingSlipTemplates, { is_default: 1 });
      if (defaultTemplate) {
        this.packing_slip_template_id = defaultTemplate.id;
      }
    },
    getLabel(action, printCallBack) {
      this.printCallBack = printCallBack
      this.action = action;
      this.loading = true;
      console.log('getLabel', this.localPrintType);
      if (this.shipments.length > this.max_ship && this.labelFormat == 'pdf') {
        this.queueLabel();
      } else {
        this.getSmallLabel();
      }
    },
    /**
     * Queue the label to be created
     */
    queueLabel: function () {
      var ids = [];
      this.validShipments.forEach(function (shipment) {
        ids.push(shipment.id);
      });

      this.$http
        .post(this.queueLabelUrl, {
          ids: ids,
          sort_by: this.sort_by,
          sort_order: this.sort_order,
          name: this.name,
          packing_slip_template_id: this.packing_slip_template_id,
          type: this.localPrintType,
          label_format: this.labelFormat,
          group_by_filter: this.group_by_filter
        })
        .then(response => {
          this.label_name = response.data;

          if (this.localPrintType == 'label_only') {
            this.$router.push({
              name: 'downloads'
            });
          } else {
            this.$router.push('/print-jobs');
          }

          // close dialogs / already redirected to another page
          this.close()

          Event.fire('labels-printed');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /**
     * Get the completed label that was queued
     */
    getLargeLabel: function () {
      if (this.action == 'print') {
        this.printUrl(this.name, true);
        this.loading = false;
      } else {
        this.downloadFileUrl(this.name, 'StallionLabels.pdf');
      }
    },
    /**
     * Dont queue download and get just label with straight call
     */
    getSmallLabel: function () {
      if (this.shipments.length > this.max_ship && this.labelFormat == 'pdf') {
        this.printLarge();
      } else {
        var ids = [];
        this.validShipments.forEach(function (shipment) {
          ids.push(shipment.id);
        });

        let printer = localStorage.printer;
        let queryData = {};
        if (printer !== null && this.action == 'print' && this.$store.state.qz.qzStatus) {
          queryData = {
            ids: ids,
            sort_by: this.sort_by,
            sort_order: this.sort_order,
            base: true,
            packing_slip_template_id: this.packing_slip_template_id,
            type: this.localPrintType,
            label_format: this.labelFormat,
            group_by_filter: this.group_by_filter
          }
        } else {
          queryData = {
            ids: ids,
            sort_by: this.sort_by,
            sort_order: this.sort_order,
            packing_slip_template_id: this.packing_slip_template_id,
            type: this.localPrintType,
            label_format: this.labelFormat,
            group_by_filter: this.group_by_filter
          }
        }

        console.log('queryData', queryData);

        this.$http
          .post(
            this.smallLabelUrl,
            queryData
          )
          .then(response => {
            if (this.action == 'print') {
              this.printUrl(response.data, true);
            } else {
              // this.downloadFileUrl(response.data, 'StallionLabels.pdf');
              this.downloadFile(ids, response.data);
            }
            if (_isFunction(this.printCallBack)) {
              this.printCallBack()
            }

            console.log('Firing labels-printed event');
            Event.fire('labels-printed');
          })
          .catch(() => {
            if (_isFunction(this.printCallBack)) {
              this.printCallBack()
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    downloadFile(ids, data) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }

      const extention = this.labelFormat

      let shipment = this.shipments.find(shipment => shipment.ship_code == ids[0]);
      if (ids.length > 1 || !shipment) {
        this.downloadFileUrl(data, `StallionLabels.${extention}`);
        return;
      }

      let filename = '';
      const store = shipment.store_nickname || shipment.store;
      if (store) {
        filename += store + '_';
      }

      filename += shipment.display_order_id || shipment.order_id || shipment.ship_code;
      if (shipment.return_label) {
        filename += '_RETURNLABEL';
      } else {
        filename += '_LABEL';
      }

      this.downloadFileUrl(data, `${filename.replace('.', '_')}.${extention}`);
    },

    /**
     * Close print dialog and single flow dialog
    */
    close: function () {
      this.show = false
      Event.fire('close-singleflow');
    },

    setPrintingData(printingSettings = {}) {
      const keysToUpdate = ['sort_by', 'sort_order', 'group_by_filter', 'packing_slip_template_id'];

      keysToUpdate.forEach(key => {
        if (printingSettings[key] !== undefined) {
          this[key] = printingSettings[key];
        }
      });
    },
    setPrintType(printType) {
      this.localPrintType = printType;
    }
  }
};
</script>
