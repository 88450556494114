<template>
  <!-- Store -->
  <div
    v-if="shipment && shipment.order_id !== null"
    xs12
  >
    <h4 v-if="showHeader">
      Store
    </h4>
    <div
      v-if="shipment.store"
      class="mb-3"
    >
      {{ shipment.store }}
    </div>

    <div
      v-if="shipment.order_id"
      class="mb-3"
    >
      <span v-if="shipment.store_id == 1">
        <v-icon small>fab fa-amazon</v-icon>
      </span>
      <span v-if="shipment.store_id == 2">
        <v-icon small>fab fa-ebay</v-icon>
      </span>
      <span v-if="shipment.store_id == 3">
        <v-icon small>fab fa-etsy</v-icon>
      </span>
      <span v-if="shipment.store_id == 4">
        <v-icon small>fab fa-shopify</v-icon>
      </span>
      <span v-if="shipment.store_id == 5">
        <v-icon small>mdi-cogs</v-icon>
      </span>
      <span v-if="shipment.store_id == 6">
        <v-icon small>$bigcommerce</v-icon>
      </span>
      <span v-if="shipment.store_id == 7">
        <v-icon small>$woocommerce</v-icon>
      </span>
      <span
        class="ml-1"
        v-html="$options.filters.orderLink(shipment)"
      />
    </div>

    <div v-if="!shipment.return_label">
      <!--  Status -->
      <div
        v-if="shipment.fulfillment_status_id !== null"
        class="d-flex flex-row justify-space-between"
      >
        <div>Status</div>

        <div
          v-html="
            $options.filters.fulfillment_status(shipment.fulfillment_status_id)
          "
        />
      </div>

      <!-- Fulfillment At -->
      <div
        v-if="
          shipment.fulfill_at !== null && shipment.fulfillment_status_id === 1
        "
        class="d-flex flex-row justify-space-between"
      >
        <div>Fulfillment At</div>
        <div>{{ shipment.fulfill_at | datetime_from }}</div>
      </div>

      <!-- Tracking Type -->
      <div
        v-if="shipment.fulfillment_status_id !== null"
        class="d-flex flex-row justify-space-between"
      >
        <div>Tracking Type</div>

        <div>
          <span v-if="shipment.stallion_fulfillment == 99">USPS Tracking</span>
          <span v-else-if="shipment.stallion_fulfillment">Stallion Tracking</span>
          <span v-else-if="!shipment.stallion_fulfillment">Carrier Tracking</span>
        </div>
      </div>

      <!-- Fullfillment Options -->
      <div
        v-if="shipment.user_store_id && shipment.fulfillment_status_id !== 2"
        class="text-center mt-4 mb-4"
      >
        <a
          v-if="shipment.fulfillment_status_id === 1"
          href="javascript:void(0);"
          @click="cancelFulfillment"
        >Cancel Fulfillment</a>
        <a
          v-else-if="
            shipment.fulfillment_status_id === 3 ||
              shipment.fulfillment_status_id === 4
          "
          href="javascript:void(0);"
          @click="fulfillNow"
        >Fulfill Now</a>
      </div>
    </div>

    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    cancelFulfillment() {
      this.$http
        .post(
          `/shipments/${this.shipment.id}/cancel-fulfillment`,
          this.shipment
        )
        .then(() => {
          this.shipment.fulfillment_status_id = 4;
          this.shipment.fulfillment_status.description = 'Canceled';

          this.$notify({
            group: 'main',
            title: 'Fulfillment Canceled',
            text: `The fulfillment for shipment ${this.shipment.ship_code} has been canceled`,
            duration: 5000,
            type: 'success'
          });
        })
        .catch((err) => {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: err,
            duration: 5000,
            type: 'error'
          });
        });
    },
    fulfillNow() {
      this.$http
        .post(`/shipments/${this.shipment.id}/fulfill-now`, this.shipment)
        .then(() => {
          this.shipment.fulfillment_status_id = 1;
          this.shipment.fulfill_at = null;
          this.shipment.fulfillment_status.description = 'Pending';

          this.$notify({
            group: 'main',
            title: 'Fulfillment Requested',
            text: `The fulfillment for shipment ${this.shipment.ship_code} has been requested`,
            duration: 5000,
            type: 'success'
          });
        })
        .catch((err) => {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: err,
            duration: 5000,
            type: 'error'
          });
        });
    }
  }
};
</script>
