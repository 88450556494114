<template>
  <div>
    <!-- Added root element -->
    <!-- Display Maximum Cost and Maximum Delivery Days at the top -->
    <div
      v-if="shipment.maximum_cost || shipment.maximum_delivery_days"
      class="max-limits"
    >
      <p v-if="shipment.maximum_cost">
        <strong>Maximum Cost:</strong> {{ shipment.maximum_cost | currency }}
      </p>
      <p v-if="shipment.maximum_delivery_days">
        <strong>Maximum Delivery Days:</strong> {{ shipment.maximum_delivery_days }}
      </p>
    </div>

    <v-list
      one-line
      subheader
      dense
    >
      <v-list-item
        v-for="(postage_rate, index) in postageRates"
        :key="'A' + index"
        dense
        :class="dense ? 'px-0' : ''"
        ripple
        @click="updateRateHandler(postage_rate)"
      >
        <v-list-item-action :class="dense ? 'mr-2 my-0' : ''">
          <v-radio-group
            v-model="shipment.postage_type_id"
            :dense="dense"
          >
            <v-radio :value="postage_rate.postage_type_id" />
          </v-radio-group>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-tooltip top>
              <template #activator="{ on }">
                <span
                  v-on="on"
                  v-html="$options.filters.postage(postage_rate)"
                />
                <span v-if="postage_rate.postage_provider_id == 120">¹</span>
              </template>
              <span v-html="$options.filters.postage(postage_rate)" />
            </v-tooltip>
            <strong v-if="$vuetify.breakpoint.mdAndUp && !dense">
              ({{ postage_rate.package_type }})
            </strong>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="postage_rate.details">{{ postage_rate.details }}</span>
            <span v-if="postage_rate.delivery_days">
              {{ postage_rate.delivery_days }} business
              {{ postage_rate.delivery_days == 1 ? 'day' : 'days' }}
            </span>
            <!-- Display messages if rate exceeds maximum cost or delivery days -->
            <div
              v-if="rateExceedsLimits(postage_rate).exceedsCost"
              class="warning-message"
            >
              Rate exceeds maximum cost
            </div>
            <div
              v-if="rateExceedsLimits(postage_rate).exceedsDeliveryDays"
              class="warning-message"
            >
              Rate exceeds maximum delivery days
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          {{ postage_rate.base_rate | currency }}
        </v-list-item-action>
      </v-list-item>

      <div
        v-if="showTotal && rate"
        cols="12"
      >
        <h3>Summary</h3>
        <v-simple-table
          :dense="dense"
          class="total-table"
        >
          <template #default>
            <tbody>
              <tr v-if="rate.base_rate">
                <td>Rate</td>
                <td>
                  {{ (rate.base_rate + rate.handling_fee) | currency }}
                </td>
              </tr>

              <tr
                v-for="(addon, index) in rate.add_ons"
                :key="index"
              >
                <td>{{ addon.name }}</td>
                <td>{{ addon.cost | currency }}</td>
              </tr>

              <tr v-if="rate.tax">
                <td>Tax</td>
                <td>{{ rate.tax | currency }}</td>
              </tr>

              <tr v-if="rate.duty_tax || rate.duty">
                <td>Duty & Tax</td>
                <td>
                  {{
                    (parseFloat(rate.duty_tax) + parseFloat(rate.duty)) |
                      currency
                  }}
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Total</strong>
                </td>
                <td v-if="rate.total">
                  {{ rate.total | currency }}
                </td>
                <td v-else>
                  -
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-list>
  </div> <!-- Closing root element -->
</template>

<script>
export default {
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    shipment: {
      required: true,
      type: Object,
    },
    postageRates: {
      default: () => [],
      type: Array,
    },
    updateRateHandler: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      rate: null,
    };
  },
  watch: {
    'shipment.postage_type_id': {
      immediate: true,
      handler() {
        if (this.shipment.postage_type_id) {
          const filtered = this.postageRates.filter(
            (m) => m.postage_type_id === this.shipment.postage_type_id
          );

          if (filtered.length === 1) {
            this.rate = filtered[0];
          } else {
            this.rate = null;
          }
        } else {
          this.rate = null;
        }
      },
    },
    postageRates: {
      immediate: true,
      handler() {
        // Get rate that matches the shipment postage type
        if (this.shipment.postage_type_id) {
          const filtered = this.postageRates.filter(
            (m) => m.postage_type_id === this.shipment.postage_type_id
          );

          if (filtered.length === 1) {
            this.rate = filtered[0];
          } else {
            this.rate = null;
          }
        } else {
          this.rate = null;
        }

        // Check if lowest postage ids is set for shipment
        // Assume that this.postageRates is already sorted by lowest in BE
        if (
          this.rate === null &&
          this.shipment.lowest_postage_ids &&
          Array.isArray(this.shipment.lowest_postage_ids)
        ) {
          const postageIds = this.shipment.lowest_postage_ids.map((m) =>
            parseInt(m, 10)
          );
          const maximum_cost = this.shipment.maximum_cost;
          const maximum_delivery_days = this.shipment.maximum_delivery_days;

          for (let i = 0; i < this.postageRates.length; i++) {
            // Parse delivery days to integer
            let delivery_days = this.postageRates[i].delivery_days;
            if (typeof delivery_days === 'string') {
              if (delivery_days.includes('-')) {
                delivery_days = delivery_days.split('-')[1];
              }
              delivery_days = parseInt(delivery_days, 10);
            }

            if (postageIds.includes(this.postageRates[i].postage_type_id)) {
              if (
                maximum_cost &&
                this.postageRates[i].total > maximum_cost
              ) {
                console.log(
                  'Rate exceeds maximum cost ' +
                    this.postageRates[i].total +
                    ' > ' +
                    maximum_cost
                );
                // Skip rate if it exceeds maximum cost
                continue;
              }

              if (
                maximum_delivery_days &&
                delivery_days > maximum_delivery_days
              ) {
                console.log(
                  'Rate exceeds maximum delivery days ' +
                    delivery_days +
                    ' > ' +
                    maximum_delivery_days
                );
                // Skip rate if it exceeds maximum delivery days
                continue;
              }

              this.rate = this.postageRates[i];
              break;
            }
          }
        }

        this.updateRateHandler(this.rate);
      },
    },
  },
  methods: {
    rateExceedsLimits(postage_rate) {
      const result = {
        exceedsCost: false,
        exceedsDeliveryDays: false,
      };

      const maximum_cost = this.shipment.maximum_cost;
      const maximum_delivery_days = this.shipment.maximum_delivery_days;

      // Check cost
      if (maximum_cost && postage_rate.total > maximum_cost) {
        result.exceedsCost = true;
      }

      // Check delivery days
      let delivery_days = postage_rate.delivery_days;
      if (typeof delivery_days === 'string') {
        if (delivery_days.includes('-')) {
          delivery_days = delivery_days.split('-')[1];
        }
        delivery_days = parseInt(delivery_days, 10);
      }

      if (maximum_delivery_days && delivery_days > maximum_delivery_days) {
        result.exceedsDeliveryDays = true;
      }

      return result;
    },
  },
};
</script>

<style scoped>
.max-limits {
  margin-bottom: 16px;
  font-size: 16px;
}

.max-limits p {
  margin: 0;
}

.total-table td:nth-child(2) {
  text-align: right;
}

.total-table tr:last-child {
  background-color: #eee;
}

.warning-message {
  color: red;
  font-weight: bold;
}
</style>
