<template>
  <div class="mt-2">
    <v-menu
      bottom
      open-on-click
      offset-y
      :rounded="rounded"
    >
      <template #activator="{ on }">
        <v-btn
          :ripple="false"
          avatar
          depressed
          small
          class="pa-1 ml-1 no-hover"
          style="background-color: #fff !important"
          v-on="on"
        >
          <v-avatar
            class="mr-2"
            color="#455a64"
            size="32"
          >
            <span
              class="white--text text-h3"
              style="font-size: 14px !important"
            >{{ $store.state.main.initials }}</span>
          </v-avatar>
          <v-icon
            color="#454545"
            small
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <v-list
        v-for="(group, index) in user_menu"
        :key="index"
        class="pa-0"
        :min-width="180"
        :rounded="rounded"
        :dense="dense"
      >
        <div
          v-for="item in group"
          :key="item.text"
        >
          <stallion-list-item
            :show-disabled="false"
            :icon-position="'right'"
            v-bind="item"
          />
        </div>

        <v-divider />
      </v-list>
    </v-menu>
    <UserFeedbackDialog v-model="show_feedback_dialog" />
    
    <v-dialog
      v-model="reportIssueDialog"
      width="400"
    >
      <v-card>
        <v-card-text style="padding:1em;text-align: center;">
          <strong>Notice:</strong>
          <p style="margin-top: 10px;text-align:left;line-height:1.1">
            The Report Issue feature is for reporting bugs or technical issues within the Stallion Express website directly to the development team.
          </p>
          <p style="margin-top: 10px;text-align:left;line-height:1.1">
            In order to ensure high customer service & satisfaction, please only use this feature to report technical bugs, and reach out to Customer Service or your account representative for a quicker further response.
          </p>
          <p style="margin-top: 10px;text-align: left;line-height:1.1">
            Using the dialog in the bottom right hand corner of your screen, please start a recording & continue using the Stallion Express website as you normally would to reproduce the bug. Once you have encountered the issue, you may submit the recording with a title and description.
          </p>
          <p style="margin-top: 10px;">
            Thank you,<br>The Stallion Team
          </p>

          <v-btn
            color="primary"
            style="margin-top: 10px;"
            @click="reportIssueDialog = false"
          >
            confirm
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StallionListItem from '../ListItems/StallionListItem.vue';
import PERMISSIONS from '@/constants/client-permissions';
export default {
  components: { StallionListItem },
  props: {},
  data() {
    return {
      reportIssueDialog: false, // Controls the visibility of the "Report Issue" dialog
      show_feedback_dialog: false,
      dense: false,
      rounded: false,
      user_menu: [
        [
          {
            icon: null,
            to: {
              name: 'dashboard'
            },
            text: 'Dashboard',
            permission: null
          },
          {
            icon: null,
            to: {
              name: 'credits'
            },
            text: 'Credits'
          },
          {
            icon: null,
            to: {
              name: 'statements'
            },
            text: 'Statements'
          },
          {
            icon: null,
            to: {
              name: 'analytics'
            },
            text: 'Analytics'
          },

          {
            icon: null,
            to: {
              name: 'referrals'
            },
            text: 'Referrals'
          },
          {
            icon: null,
            to: {
              name: 'settings'
            },
            text: 'Settings'
          },


        ],
        [
          {
            icon: 'mdi-launch',
            href: 'https://help.stallionexpress.ca/',
            text: 'Help Center',
            permission: PERMISSIONS.DEFAULT_ACCESS
          },
          {
            icon: 'mdi-launch',
            href: 'https://stallionexpress.ca/locations/',
            text: 'Locations',
            permission: PERMISSIONS.DEFAULT_ACCESS
          },
          {
            icon: null,
            text: 'Report Issue',
            permission: PERMISSIONS.DEFAULT_ACCESS,
            click: this.showReportIssueDialog
          }
        ],
        [
          {
            icon: null,
            to: {
              name: 'admin-dashboard'
            },
            text: 'Admin Panel'
          },
          {
            icon: null,
            click: this.logout,
            text: 'Logout',
            permission: PERMISSIONS.DEFAULT_ACCESS
          },

        ]
      ]
    };
  },
  methods: {
    showReportIssueDialog() {
      try {
        window.birdeatsbug.setOptions({
           user: { email: this.$auth.user.email },
        });
        
        window.birdeatsbug.trigger();
      } catch (e) {
        console.error(e);
      }

      this.reportIssueDialog = true; // Show the dialog
    },
  }
};
</script>
