<template>
  <v-main>
    <v-row
      container--fluid
      fill-height
      style="overflow-y: scroll"
    >
      <v-col
        cols="12"
        md="4"
        class="image-column"
        style="background-color: #f9ac40; height: 100vh"
      >
        <v-container>
          <v-row dense>
            <v-col
              cols="12"
              class="px-10 mb-12"
            >
              <img
                class="ml-0 img-responsive"
                :src="asset('images/StallionExpress-logo-white.png')"
                style="width: 150px"
              >
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
              style="text-align: left"
            >
              <h1
                class="font-weight-bold"
                style="color: white; font-size: 28px"
              >
                Ship Faster, Smarter, and Cheaper with Stallion!
              </h1>
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
            >
              <img
                class="img-responsive login-image"
                :src="asset('images/login-graphic.png')"
                style="width: 310px"
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        cols="12"
        md="8"
        class="pa-0"
        style="height: 100vh"
      >
        <v-card class="elevation-0 pa-5 px-12">
          <v-container class="pb-0">
            <v-row dense>
              <v-col
                cols="12"
                class="text-right"
              >
                <div>
                  <p>
                    Need Help?
                    <a
                      style="font-weight: 700; text-decoration: none"
                      target="_blank"
                      @click="handleClick"
                    >Contact Us</a>
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <div class="float-right">
                  <weglot-switcher />
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-stepper
            v-model="step"
            class="elevation-0"
          >
            <v-stepper-header
              class="elevation-0"
              style="height: 50px; max-width: 600px"
            >
              <v-stepper-step
                :complete="step > 1"
                step="1"
                :editable="true"
              >
                Business Info
              </v-stepper-step>
        
              <v-stepper-step
                :complete="step > 2"
                step="2"
                :editable="true"
              >
                Your Products
              </v-stepper-step>
              <v-stepper-step
                :complete="step > 3"
                step="3"
                :editable="editable"
              >
                Branch Selection
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                :step="1"
                class="py-2 stepper-container"
              >
                <v-form data-vv-scope="step1">
                  <v-container>
                    <v-row dense>
                      <v-col cols="12">
                        <h4 class="font-weight-bold">
                          Tell us about your business!
                        </h4>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="user.first_name"
                          v-validate="'required'"
                          label="First Name"
                          data-vv-name="first_name"
                          :error-messages="errs.collect('step1.first_name')"
                          type="text"
                          name="first_name"
                          dusk="first_name"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="user.last_name"
                          v-validate="'required'"
                          label="Last Name"
                          data-vv-name="last_name"
                          :error-messages="errs.collect('step1.last_name')"
                          type="text"
                          name="last_name"
                          dusk="last_name"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="user.business_name"
                          v-validate="'required'"
                          label="Company Name"
                          data-vv-name="company_name"
                          :error-messages="errs.collect('step1.company_name')"
                          type="text"
                          name="company_name"
                          dusk="business_name"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          id="address1"
                          ref="autocomplete"
                          v-model="user.address1"
                          v-validate="'required'"
                          label="Address Line 1"
                          data-vv-name="address1"
                          :error-messages="errs.collect('step1.address1')"
                          type="text"
                          name="address1"
                          dusk="address1"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="user.address2"
                          label="Address Line 2"
                          data-vv-name="address2"
                          :error-messages="errs.collect('step1.address2')"
                          type="text"
                          name="address2"
                          dusk="address2"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="user.city"
                          v-validate="'required'"
                          label="City"
                          data-vv-name="city"
                          :error-messages="errs.collect('step1.city')"
                          type="text"
                          name="city"
                          dusk="city"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="user.postal_code"
                          v-mask="'A#A #A#'"
                          v-validate="{
                            required: true,
                            regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                          }"
                          label="Postal Code"
                          :error-messages="errs.collect('step1.postal_code')"
                          data-vv-name="postal_code"
                          dusk="postal_code"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="user.province_code"
                          v-validate="'required'"
                          :error-messages="errs.collect('step1.province_code')"
                          data-vv-name="province_code"
                          :items="provinces"
                          label="Province"
                          item-text="name"
                          item-value="code"
                          :search-input.sync="searchInput2"
                          dense
                          dusk="province_code"
                          outlined
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="user.country_code"
                          v-validate="'required'"
                          :error-messages="errs.collect('step1.country_code')"
                          data-vv-name="country_code"
                          :items="countries"
                          label="Country"
                          item-text="name"
                          item-value="code"
                          :search-input.sync="searchInput"
                          dense
                          dusk="country_code"
                          outlined
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="user.phone"
                          v-mask="'###########'"
                          v-validate="'required'"
                          :error-messages="errs.collect('step1.phone')"
                          label="Phone"
                          data-vv-name="phone"
                          dusk="phone"
                          outlined
                          dense
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="user.contact_email"
                          v-validate="'email'"
                          :error-messages="errs.collect('step1.contact_email')"
                          label="Contact Email"
                          data-vv-name="contact_email"
                          dusk="contact_email"
                          outlined
                          dense
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-model="user.weekly_package_count"
                          v-validate="'required'"
                          :error-messages="
                            errs.collect('step1.weekly_package_count')
                          "
                          data-vv-name="weekly_package_count"
                          :items="package_counts"
                          label="Weekly Package Count"
                          :search-input.sync="searchInput3"
                          dense
                          dusk="weekly_package_count"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  
                    <v-row dense>
                      <v-col
                        cols="12"
                        class="py-0 d-flex flex-row"
                      >
                        <v-btn
                          color="primary"
                          name="submit1"
                          @click="step1"
                        >
                          Continue
                        </v-btn>
                        <div class="py-2 px-6">
                          <p>
                            Or
                            <a
                              style="font-weight: 700; text-decoration: none"
                              @click.prevent="logout()"
                            >Log me out</a>
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-stepper-content>

              <v-stepper-content
                :step="2"
                class="py-0 stepper-container"
              >
                <v-form data-vv-scope="step2">
                  <v-container>
                    <v-row dense>
                      <v-col
                        cols="12"
                        class="pt-0"
                      >
                        <h4>What type of products do you ship?</h4>
                      </v-col>
                      <v-col
                        id="product_categories1"
                        cols="12"
                        md="6"
                      >
                        <v-checkbox
                          v-for="item in categories1"
                          :key="item"
                          v-model="user.product_categories"
                          v-validate="'required'"
                          :value="item"
                          data-vv-name="product_categories"
                          :name="`product_categories[${item}]`"
                          :error-messages="
                            errs.collect('step2.product_categories')
                          "
                          :label="item"
                        />
                      </v-col>
                      <v-col
                        id="product_categories2"
                        cols="12"
                        md="6"
                      >
                        <v-checkbox
                          v-for="item in categories2"
                          :key="item"
                          v-model="user.product_categories"
                          v-validate="'required'"
                          :value="item"
                          data-vv-name="product_categories"
                          :name="`product_categories[${item}]`"
                          :error-messages="
                            errs.collect('step2.product_categories')
                          "
                          :label="item"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-if="!$auth.user?.auto_referral"
                          v-model="user.promotional_code"
                          label="Promotional Code"
                          data-vv-name="name"
                          :error-messages="
                            errs.collect('step2.promotional_code')
                          "
                          type="text"
                          name="promotional_code"
                          dusk="promotional_code"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-autocomplete
                          v-if="!$auth.user?.auto_referral"
                          v-model="user.referral"
                          v-validate="'required'"
                          :error-messages="errs.collect('step2.referral')"
                          data-vv-name="referral"
                          :items="referral_list"
                          label="How did you hear about us?"
                          :search-input.sync="searchInput4"
                          outlined
                          dense
                          dusk="referral"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        class="py-0 d-flex flex-row"
                      >
                        <v-btn
                          color="primary"
                          class="px-6"
                          name="submit2"
                          @click="step2"
                        >
                          Submit
                        </v-btn>
                        <div class="py-2 px-6">
                          <p>
                            Or
                            <a
                              style="font-weight: 700; text-decoration: none"
                              @click.prevent="logout()"
                            >Log me out</a>
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-stepper-content>

              <v-stepper-content
                :step="3"
                class="py- stepper-container"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12">
                      <h2 class="font-weight-bold">
                        Just one more step to go!
                      </h2>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <LocationSelector
                        ref="locationSelector"
                        :postal-code="user.postal_code"
                        :dropoff-location-id.sync="user.dropoff_location_id"
                        :region-id.sync="user.region_id"
                        :pickup-location-id.sync="user.branch_location_id"
                   
                        @validationResult="handleValidationResult"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      class="py-5 d-flex flex-row"
                    >
                      <v-btn
                        color="primary"
                        class="px-6"
                        name="submit2"
                        @click="$refs.locationSelector.validate()"
                      >
                        Submit
                      </v-btn>
                      <div class="py-2 px-6">
                        <p>
                          Or
                          <a
                            style="font-weight: 700; text-decoration: none"
                            @click.prevent="logout()"
                          >Log me out</a>
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <v-flex xs12>
            <v-alert
              v-for="(error, key1) in errors"
              :key="key1"
              color="error"
              :value="error != ''"
              icon="warning"
            >
              <div
                v-for="(description, key2) in error"
                :key="key2"
              >
                {{ description }}
              </div>
            </v-alert>
          </v-flex>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import constants from '../../constants/index';
export default {
  data() {
    return {
      regions: [],
      editable: false,
      loading: false,
      searchInput2: '',
      searchInput4: '',
      searchInput3: '',
      searchInput: '',
      branch: '',
      errors: [],
      selected_categories: [],
      step: 1,
      countries: [
        {
          name: 'Canada',
          code: 'CA'
        }
      ],
      categories1: constants.PRODUCT_CATEGORIES_1 || [],
      categories2: constants.PRODUCT_CATEGORIES_2 || [],
      referral_list: constants.REFERRAL_LIST || [],
      default_location_list: constants.DEFAULT_PICKUP_LOCATIONS || [],
      package_counts: constants.PACKAGE_COUNTS || [],
      user: {
        product_categories: [],
        address1: '',
        address2: '',
        city: '',
        postal_code: '',
        province_code: '',
        country_code: '',
        phone: '',
        contact_email: '',
        weekly_package_count: '',
        promotional_code: '',
        referral: '',
        branch_location_id: '',
        region_id: '',
        dropoff_location_id: ''
      },
      provinces: constants.PROVINCES || [],
      showPasswordFields: false,
      newlyRegistered: true,

      processing: false,
      addressNotFound: false,
      validationResult: false

    };
  },
  mounted() {
    this.init();

    var returnData = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: ''
    };
    var element = document.getElementById('address1');
    var autocomplete = new google.maps.places.Autocomplete(element, {
      types: ['geocode']
    });
    element.placeholder = '';
    element.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
    element.addEventListener('blur', (e) => {
      returnData.route = e.target.value;
      returnData.street_number = '';
      //this.$emit('placechanged', returnData);
    });
    autocomplete.addListener('place_changed', () => {
      returnData = {
        street_number: '',
        route: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: ''
      };

      let place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }
      let addressComponents = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'short_name',
        postal_code: 'short_name'
      };
      if (place.address_components !== undefined) {
        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];
          if (addressComponents[addressType]) {
            let val =
              place.address_components[i][addressComponents[addressType]];
            returnData[addressType] = val;
          }
        }
        returnData['latitude'] = place.geometry.location.lat();
        returnData['longitude'] = place.geometry.location.lng();
      }
      this.updateAddress(returnData, place);
      //this.$emit('placechanged', returnData, place);
    });
  },
  methods: {
    init() {
      // set product_categories to an empty array
      let tempUser = Object.assign({}, this.$auth.user);
      if (!tempUser.product_categories) {
        tempUser.product_categories = [];
      }
      this.user = Object.assign({}, tempUser);

      this.$validator.reset();
    },
    step1() {
      this.$validator.validateAll('step1').then((result) => {
        if (result) {
          this.step = 2;
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    },
    step2() {
      this.$validator.validateAll('step2').then((result) => {
        if (result) {
          this.step = 3;
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    },
    handleValidationResult(result) {
        if(result) {
          this.loading = true;
          this.processing = true;

          this.$http
            .post('/auth/account-setup', this.user)
            .then((res) => {
              if (res.data.success) {
                this.getUser().then(() => {
                  this.$router.push('/');
                });
              } else {
                swal('Error!', res.data.message);
              }

              this.processing = false;
            })
            .catch((err) => {
              if (err.response.status == 422) {
                // this.errors = err.response.data.errors;

                this.errorMessage(err.response.data.errors);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
    
    },
    geolocate() {
      if (this.enableGeolocation) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy
            });
          });
        }
      }
    },
    updateAddress(value) {
      // console.log(value);
      this.user.address1 = value.street_number + ' ' + value.route;
      this.user.city = value.locality;
      this.user.postal_code = value.postal_code;

      if (value.country == 'CA') {
        this.user.country_code = value.country;
      }

      if (value.administrative_area_level_1 == 'ON') {
        this.user.province_code = value.administrative_area_level_1;
      }
    },
    handleClick() {
      window.open(this.$contactUs, '_blank')
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px !important;
}

.v-stepper__step {
  padding-top: 0px;
  padding-bottom: 0px;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}

@media only screen and (min-width: 1440px) {
  .login-image {
    width: 400px !important;
  }

  .stepper-style {
    margin-bottom: 10px !important;
    width: 40% !important;
  }

  .stallion-title {
    margin-bottom: 50px !important;
  }

  .stepper-container {
    width: 70% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .login-image {
    width: 260px !important;
  }
}

@media only screen and (max-width: 1550px) and (min-width: 1080px) {
  .stepper-style {
    width: 60% !important;
  }
}

@media only screen and (max-width: 1080px) and (min-width: 960px) {
  .stepper-style {
    width: 90% !important;
  }
}

@media only screen and (max-width: 960px) {
  .login-image {
    display: none !important;
  }

  .image-column {
    height: 40vh !important;
  }

  .v-stepper__header {
    display: none !important;
  }
}
</style>
