// mixins/printerMixin.js
import {ParseData} from "../helpers/scales";
import Vue from "vue"; // Ensure Vue is imported for notifications
import axios from "axios";
import {console} from "vuedraggable/src/util/helper";
import PRINTER from "@/constants/printer";

export default {
  data() {
    return {
      printers: [],
      scales: [],
      selectedPrinter: '',
      selectedScale: {},
      streamIntervalTime: 100,
      stableWeightTime: 400, //cannot be less than 5
      autoReconnectIds: [],
    };
  },
  methods:
  {
    // Check if a printer is initialized by verifying local storage
    isPrinterInitialized() {
      console.log("Checking if printer is initialized...");
      const printer = localStorage.getItem('printer');
      const printerFormat = localStorage.getItem('printerFormat');
      this.$store.dispatch('qz/updateCurrentPrinter', { printer, printerFormat });
      console.log(`Printer initialized: [${printer}, ${printerFormat}]`);
      return Boolean(printer);
    },

    // Check if QZ Tray is initialized and update its status
    isQzInitialized() {
      console.log("Checking if QZ Tray is initialized...");
      const isActive = this.$qz.websocket.isActive();

      // console.log(`QZ Tray isActive: ${isActive}`);
      this.$store.dispatch(isActive ? 'qz/activateQz' : 'qz/deactivateQz');
      // console.log(`QZ Tray initialized: ${isActive}`);
      return isActive;
    },

    // Sign in to QZ Tray with a certificate and set security configurations
    async signInToQz() {
      // console.log("Signing in to QZ Tray...");
      const certificate = await this.fetchQzCertificate();
      await this.configureQzSecurity(certificate);
      this.$qz.websocket.setErrorCallbacks(console.log);
      // console.log("Signed in to QZ Tray successfully.");
    },

    // Fetch QZ security certificate
    async fetchQzCertificate() {
      // console.log("Fetching QZ security certificate...");
      if (this.$store.state.qz.certificate) {
        // console.log("Using existing certificate.");
        return this.$store.state.qz.certificate;
      }

      const { data: certificate } = await this.$http.get('/qz/cert');
      this.$store.dispatch('qz/updateCertificate', certificate);
      // console.log("Fetched new certificate.");
      return certificate;
    },

    // Configure QZ security with a certificate and signature algorithm
    async configureQzSecurity(certificate) {
      // console.log("Configuring QZ security...");
      await this.$qz.security.setCertificatePromise((resolve) => resolve(certificate));
      this.$qz.security.setSignatureAlgorithm("SHA512");
      this.$qz.security.setSignaturePromise(this.createSignatureResolver());
      // console.log("QZ security configured.");
    },

    // Create a resolver function for generating signatures
    createSignatureResolver() {
      // console.log("Creating signature resolver...");
      return (toSign) => async (resolve, reject) => {
        try {
          const signedKeys = await this.fetchSignedKeys(toSign);
          resolve(signedKeys);
        } catch (error) {
          console.error("Error generating signature:", error);
          reject(error);
        }
      };
    },

    // Fetch signed keys for QZ Tray
    async fetchSignedKeys(data) {
      // console.log("Fetching signed keys...", data);
      const { data: signedKeys } = await this.$http.post('/qz/cert', { data });
      // console.log("Signed keys fetched.");
      return signedKeys;
    },

    // Initialize QZ Tray and handle connection
    async initializeQz() {
      // console.log("Initializing QZ Tray...");
      if (this.isQzInitialized()) {
        // console.log("QZ Tray already initialized.");
        return;
      }

      await this.signInToQz();
      try {
        await this.$qz.websocket.connect();
        // console.log('QZ Tray connected. Version:', await this.$qz.api.getVersion());
        this.$store.dispatch('qz/activateQz');
        await this.initializeDevices();
        // console.log('QZ Tray fully initialized.');
        this.$store.dispatch('qz/updateNotified', false);
      } catch (error) {
        console.error("Error initializing QZ Tray:", error);
        await this.handleQzConnectionError(error);
      }

      this.setupQzDisconnectionHandler();
    },

    // Initialize connected devices (printers and scales)
    async initializeDevices() {
      // console.log("Initializing connected devices...");
      if (this.isScaleInitialized()) await this.fetchConnectedScales();
      if (this.isPrinterInitialized()) await this.fetchConnectedPrinters();
      // console.log("Connected devices initialized.");
    },

    // Handle QZ Tray connection errors
    async handleQzConnectionError(error) {
      // console.error("Handling QZ Tray connection error:", error);
      if (error.message === 'Unable to establish connection with QZ' && !this.$store.state.qz.notified) {
        this.$store.dispatch('qz/updateNotified', true);
        window.location.assign("qz:launch");
        setTimeout(this.initializeQz, 5000);
      }
    },

    // Setup handler for QZ Tray disconnection
    setupQzDisconnectionHandler() {
      // console.log("Setting up QZ Tray disconnection handler...");
      this.$qz.websocket.setClosedCallbacks(() => {
        this.$store.dispatch('qz/deactivateQz');
        console.log('Disconnected from QZ Tray');
        this.retryQzConnection();
      });
    },

    retryQzConnection() {
      const intervalId = setInterval(async () => {
        if (this.$qz.websocket.isActive()) {
          clearInterval(intervalId);
        } else {
          console.log('Reconnecting to QZ Tray...');
          await this.$qz.websocket.connect();
        }
      }, 4000);
    },

    // Check if a printer is physically connected
    async isPrinterConnected() {
      // console.log("Checking if a printer is physically connected...");
      if (!this.isPrinterInitialized()) {
        console.warn("Printer not initialized. Set printer in settings first.");
        return false;
      }

      if (!this.isQzInitialized()) {
        await this.initializeQz();
        if (!this.isQzInitialized()) {
          console.warn("QZ Tray not initialized after attempt.");
          return false;
        }
      }

      const printers = await this.fetchConnectedPrinters();
      console.log(`Printers connected: ${printers}`);

      console.log(`Current Printer: ${this.$store.state.qz.currentPrinter}`);
      return printers.includes(this.$store.state.qz.currentPrinter);
    },

    // Fetch connected printers
    async fetchConnectedPrinters() {
      // console.log("Fetching connected printers...");
      if (!this.isQzInitialized()) await this.initializeQz();

      try {
        this.printers = await this.$qz.printers.find();
        // console.log("Connected printers fetched:", this.printers);
        return this.printers;
      } catch (error) {
        this.logQzError(error, "Error finding printers");
        return [];
      }
    },

    // Log QZ errors with an optional custom message
    logQzError(error, message = "") {
      console.error(message, error.message || error);
    },

    // Print a URL using QZ Tray
    async printUrl(url, isLabel = false) {
      console.log(`Printing URL: ${url}`);

      let printer = localStorage.printer;
      if(printer !== null && printer !== undefined && this.$store.state.qz.qzStatus && isLabel) {
        try {
          const { data: buffer } = await axios.get(url, { responseType: 'arraybuffer' });
          const printData = this.convertArrayBufferToBase64(buffer);
          await this.printData(printData, url);
          // console.log("Printed URL successfully.");
        } catch (error) {
          console.error("Error printing URL:", error);
        }
      } else {
        await this.printFileUrl(url);
      }
    },
    async printFileUrl(url, type = 'application/pdf'){
      try {
          var instance = axios.create();
          const response = await instance.get(url, {
              responseType: 'arraybuffer',
          });
                  const blob = new Blob([response.data], {
                      type: type
                  });
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);

                  printJS(link.href);
      } catch (error) {
          console.error('Print failed:', error);
      }
  },

    // Print data using QZ Tray
    async printData(printData, url = '') {
      console.log("Printing data using QZ Tray...");
      if (!await this.isPrinterConnected()) {
        console.warn("Cannot print. Printer is not connected.");
        return;
      }

      try {
        const config = await this.getConfig();
        const data = this.preparePrintData(printData, url);
        console.log("Printing data...", config);
        await this.$qz.print(config, data);
        console.log("Data printed successfully.");
      } catch (error) {
        console.error("Error printing data:", error);
      }
    },

    // Prepare print data based on the printer format
    preparePrintData(printData, url) {
      console.log("Preparing print data...");
      const format = this.$store.state.qz.printerFormat;
    
      if (format === PRINTER.PRINT_FORMATS.PDF) {
        return [{
          type: 'pixel',
          format: 'pdf',
          flavor: 'base64',
          data: printData,
          options: { ignoreTransparency: true, altFontRendering: false }
        }];
      }
    
      if (format === PRINTER.PRINT_FORMATS.ZPL) {
        console.warn("ZPL format not supported yet", this.convertArrayBufferToStream(printData));
        return [{ type: 'raw', format: 'file', data: url }];
      }
    
      console.warn("Unknown printer format:", format);
      return [];
    },    

    async getConfig() {
      let config = { 
        rasterize: true, 
        colorType: "blackwhite",
        scaleContent: "true", 
        size: { width: 4, height: 6 }, 
        units: "in",
        margins: { top: 0.0, left: 0.0, right: 0.0, bottom: 0.0 },
        interpolation: "nearest-neighbor",
        duplex: "one-sided",
      };
    
      let docType = this.$store.state.qz.currentDocType;
      let selectedPrinter = this.isPrinterSet(docType) ? this.getPrinterSettingData(false, docType).printer : this.$store.state.qz.currentPrinter;
      if (selectedPrinter === 'Default') selectedPrinter = this.$store.state.qz.currentPrinter;
    
      let printer = await this.$qz.printers.find(selectedPrinter);
      if (this.isPrinterSet(docType)) {
        let printerData = this.getPrinterSettingData(false, docType);
        let dims = this.getPrintableDimensions(printerData);
        config.size = { width: dims.width, height: dims.height };
        if(printerData.docFormat === PRINTER.DIMENSIONS.LETTER) {
            config.margins = 0.25;
        }
        if (printerData.density === PRINTER.DENSITIES.DEFAULT){
          config.density = '0';
        } else  if(printerData.density === PRINTER.DENSITIES.DPI_203) {
          config.density = '203';
        } else if(printerData.density === PRINTER.DENSITIES.DPI_300) {
          config.density = '300';
        }
      }

      console.log("Printing config:", config);
    
      return this.$qz.configs.create(printer, config);
    },    

    // get the printable dimensions based on the document format
    getPrintableDimensions(printerData) {
      if (!printerData) return { width: 4, height: 6 };
    
      switch (printerData.docFormat) {
        case PRINTER.DIMENSIONS.LETTER:
          return { width: 8.5, height: 11 };
        default:
          return { width: 4, height: 6 };
      }
    },    

    // check if printer is set in the settings
    isPrinterSet(docType) {
      let printerData = this.getPrinterSettingData(false,docType);
      if (printerData?.printer === "No Printer Selected") {
        return false;
      }
      return !!printerData?.printer;
    },

    //get the print to value based on the document type
    getPrintTo(docType) {
      let printerData = this.getPrinterSettingData(false, docType);
      return printerData?.printAction || PRINTER.PRINT_ACTION.PROMPT; // Default to prompt
    },

    //get the printer name and setting from the local storage
    getPrinterSettingData(bulk = false, docType = null) {
      let setting = localStorage.getItem('stallion_printer_settings');
      if (setting) {
        let parsedSettings = JSON.parse(setting);
        if (bulk) {
          return parsedSettings;
        }
        if(docType) {
        return parsedSettings.find(doc => doc.docName.toLowerCase().includes(docType.toLowerCase()))
        }
        return null;
      } else {
        return null;
      }
    },

    //save the printer settings to the local storage
    savePrintingSettings(settings) {
      localStorage.setItem('stallion_printer_settings', JSON.stringify(settings));
    },

    convertArrayBufferToBase64(buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    // Convert array buffer to stream (string)
    convertArrayBufferToStream(buffer) {
      console.log("Converting array buffer to stream...");
      return String.fromCharCode(...new Uint8Array(buffer));
    },

    // Check if scale is initialized by verifying local storage
    isScaleInitialized() {
      // console.log("Checking if scale is initialized...");
      let scale = localStorage.getItem('scale');
      if (!scale) {
        // console.log("Scale not initialized.");
        return false;
      }

      if (typeof scale === 'string') scale = JSON.parse(scale);
      scale.responseSize = 8;
      this.$store.dispatch('qz/updateCurrentScale', scale);
      this.$store.dispatch('qz/updateEnabledAutoWeightFetch', scale.enabledAutoWeightFetch);
      // console.log("Scale initialized.");
      return true;
    },

    // Fetch connected scales
    async fetchConnectedScales() {
      // console.log("Fetching connected scales...");
      if (!this.isQzInitialized()) await this.initializeQz();

      try {
        const devices = await this.$qz.hid.listDevices();
        // console.log("All connected devices fetched:", devices);
        this.scales = this.filterSupportedScales(devices);
        // console.log("Connected scales fetched:", this.scales);
        return this.scales;
      } catch (error) {
        this.logQzError(error, "Error connecting to QZ Tray for scales");
        return [];
      }
    },

    // Filter supported scales from device list
    filterSupportedScales(devices) {
      // console.log("Filtering supported scales...");
      const supportedVendors = ['6096', '0922', '1446', '0eb8', '2474', '0b67'];
      const filteredScales = devices.filter(device => supportedVendors.includes(device.vendorId.toString().slice(-4)))
        .map((device, index) => ({
          ...device,
          product: device.product || `Unknown Scale #${index} (vendorId:${device.vendorId} productId:${device.productId})`
        }));
      // console.log("Filtered scales:", filteredScales);
      return filteredScales;
    },

    // Get weight from the connected scale
    async getScaleData() {
      console.log("Getting weight from scale... ====================");
      if (this.$store.state.qz.currentScaleStream) {
        console.log("Stopping current scale stream...");
        await this.stopScaleStream();
      }

      if (!await this.isScaleConnected()) {
        console.warn("Scale not connected. Please connect USB Scale first.");
        Vue.notify({
          group: 'main',
          title: 'Error getting scale weight',
          text: 'Please connect USB Scale first!',
          duration: 3000,
          type: 'error'
        });
        return { status: false, data: "" };
      }

      try {
        await this.claimScaleDevice();
        const scaleData = await this.readScaleData();
        console.log("Scale data read successfully:", scaleData);
        return scaleData;
      } catch (error) {
        console.error("Error reading scale data:", error);
        return { status: false, data: "" };
      } finally {
        await this.releaseScaleDevice();
      }
    },

    // Claim the connected scale device for reading
    async claimScaleDevice() {
      console.log("Claiming scale device...");
      const isClaimed = await this.$qz.hid.isClaimed(this.$store.state.qz.currentScale);
      console.log("Claiming scale device status",isClaimed);
      if (!isClaimed) {
        await this.$qz.hid.claimDevice(this.$store.state.qz.currentScale);
        console.log("Scale device claimed.");
      } else {
        console.log("Scale device already claimed.");
        await this.releaseScaleDevice();
        await this.claimScaleDevice();
      }
    },

    // Read data from the connected scale and parse it
    async readScaleData() {
      console.log("Reading data from scale...");
      let parsedData = { status: "UNSTABLE" };
      let attempts = 0;

      do {
        attempts++;
        const data = await this.$qz.hid.readData(this.$store.state.qz.currentScale);
        parsedData = ParseData(data);
        console.log(`Attempt ${attempts}: Scale data parsed:`, parsedData);

        if (parsedData.status === "ZERO") {
          Vue.notify({
            group: 'main',
            title: 'Error getting scale weight',
            text: 'Please put weight on Scale',
            duration: 3000,
            type: 'error'
          });
          break;
        }

        if (parsedData.status === "UNDER_ZERO") {
          Vue.notify({
            group: 'main',
            title: 'Invalid Scale Weight',
            text: 'Please reset Scale',
            duration: 3000,
            type: 'error'
          });
          break;
        }

        if (attempts === 20) break;
      } while (parsedData.status !== "STABLE");

      return parsedData.status === "STABLE"
        ? { status: true, data: { ...parsedData, weight: Number(Math.round(parsedData.weight + 'e' + 2) + 'e-' + 2) } }
        : { status: false, data: "" };
    },

    // Release the scale device after reading data
    async releaseScaleDevice() {
      console.log("Releasing scale device...", this.$store.state.qz.currentScale);
      await this.$qz.hid.releaseDevice(this.$store.state.qz.currentScale.vendorId, this.$store.state.qz.currentScale.productId);
      console.log("Scale device released.");
    },

    // Start streaming data from the scale
    async startScaleStream() {
      console.log("Starting scale stream...");
      if (!await this.isScaleConnected()) {
        console.warn("Scale not connected. Unable to start stream.");
        Vue.notify({
          group: 'main',
          title: 'Error getting scale weight',
          text: 'Selected scale not available. Please connect USB Scale first!',
          duration: 3000,
          type: 'error'
        });
        return false;
      }

      try {
        if (this.$store.state.qz.isScaleLocked) {
          console.log("Scale device is already in use by another instance.");
          return false;
        }

        // Set the lock in the store
        await this.$store.dispatch('qz/setScaleLock', true);

        if(!this.$store.state.qz.currentScaleStream) {
          await this.claimScaleDevice();
        }
        await this.$store.dispatch('qz/updateCurrentScaleStream', true);
        const currentScale = { ...this.$store.state.qz.currentScale, interval: this.streamIntervalTime };
        console.log(`Streaming started with interval of ${this.streamIntervalTime} ms`);
        await this.$qz.hid.openStream(currentScale);
        await this.$store.dispatch('qz/updateAutoReconnect', true);
        return true;
      } catch (error) {
        console.error("Error starting scale stream:", error);
        Vue.notify({
          group: 'main',
          title: 'Device Error',
          text: 'Cannot claim Scale',
          duration: 3000,
          type: 'error'
        });
      }  finally {
        // Release the lock regardless of success or failure
        await this.$store.dispatch('qz/setScaleLock', false);
      }
    },

    // Stop the scale stream
    async stopScaleStream() {
      console.log("Stopping scale stream...");
      await this.$store.dispatch('qz/updateAutoReconnect', false);
      this.stopAutoReconnect();
      if(this.$store.state.qz.currentScaleStream) {
        await this.$store.dispatch('qz/updateCurrentScaleStream', false);
        await this.$qz.hid.closeStream(this.$store.state.qz.currentScale);
        await this.$qz.hid.releaseDevice(this.$store.state.qz.currentScale);
      }
      console.log("Scale stream stopped.");
    },

    // Parse data from the scale stream
    async parseScaleStreamData(streamData) {
      console.log("Parsing scale stream data...");
      const parsedData = ParseData(streamData);

      if (parsedData.status === "ZERO") {
        console.log("Scale status: ZERO. Please put weight on Scale.");
        return { status: true, data: parsedData };
      }

      if (parsedData.status === "UNDER_ZERO") {
        console.log("Scale status: UNDER_ZERO. Please reset Scale.");
        return { status: false, data: {} };
      }

      if (parsedData.status === "STABLE" && parsedData.weight !== 0) {
        parsedData.weight = Number(Math.round(parsedData.weight + 'e' + 2) + 'e-' + 2);
        console.log("Scale status: STABLE. Parsed data:", parsedData);
        return { status: true, data: parsedData };
      }

      console.log("Scale data unstable or weight is zero.");
      return { status: false, data: {} };
    },

    // Compare connected scales with the selected scale
    async compareScales() {
      // console.log("Comparing connected scales with the selected scale...");
      const connectedScales = await this.fetchConnectedScales();
      if (!connectedScales) {
        console.warn("No connected scales found.");
        return false;
      }

      const scaleConnected = connectedScales.some(device => {
        const deviceVendorId = device.vendorId.toString().slice(-4);
        const selectedVendorId = this.$store.state.qz.currentScale.vendorId.toString().slice(-4);
        return deviceVendorId === selectedVendorId;
      });

      console.log(`Scale connected: ${scaleConnected}`);
      return scaleConnected;
    },

    // Check if a scale is physically connected
    async isScaleConnected() {
      // console.log("Checking if scale is physically connected...");
      if (!this.isScaleInitialized()) {
        console.warn("Scale not initialized in settings.");
        return false;
      }

      if (!this.isQzInitialized()) {
        await this.initializeQz();
        if (!this.isQzInitialized()) {
          console.warn("QZ Tray not initialized after attempt.");
          return false;
        }
      }

      // console.log(`Scale physically connected: ${connected}`);
      return await this.compareScales();
    },

    // Set the selected scale in local storage and in autoscalebutton.vue
    setAutoWeight(weight) {
      if (!weight || !weight.status) return;

      // Ensure weightStorage is initialized
      if (!this.weightStorage) this.weightStorage = [];

      this.weightStorage.push(weight.data.weight);

      const minSampleCount = Math.ceil(this.stableWeightTime / this.streamIntervalTime);
      console.log("Weight Sample Data: ", minSampleCount);

      if (this.weightStorage.length === minSampleCount) {
        if (weight.data.weight < 0.1) {
          this.$store.dispatch('qz/updateCurrentScaleWeight', {
            status: true,
            data: {
              weight: 0.0,
              weight_unit: weight.data.weight_unit
            }
          });
          weight.data.weight = 0.0;
          Event.fire('set-auto-weights', weight);
        } else {
          // Dynamically check if the last `minSampleCount` weights are the same
          let allWeightsAreEqual = true;
          for (let i = 1; i < minSampleCount; i++) {
            if (this.weightStorage[minSampleCount - 1] !== this.weightStorage[minSampleCount - 1 - i]) {
              allWeightsAreEqual = false;
              break;
            }
          }

          if (allWeightsAreEqual) {
            this.$store.dispatch('qz/updateCurrentScaleWeight', weight);
            Event.fire('set-auto-weights', weight);
            // this.stopScaleStream(); // Uncomment if stopping is required
          }
        }
        // Reset weightStorage after processing
        this.weightStorage = [];
      }
    },

    autoReconnectScale() {
      if (this.$store.state.qz.autoReconnect) {
        const intervalId = setInterval(async () => {
          if (this.$store.state.qz.currentScaleStream) {
            clearInterval(intervalId);
          } else {
            await this.startScaleStream();
          }
        }, 4000);
        this.autoReconnectIds.push(intervalId);
      }
    },

    stopAutoReconnect() {
      if (this.autoReconnectIds.length !== 0) {
        this.autoReconnectIds.forEach(id => clearInterval(id));
        this.autoReconnectIds = [];
        console.log('Auto reconnect cleared');
      }
    },


//     async printUrlFromQz(url) {
//       var instance = axios.create();
//       const response = await instance.get(url, {
//           responseType: 'arraybuffer',
//       });
//       let unitArrayData = new Uint8Array(response.data);
//       let printData = this.arrayBufferToBase64(unitArrayData);
//       let data = [];
//       if (await this.isPrinterPhysicallyConnected()) {
//           let foundPrinter = await this.$qz.printers.find(this.$store.state.qz.currentPrinter);
//           let config = this.$qz.configs.create(foundPrinter);
//           if(this.$store.state.qz.printerFormat === 'PDF') {
//               data = [{
//                   type: 'pixel',
//                   format: 'pdf',
//                   flavor: 'base64',
//                   data: printData
//               }];
//           } else if (this.$store.state.qz.printerFormat === 'ZPL') {
//               printData = this.arrayBufferToStream(unitArrayData);
//               console.log("ZPL format not supported yet", printData)
//               data = [{
//                   type: 'raw',
//                   format: 'file',
//                   data: url,
//               }];
//           }
//           try {
//               await this.$qz.print(config, data);
//           } catch (e) {
//               console.log(e);
//           }
//       } else {
//           console.log("Please connect printer first");
//       }
//   },

//   async printFromQz(printData) {
//       if (await this.isPrinterPhysicallyConnected()) {
//           let foundPrinter = await this.$qz.printers.find(this.$store.state.qz.currentPrinter);
//           let config = this.$qz.configs.create(foundPrinter);
//           let data = [{
//               type: 'pixel',
//               format: 'pdf',
//               flavor: 'base64',
//               data: printData
//           }];
//           try {
//               await this.$qz.print(config, data);
//           } catch (e) {
//               console.log(e);
//           }
//       } else {
//           console.log("Please connect printer first");
//       }
//   },

//   arrayBufferToBase64(buffer) {
//       var binary = '';
//       var bytes = new Uint8Array(buffer);
//       var len = bytes.byteLength;
//       for (var i = 0; i < len; i++) {
//           binary += String.fromCharCode(bytes[i]);
//       }
//       return window.btoa(binary);
//   },

//   arrayBufferToStream(buffer) {
//       var binary = '';
//       var bytes = new Uint8Array(buffer);
//       var len = bytes.byteLength;
//       for (var i = 0; i < len; i++) {
//           binary += String.fromCharCode(bytes[i]);
//       }
//       return binary;
//   },

//   async isPrinterPhysicallyConnected() {
//     if (this.isPrinterInitialized()) {
//         if (this.isQzInitialized()) {
//             let printers = await this.getConnectedPrinters()
//             return printers.includes(this.$store.state.qz.currentPrinter);
//         } else {
//             this.initializeQz();
//             if (this.isQzInitialized()) {
//                 let printers = await this.getConnectedPrinters()
//                 return printers.includes(this.$store.state.qz.currentPrinter);
//             } else {
//                 return false;
//             }
//         }
//     } else {
//         console.log("Set printer in settings first")
//         return false;
//     }
// },


  },
};
