export default {
  SHIPMENT_ITEM:{
    description: "",
    quantity: 1,
    value: "",
    currency: "USD",
    hs_code: "",
    country_of_origin: "",
    customs_description: "",
    warehouse_location: "",
    img_url: [],
  },
  COUNTRIES: [
    {
      name: 'United States(US)',
      code: 'US'
    },
    {
      name: 'Canada(CA)',
      code: 'CA'
    },
    {
      name: 'Afghanistan(AF)',
      code: 'AF'
    },
    {
      name: 'land Islands(AX)',
      code: 'AX'
    },
    {
      name: 'Albania(AL)',
      code: 'AL'
    },
    {
      name: 'Algeria(DZ)',
      code: 'DZ'
    },
    // {
    //     "name": "American Samoa(AS)",
    //     "code": "AS"
    // },
    {
      name: 'AndorrA(AD)',
      code: 'AD'
    },
    {
      name: 'Angola(AO)',
      code: 'AO'
    },
    {
      name: 'Anguilla(AI)',
      code: 'AI'
    },
    {
      name: 'Antarctica(AQ)',
      code: 'AQ'
    },
    {
      name: 'Antigua and Barbuda(AG)',
      code: 'AG'
    },
    {
      name: 'Argentina(AR)',
      code: 'AR'
    },
    {
      name: 'Armenia(AM)',
      code: 'AM'
    },
    {
      name: 'Aruba(AW)',
      code: 'AW'
    },
    {
      name: 'Australia(AU)',
      code: 'AU'
    },
    {
      name: 'Austria(AT)',
      code: 'AT'
    },
    {
      name: 'Azerbaijan(AZ)',
      code: 'AZ'
    },
    {
      name: 'Bahamas(BS)',
      code: 'BS'
    },
    {
      name: 'Bahrain(BH)',
      code: 'BH'
    },
    {
      name: 'Bangladesh(BD)',
      code: 'BD'
    },
    {
      name: 'Barbados(BB)',
      code: 'BB'
    },
    {
      name: 'Belarus(BY)',
      code: 'BY'
    },
    {
      name: 'Belgium(BE)',
      code: 'BE'
    },
    {
      name: 'Belize(BZ)',
      code: 'BZ'
    },
    {
      name: 'Benin(BJ)',
      code: 'BJ'
    },
    {
      name: 'Bermuda(BM)',
      code: 'BM'
    },
    {
      name: 'Bhutan(BT)',
      code: 'BT'
    },
    {
      name: 'Bolivia(BO)',
      code: 'BO'
    },
    {
      name: 'Bosnia and Herzegovina(BA)',
      code: 'BA'
    },
    {
      name: 'Botswana(BW)',
      code: 'BW'
    },
    {
      name: 'Bouvet Island(BV)',
      code: 'BV'
    },
    {
      name: 'Brazil(BR)',
      code: 'BR'
    },
    {
      name: 'British Indian Ocean Territory(IO)',
      code: 'IO'
    },
    {
      name: 'Brunei Darussalam(BN)',
      code: 'BN'
    },
    {
      name: 'Bulgaria(BG)',
      code: 'BG'
    },
    {
      name: 'Burkina Faso(BF)',
      code: 'BF'
    },
    {
      name: 'Burundi(BI)',
      code: 'BI'
    },
    {
      name: 'Cambodia(KH)',
      code: 'KH'
    },
    {
      name: 'Cameroon(CM)',
      code: 'CM'
    },

    {
      name: 'Cape Verde(CV)',
      code: 'CV'
    },
    {
      name: 'Cayman Islands(KY)',
      code: 'KY'
    },
    {
      name: 'Central African Republic(CF)',
      code: 'CF'
    },
    {
      name: 'Chad(TD)',
      code: 'TD'
    },
    {
      name: 'Chile(CL)',
      code: 'CL'
    },
    {
      name: 'China(CN)',
      code: 'CN'
    },
    {
      name: 'Christmas Island(CX)',
      code: 'CX'
    },
    {
      name: 'Cocos (Keeling) Islands(CC)',
      code: 'CC'
    },
    {
      name: 'Colombia(CO)',
      code: 'CO'
    },
    {
      name: 'Comoros(KM)',
      code: 'KM'
    },
    {
      name: 'Congo(CG)',
      code: 'CG'
    },
    {
      name: 'Congo, The Democratic Republic of the(CD)',
      code: 'CD'
    },
    {
      name: 'Cook Islands(CK)',
      code: 'CK'
    },
    {
      name: 'Costa Rica(CR)',
      code: 'CR'
    },
    {
      name: 'Cote D"Ivoire(CI)',
      code: 'CI'
    },
    {
      name: 'Croatia(HR)',
      code: 'HR'
    },
    {
      name: 'Cuba(CU)',
      code: 'CU'
    },
    {
      name: 'Curacao(CW)',
      code: 'CW'
    },
    {
      name: 'Cyprus(CY)',
      code: 'CY'
    },
    {
      name: 'Czech Republic(CZ)',
      code: 'CZ'
    },
    {
      name: 'Denmark(DK)',
      code: 'DK'
    },
    {
      name: 'Djibouti(DJ)',
      code: 'DJ'
    },
    {
      name: 'Dominica(DM)',
      code: 'DM'
    },
    {
      name: 'Dominican Republic(DO)',
      code: 'DO'
    },
    {
      name: 'Ecuador(EC)',
      code: 'EC'
    },
    {
      name: 'Egypt(EG)',
      code: 'EG'
    },
    {
      name: 'El Salvador(SV)',
      code: 'SV'
    },
    {
      name: 'Equatorial Guinea(GQ)',
      code: 'GQ'
    },
    {
      name: 'Eritrea(ER)',
      code: 'ER'
    },
    {
      name: 'Estonia(EE)',
      code: 'EE'
    },
    {
      name: 'Ethiopia(ET)',
      code: 'ET'
    },
    {
      name: 'Falkland Islands(FK)',
      code: 'FK'
    },
    {
      name: 'Faroe Islands(FO)',
      code: 'FO'
    },
    {
      name: 'Fiji(FJ)',
      code: 'FJ'
    },
    {
      name: 'Finland(FI)',
      code: 'FI'
    },
    {
      name: 'France(FR)',
      code: 'FR'
    },
    {
      name: 'French Guiana(GF)',
      code: 'GF'
    },
    {
      name: 'French Polynesia(PF)',
      code: 'PF'
    },
    {
      name: 'French Southern Territories(TF)',
      code: 'TF'
    },
    {
      name: 'Gabon(GA)',
      code: 'GA'
    },
    {
      name: 'Gambia(GM)',
      code: 'GM'
    },
    {
      name: 'Georgia(GE)',
      code: 'GE'
    },
    {
      name: 'Germany(DE)',
      code: 'DE'
    },
    {
      name: 'Ghana(GH)',
      code: 'GH'
    },
    {
      name: 'Gibraltar(GI)',
      code: 'GI'
    },
    {
      name: 'Greece(GR)',
      code: 'GR'
    },
    {
      name: 'Greenland(GL)',
      code: 'GL'
    },
    {
      name: 'Grenada(GD)',
      code: 'GD'
    },
    {
      name: 'Guadeloupe(GP)',
      code: 'GP'
    },
    // {
    //     "name": "Guam(GU)",
    //     "code": "GU"
    // },
    {
      name: 'Guatemala(GT)',
      code: 'GT'
    },
    {
      name: 'Guernsey(GG)',
      code: 'GG'
    },
    {
      name: 'Guinea(GN)',
      code: 'GN'
    },
    {
      name: 'Guinea-Bissau(GW)',
      code: 'GW'
    },
    {
      name: 'Guyana(GY)',
      code: 'GY'
    },
    {
      name: 'Haiti(HT)',
      code: 'HT'
    },
    {
      name: 'Heard Island and Mcdonald Islands(HM)',
      code: 'HM'
    },
    {
      name: 'Vatican City(VA)',
      code: 'VA'
    },
    {
      name: 'Honduras(HN)',
      code: 'HN'
    },
    {
      name: 'Hong Kong(HK)',
      code: 'HK'
    },
    {
      name: 'Hungary(HU)',
      code: 'HU'
    },
    {
      name: 'Iceland(IS)',
      code: 'IS'
    },
    {
      name: 'India(IN)',
      code: 'IN'
    },
    {
      name: 'Indonesia(ID)',
      code: 'ID'
    },
    {
      name: 'Iran, Islamic Republic Of(IR)',
      code: 'IR'
    },
    {
      name: 'Iraq(IQ)',
      code: 'IQ'
    },
    {
      name: 'Ireland(IE)',
      code: 'IE'
    },
    {
      name: 'Isle of Man(IM)',
      code: 'IM'
    },
    {
      name: 'Israel(IL)',
      code: 'IL'
    },
    {
      name: 'Italy(IT)',
      code: 'IT'
    },
    {
      name: 'Jamaica(JM)',
      code: 'JM'
    },
    {
      name: 'Japan(JP)',
      code: 'JP'
    },
    {
      name: 'Jersey(JE)',
      code: 'JE'
    },
    {
      name: 'Jordan(JO)',
      code: 'JO'
    },
    {
      name: 'Kazakhstan(KZ)',
      code: 'KZ'
    },
    {
      name: 'Kenya(KE)',
      code: 'KE'
    },
    {
      name: 'Kiribati(KI)',
      code: 'KI'
    },
    {
      name: 'Korea, Democratic People"S Republic of(KP)',
      code: 'KP'
    },
    {
      name: 'Korea, Republic of(KR)',
      code: 'KR'
    },
    {
      name: 'Kuwait(KW)',
      code: 'KW'
    },
    {
      name: 'Kyrgyzstan(KG)',
      code: 'KG'
    },
    {
      name: 'Lao People"S Democratic Republic(LA)',
      code: 'LA'
    },
    {
      name: 'Latvia(LV)',
      code: 'LV'
    },
    {
      name: 'Lebanon(LB)',
      code: 'LB'
    },
    {
      name: 'Lesotho(LS)',
      code: 'LS'
    },
    {
      name: 'Liberia(LR)',
      code: 'LR'
    },
    {
      name: 'Libyan Arab Jamahiriya(LY)',
      code: 'LY'
    },
    {
      name: 'Liechtenstein(LI)',
      code: 'LI'
    },
    {
      name: 'Lithuania(LT)',
      code: 'LT'
    },
    {
      name: 'Luxembourg(LU)',
      code: 'LU'
    },
    {
      name: 'Macao(MO)',
      code: 'MO'
    },
    {
      name: 'Macedonia, The Former Yugoslav Republic of(MK)',
      code: 'MK'
    },
    {
      name: 'Madagascar(MG)',
      code: 'MG'
    },
    {
      name: 'Malawi(MW)',
      code: 'MW'
    },
    {
      name: 'Malaysia(MY)',
      code: 'MY'
    },
    {
      name: 'Maldives(MV)',
      code: 'MV'
    },
    {
      name: 'Mali(ML)',
      code: 'ML'
    },
    {
      name: 'Malta(MT)',
      code: 'MT'
    },
    {
      name: 'Marshall Islands(MH)',
      code: 'MH'
    },
    {
      name: 'Martinique(MQ)',
      code: 'MQ'
    },
    {
      name: 'Mauritania(MR)',
      code: 'MR'
    },
    {
      name: 'Mauritius(MU)',
      code: 'MU'
    },
    {
      name: 'Mayotte(YT)',
      code: 'YT'
    },
    {
      name: 'Mexico(MX)',
      code: 'MX'
    },
    {
      name: 'Moldova, Republic of(MD)',
      code: 'MD'
    },
    {
      name: 'Monaco(MC)',
      code: 'MC'
    },
    {
      name: 'Mongolia(MN)',
      code: 'MN'
    },
    {
      name: 'Montenegro(ME)',
      code: 'ME'
    },
    {
      name: 'Montserrat(MS)',
      code: 'MS'
    },
    {
      name: 'Morocco(MA)',
      code: 'MA'
    },
    {
      name: 'Mozambique(MZ)',
      code: 'MZ'
    },
    {
      name: 'Myanmar(MM)',
      code: 'MM'
    },
    {
      name: 'Namibia(NA)',
      code: 'NA'
    },
    {
      name: 'Nauru(NR)',
      code: 'NR'
    },
    {
      name: 'Nepal(NP)',
      code: 'NP'
    },
    {
      name: 'Netherlands(NL)',
      code: 'NL'
    },
    {
      name: 'Netherlands Antilles(AN)',
      code: 'AN'
    },
    {
      name: 'New Caledonia(NC)',
      code: 'NC'
    },
    {
      name: 'New Zealand(NZ)',
      code: 'NZ'
    },
    {
      name: 'Nicaragua(NI)',
      code: 'NI'
    },
    {
      name: 'Niger(NE)',
      code: 'NE'
    },
    {
      name: 'Nigeria(NG)',
      code: 'NG'
    },
    {
      name: 'Niue(NU)',
      code: 'NU'
    },
    {
      name: 'Norfolk Island(NF)',
      code: 'NF'
    },
    // {
    //   name: 'Northern Mariana Islands(MP)',
    //   code: 'MP'
    // },
    {
      name: 'Norway(NO)',
      code: 'NO'
    },
    {
      name: 'Oman(OM)',
      code: 'OM'
    },
    {
      name: 'Pakistan(PK)',
      code: 'PK'
    },
    {
      name: 'Palau(PW)',
      code: 'PW'
    },
    {
      name: 'Palestinian Territory, Occupied(PS)',
      code: 'PS'
    },
    {
      name: 'Panama(PA)',
      code: 'PA'
    },
    {
      name: 'Papua New Guinea(PG)',
      code: 'PG'
    },
    {
      name: 'Paraguay(PY)',
      code: 'PY'
    },
    {
      name: 'Peru(PE)',
      code: 'PE'
    },
    {
      name: 'Philippines(PH)',
      code: 'PH'
    },
    {
      name: 'Pitcairn(PN)',
      code: 'PN'
    },
    {
      name: 'Poland(PL)',
      code: 'PL'
    },
    {
      name: 'Portugal(PT)',
      code: 'PT'
    },
    // {
    //     "name": "Puerto Rico(PR)",
    //     "code": "PR"
    // },
    {
      name: 'Qatar(QA)',
      code: 'QA'
    },
    {
      name: 'Reunion(RE)',
      code: 'RE'
    },
    {
      name: 'Romania(RO)',
      code: 'RO'
    },
    {
      name: 'Russian Federation(RU)',
      code: 'RU'
    },
    {
      name: 'RWANDA(RW)',
      code: 'RW'
    },
    {
      name: 'Saint Helena(SH)',
      code: 'SH'
    },
    {
      name: 'Saint Kitts and Nevis(KN)',
      code: 'KN'
    },
    {
      name: 'Saint Lucia(LC)',
      code: 'LC'
    },
    {
      name: 'Saint Pierre and Miquelon(PM)',
      code: 'PM'
    },
    {
      name: 'Saint Vincent and the Grenadines(VC)',
      code: 'VC'
    },
    {
      name: 'Samoa(WS)',
      code: 'WS'
    },
    {
      name: 'San Marino(SM)',
      code: 'SM'
    },
    {
      name: 'Sao Tome and Principe(ST)',
      code: 'ST'
    },
    {
      name: 'Saudi Arabia(SA)',
      code: 'SA'
    },
    {
      name: 'Senegal(SN)',
      code: 'SN'
    },
    {
      name: 'Serbia(RS)',
      code: 'RS'
    },
    {
      name: 'Seychelles(SC)',
      code: 'SC'
    },
    {
      name: 'Sierra Leone(SL)',
      code: 'SL'
    },
    {
      name: 'Singapore(SG)',
      code: 'SG'
    },
    {
      name: 'Slovakia(SK)',
      code: 'SK'
    },
    {
      name: 'Slovenia(SI)',
      code: 'SI'
    },
    {
      name: 'Solomon Islands(SB)',
      code: 'SB'
    },
    {
      name: 'Somalia(SO)',
      code: 'SO'
    },
    {
      name: 'South Africa(ZA)',
      code: 'ZA'
    },
    {
      name: 'South Georgia and the South Sandwich Islands(GS)',
      code: 'GS'
    },
    {
      name: 'Spain(ES)',
      code: 'ES'
    },
    {
      name: 'Sri Lanka(LK)',
      code: 'LK'
    },
    {
      name: 'Sudan(SD)',
      code: 'SD'
    },
    {
      name: 'Suriname(SR)',
      code: 'SR'
    },
    {
      name: 'Svalbard and Jan Mayen(SJ)',
      code: 'SJ'
    },
    {
      name: 'Swaziland(SZ)',
      code: 'SZ'
    },
    {
      name: 'Sweden(SE)',
      code: 'SE'
    },
    {
      name: 'Switzerland(CH)',
      code: 'CH'
    },
    {
      name: 'Syrian Arab Republic(SY)',
      code: 'SY'
    },
    {
      name: 'Taiwan, Province of China(TW)',
      code: 'TW'
    },
    {
      name: 'Tajikistan(TJ)',
      code: 'TJ'
    },
    {
      name: 'Tanzania, United Republic of(TZ)',
      code: 'TZ'
    },
    {
      name: 'Thailand(TH)',
      code: 'TH'
    },
    {
      name: 'Timor-Leste(TL)',
      code: 'TL'
    },
    {
      name: 'Togo(TG)',
      code: 'TG'
    },
    {
      name: 'Tokelau(TK)',
      code: 'TK'
    },
    {
      name: 'Tonga(TO)',
      code: 'TO'
    },
    {
      name: 'Trinidad and Tobago(TT)',
      code: 'TT'
    },
    {
      name: 'Tunisia(TN)',
      code: 'TN'
    },
    {
      name: 'Turkey(TR)',
      code: 'TR'
    },
    {
      name: 'Turkmenistan(TM)',
      code: 'TM'
    },
    {
      name: 'Turks and Caicos Islands(TC)',
      code: 'TC'
    },
    {
      name: 'Tuvalu(TV)',
      code: 'TV'
    },
    {
      name: 'Uganda(UG)',
      code: 'UG'
    },
    {
      name: 'Ukraine(UA)',
      code: 'UA'
    },
    {
      name: 'United Arab Emirates(AE)',
      code: 'AE'
    },
    {
      name: 'United Kingdom(GB)',
      code: 'GB'
    },

    // {
    //     "name": "United States Minor Outlying Islands(UM)",
    //     "code": "UM"
    // },
    {
      name: 'Uruguay(UY)',
      code: 'UY'
    },
    {
      name: 'Uzbekistan(UZ)',
      code: 'UZ'
    },
    {
      name: 'Vanuatu(VU)',
      code: 'VU'
    },
    {
      name: 'Venezuela(VE)',
      code: 'VE'
    },
    {
      name: 'Viet Nam(VN)',
      code: 'VN'
    },
    {
      name: 'Virgin Islands, British(VG)',
      code: 'VG'
    },
    // {
    //     "name": "Virgin Islands, U.S.(VI)",
    //     "code": "VI"
    // },
    {
      name: 'Wallis and Futuna(WF)',
      code: 'WF'
    },
    {
      name: 'Western Sahara(EH)',
      code: 'EH'
    },
    {
      name: 'Kosovo',
      code: 'XK'
    },
    {
      name: 'Yemen(YE)',
      code: 'YE'
    },
    {
      name: 'Zambia(ZM)',
      code: 'ZM'
    },
    {
      name: 'Zimbabwe(ZW)',
      code: 'ZW'
    }
  ],

  PROVINCES: [
    {
      name: 'Alberta(AB)',
      code: 'AB'
    },
    {
      name: 'British Columbia(BC)',
      code: 'BC'
    },
    {
      name: 'Manitoba(MB)',
      code: 'MB'
    },
    {
      name: 'New Brunswick(NB)',
      code: 'NB'
    },
    {
      name: 'Newfoundland and Labrador(NL)',
      code: 'NL'
    },
    {
      name: 'Northwest Territories(NT)',
      code: 'NT'
    },
    {
      name: 'Nova Scotia(NS)',
      code: 'NS'
    },
    {
      name: 'Nunavut(NU)',
      code: 'NU'
    },
    {
      name: 'Ontario(ON)',
      code: 'ON'
    },
    {
      name: 'Prince Edward Island(PE)',
      code: 'PE'
    },
    {
      name: 'Quebec(QC)',
      code: 'QC'
    },
    {
      name: 'Saskatchewan(SK)',
      code: 'SK'
    },
    {
      name: 'Yukon Territory(YT)',
      code: 'YT'
    }
  ],

  STATES: [
    {
      code: 'AK',
      name: 'Alaska(AK)'
    },
    {
      code: 'AL',
      name: 'Alabama(AL)'
    },
    {
      code: 'AR',
      name: 'Arkansas(AR)'
    },
    {
      code: 'AS',
      name: 'American Samoa(AS)'
    },
    {
      code: 'AZ',
      name: 'Arizona(AZ)'
    },
    {
      code: 'CA',
      name: 'California(CA)'
    },
    {
      code: 'CO',
      name: 'Colorado(CO)'
    },
    {
      code: 'CT',
      name: 'Connecticut(CT)'
    },
    {
      code: 'DC',
      name: 'District of Columbia(DC)'
    },
    {
      code: 'DE',
      name: 'Delaware(DE)'
    },
    {
      code: 'FL',
      name: 'Florida(FL)'
    },
    {
      name: 'Micronesia, Federated States of(FM)',
      code: 'FM'
    },
    {
      code: 'GA',
      name: 'Georgia(GA)'
    },
    {
      code: 'GU',
      name: 'Guam(GU)'
    },
    {
      code: 'HI',
      name: 'Hawaii(HI)'
    },
    {
      code: 'IA',
      name: 'Iowa(ia)'
    },
    {
      code: 'ID',
      name: 'Idaho(ID)'
    },
    {
      code: 'IL',
      name: 'Illinois(IL)'
    },
    {
      code: 'IN',
      name: 'Indiana(IN)'
    },
    {
      code: 'KS',
      name: 'Kansas(KS)'
    },
    {
      code: 'KY',
      name: 'Kentucky(KY)'
    },
    {
      code: 'LA',
      name: 'Louisiana(LA)'
    },
    {
      code: 'MA',
      name: 'Massachusetts(MA)'
    },
    {
      code: 'MD',
      name: 'Maryland(MD)'
    },
    {
      code: 'ME',
      name: 'Maine(ME)'
    },
    {
      code: 'MI',
      name: 'Michigan(MI)'
    },
    {
      code: 'MN',
      name: 'Minnesota(MN)'
    },
    {
      code: 'MO',
      name: 'Missouri(MO)'
    },
    {
      code: 'MS',
      name: 'Mississippi(MS)'
    },
    {
      code: 'MT',
      name: 'Montana(MT)'
    },
    {
      code: 'NC',
      name: 'North Carolina(NC)'
    },
    {
      code: 'ND',
      name: 'North Dakota(ND)'
    },
    {
      code: 'NE',
      name: 'Nebraska(NE)'
    },
    {
      code: 'NH',
      name: 'New Hampshire(NH)'
    },
    {
      code: 'NJ',
      name: 'New Jersey(NJ)'
    },
    {
      code: 'NM',
      name: 'New Mexico(NM)'
    },
    {
      code: 'NV',
      name: 'Nevada(NV)'
    },
    {
      code: 'NY',
      name: 'New York(NY)'
    },
    {
      code: 'OH',
      name: 'Ohio(OH)'
    },
    {
      code: 'OK',
      name: 'Oklahoma(OK)'
    },
    {
      code: 'OR',
      name: 'Oregon(OR)'
    },
    {
      code: 'PA',
      name: 'Pennsylvania(PA)'
    },
    {
      code: 'PR',
      name: 'Puerto Rico(PR)'
    },
    {
      code: 'RI',
      name: 'Rhode Island(RI)'
    },
    {
      code: 'SC',
      name: 'South Carolina(SC)'
    },
    {
      code: 'SD',
      name: 'South Dakota(SD)'
    },
    {
      code: 'TN',
      name: 'Tennessee(TN)'
    },
    {
      code: 'TX',
      name: 'Texas(TX)'
    },
    {
      code: 'UT',
      name: 'Utah(UT)'
    },
    {
      code: 'VA',
      name: 'Virginia(VA)'
    },
    {
      code: 'VI',
      name: 'Virgin Islands(VI)'
    },
    {
      code: 'VT',
      name: 'Vermont(VT)'
    },
    {
      code: 'WA',
      name: 'Washington(WA)'
    },
    {
      code: 'WI',
      name: 'Wisconsin(WI)'
    },
    {
      code: 'WV',
      name: 'West Virginia(WV)'
    },
    {
      code: 'WY',
      name: 'Wyoming(WY)'
    },
    {
      code: 'AE',
      name: 'Armed Forces Europe(AE)'
    },
    {
      code: 'AP',
      name: 'Armed Forces Pacific(AP)'
    },
    {
      code: 'AA',
      name: 'Armed Forces Canada(AA)'
    },
    {
      name: 'Northern Mariana Islands(MP)',
      code: 'MP'
    },
    {
      name: 'American Samoa(AS)',
      code: 'AS'
    },
    {
      name: 'United States Minor Outlying Islands(UM)',
      code: 'UM'
    }
  ],

  DEFAULT_PRESET_ITEM: {
    name: null,
    address1: null,
    address2: null,
    city: null,
    province_code: null,
    postal_code: null,
    country_code: null,
    phone: null,
    package_type: null,
    package_type_id: null,
    description: null,
    package_contents: null,
    value: null,
    currency: null,
    weight: null,
    weight_unit: null,
    length: null,
    width: null,
    height: null,
    size_unit: null,
    needs_postage: null,
    postage_type: null,
    postage_type_id: null,
    tracking_code: null,
    insured: null,
    signature: null,
    is_fba: null,
    signature_confirmation: null,
    items: [],
    lowest_postage_ids: [],
  },

  DEFAULT_PICKUP_LOCATIONS: [
    {
      id: 1,
      location: 'Markham'
    },
    {
      id: 5,
      location: 'Mississauga'
    }
  ],

  REFERRAL_LIST: [
    'Google Search',
    'TikTok',
    'Instagram',
    'YouTube',
    'Facebook Ad',
    "Friend's Referral",
    'Online Forum',
    'You contacted me!',
    'Other'
  ],

  PRODUCT_CATEGORIES_1: [
    'Automotive',
    'Baby Products',
    'Beauty',
    'Books',
    'Camera & Photo',
    'Clothing & Accessories',
    'Consumer Electronics',
    'Electronics Accessories',
    'Health & Personal Care'
  ],

  PRODUCT_CATEGORIES_2: [
    'Home & Garden',
    'Jewelry',
    'Music',
    'Office Products',
    'Outdoors',
    'Sports',
    'Tools & Home Improvement',
    'Toys & Games'
  ],

  PACKAGE_COUNTS: ['1-10', '11-50', '51-200', '200+'],

  RETURN_SERVICES: {
    action_required: 1,
    return_requested: 2,
    dispose: 3
  },

  RETURN_STATUSES: {
    awaiting_review: 5,
    ready_to_ship: 10,
    complete: 35
  },

  POSTAGE_PROVIDERS: [
    {
      id: 70,
      name: 'Prepaid'
    },
    {
      id: 60,
      name: 'FBA'
    },
    {
      id: 50,
      name: 'Frieghtcom'
    },
    {
      id: 40,
      name: 'APC'
    },
    {
      id: 30,
      name: 'PostNL'
    },
    {
      id: 20,
      name: 'Landmark'
    },
    {
      id: 10,
      name: 'Stamps'
    },
    {
      id: 80,
      name: 'OSM'
    },
    {
      id: 90,
      name: 'FedEx'
    },
    {
      id: 110,
      name: 'FleetOptics'
    },
    {
      id: 120,
      name: 'USPS'
    },
    {
      id: 130,
      name: 'UniUni'
    },
    {
      id: 140,
      name: 'GreatVision'
    },
    {
      id: 150,
      name: 'UPS'
    },
    {
      id: 160,
      name: 'Ecom Logistics'
    }
  ],

  EU_COUNTRIES: [
    'ES',
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'SE'
  ],

  TAX_COUNTRIES: ['GB', 'BR', 'CL', 'AR'],

  CURRENCIES: [
    {
      text: 'CAD',
      key: 'CAD'
    },
    {
      text: 'USD',
      key: 'USD'
    },
    {
      text: 'EUR',
      key: 'EUR'
    },
    {
      text: 'AUD',
      key: 'AUD'
    },
    {
      text: 'GBP',
      key: 'GBP'
    }
  ],

  QUICKER_SHIP_TABS: {
    view: 'tab-view-shipment',
    edit: 'tab-edit-shipment'
  }
};
