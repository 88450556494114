<template>
  <div>
    <StallionDialog
      v-model="show"
      :width="500"
      :title="dialogTitle"
    >
      <template #content>
        <v-row dense>
          <v-col xs12>
            <h2>Which records would you like to export?</h2>
            <v-radio-group v-model="exportType">
              <v-radio value="export-all">
                <template #label>
                  <span>Export all {{ total }} records</span>
                </template>
              </v-radio>
              <v-radio
                value="export-selected"
                :disabled="selected.length === 0"
              >
                <template #label>
                  <span>Only export the {{ selected.length }} records that are currently selected</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
  
        <v-row dense>
          <v-col xs12>
            <h2>How would you like to export these?</h2>
            <v-radio-group v-model="lineItems">
              <v-radio
                :label="'Export ' + type + 's only'"
                value="no_line_item"
              />
              <v-radio
                :label="'Export ' + type + 's with line items'"
                value="with_line_item"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </template>
  
      <template #actions>
        <v-btn
          color="primary"
          :loading="isExporting"
          @click="exportData"
        >
          Export
        </v-btn>
      </template>
    </StallionDialog>
  </div>
</template>
  
  <script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: true,
      },
      selected: {
        type: Array,
        default: () => [],
      },
      total: {
        type: Number,
        default: 0,
      },
      dialogLabel: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: 'order', // 'order' or 'shipment'
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      endpoint: {
        type: String,
        default: null,
      },
      method: {
        type: String,
        default: 'post',
      },
    },
    data() {
      return {
        lineItems: 'no_line_item',
        exportType: 'export-all',
        isExporting: false,
      };
    },
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      dialogTitle() {
        return `Export ${this.type.charAt(0).toUpperCase() + this.type.slice(1)}s`;
      },
    },
    methods: {
      generateFileName(withLineItems) {
        const itemType = this.type.charAt(0).toUpperCase() + this.type.slice(1);
        const lineItemSuffix = withLineItems ? '_Line_Items' : '';
        return `Stallion_${itemType}${lineItemSuffix}_Export.csv`;
      },
      exportData() {
        this.isExporting = true;
        const withLineItems = this.lineItems === 'with_line_item';

        let params = {
          with_line_items: withLineItems,
          export: true
        };

        if (this.exportType !== 'export-all') {
          // Export only selected items
          const ids = this.selected.map((item) => item.id);
          params = {
            ...params,
            [`${this.type}_ids`]: ids,
          };
        } else{
          // Add any additional params
          params = {
            ...params,
            ...this.params,
          }
        }
  
        this.$http
          .request({
            url: this.endpoint,
            method: this.method,
            params,
          })
          .then((response) => {
            const fileName = this.generateFileName(withLineItems);
            this.downloadFileUrl(response.data, fileName, 'text/csv');
          })
          .catch(() => {
            this.errorMessage('Nothing to export');
          })
          .finally(() => {
            this.isExporting = false;
            this.show = false;
          });
      },
    },
  };
  </script>
  
  <style scoped></style>
  