<template>
  <v-row dense>
    <template
      v-for="(list, index) in newSettings.workflow_settings"
    >
      <v-col
        v-if="list.hidden !== true"
        :key="index"
        cols="12"
      >
        <user-settings-option
          :show-description="true"
          :list="list"
          :dense="false"
          chips
        />
      </v-col>
    </template>
    
    <v-col cols="12">
      <v-btn
        color="primary"
        :loading="processing"
        @click="submit"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { handleLaravelValidationError } from '@/helpers/helper';
export default {
  data() {
    return {
      newSettings: {
        workflow_settings: _.cloneDeep(
          this.$auth.user.user_config_settings.workflow_settings
        )
      },
      processing: false
    };
  },
  mounted() {},
  methods: {
    submit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing = true;
          this.$http
            .post('/users/updateUserConfigSettings', this.newSettings)
            .then((res) => {
              // console.log(res);
              if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
                if (res.data.success) {
                  this.successMessage('Your account changes have been saved');
                  this.$auth.user.user_config_settings.workflow_settings = _.cloneDeep(this.newSettings.workflow_settings)
                } else {
                  this.errorMessage('Your account changes have not been saved');
                }
              }
            })
            .catch((err) => {
              handleLaravelValidationError(err);
            })
            .finally(() => {
              this.processing = false;
            });
        }
      });
    }
  }
};
</script>
