<template>
  <div>
    <StallionDialog
      v-model="showQuickShipForm"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      :title="dialogTitle"
      fluid
      @onDialogClosed="close()"
    >
      <template
        v-if="shipmentLocal"
        #content
      >
        <v-card-text>
          <v-form>
            <v-container fluid>
              <v-card 
                v-if="isReturnLabel"
                outlined
                class="mb-3"
              >
                <v-row>
                  <v-col class="d-flex ml-3 mt-2 justify-start">
                    <!-- <div>
                      <v-switch
                        v-model="isBatchProcess"
                        class="ma-0"
                        label="Batch Processing"
                      />
                    </div> -->

                    <div>
                      <v-select
                        v-model="selectedShipmentIndex"
                        :disabled="!isBatchProcess || updateLoading || purchaseLoading"
                        class="px-2"
                        :items="shipments"
                        label="Shipments"
                        :item-text="(item) => getReturnLabelnfo(item)"
                        item-value="_index"
                        outlined
                        dense
                        @change="onSelectedShipmentChange"
                      />
                    </div>

                    <div>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            :disabled="!isBatchProcess || shipments.length == 1 || updateLoading || purchaseLoading"
                            large
                            text
                            color="red"
                            icon
                            @click="removeShipment"
                            v-on="on"
                          >
                            <v-icon
                              left
                            >
                              mdi-minus-circle-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Remove shipment</span>
                      </v-tooltip>
                    </div>
                    
                    <!-- <div>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            :disabled="!isBatchProcess || updateLoading || purchaseLoading"
                            large
                            text
                            color="green"
                            icon
                            @click="addShipment"
                            v-on="on"
                          >
                            <v-icon
                              left
                              
                            >
                              mdi-plus-circle-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Add shipment</span>
                      </v-tooltip>
                    </div> -->
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                outlined
                class="mb-3"
              >
                <v-row>
                  <v-col>
                    <v-tabs v-model="tab">
                      <v-tab>
                        <v-icon left>
                          mdi-map
                        </v-icon>Address
                      </v-tab>

                      <v-tab>
                        <v-icon left>
                          mdi-clipboard-text-outline
                        </v-icon>Order
                      </v-tab>

                      <v-tab>
                        <v-icon left>
                          mdi-package-variant-closed
                        </v-icon>Shipping
                      </v-tab>

                      <!-- <v-tab>
                        <v-icon left>
                          mdi-package-variant-closed
                        </v-icon>Package Contents
                      </v-tab> -->

                      <!-- <v-tab :disabled="true" @click="openPresetsDialog()">
                      <v-icon left>
                        mdi-auto-upload
                      </v-icon>Presets
                    </v-tab> -->
                    </v-tabs>
                  </v-col>

                  <v-col class="d-flex mt-2 mr-2 justify-end">
                    <div>
                      <v-select
                        v-model="presetId"
                        v-validate="''"
                        class="px-2"
                        data-vv-name="presetId"
                        :error-messages="errs.collect('presetId')"
                        :items="presets"
                        item-text="preset_name"
                        item-value="id"
                        label="Presets"
                        outlined
                        :clearable="true"
                        dense
                        @change="applyPresetOnDropdown"
                      />
                    </div>
                    <div>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            :disabled="!presetId || updateLoading || purchaseLoading"
                            large
                            text
                            color="primary"
                            icon
                            @click="applyPresetOnDropdown"
                            v-on="on"
                          >
                            <v-icon
                              left
                              large
                            >
                              mdi-content-save-check
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Apply Preset</span>
                      </v-tooltip>
                    </div>
                    <div>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            icon
                            large
                            text
                            :disabled="updateLoading || purchaseLoading"
                            color="primary"
                            @click="openPresetsDialog()"
                            v-on="on"
                          >
                            <v-icon
                                
                              large
                            >
                              mdi-auto-upload
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Manage Presets</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-card>

              <v-tabs-items v-model="tab">
                <v-tab-item :transition="false">
                  <v-row dense>
                    <v-col
                      v-if="shipmentLocal.return_label"
                      cols="6"
                    >
                      <v-card
                        outlined
                        class="pa-5"
                      >
                        <v-row dense>
                          <v-col
                            cols="12"
                            class="d-flex flex-row"
                          >
                            <div>
                              <h3 class="mr-2">
                                From
                              </h3>
                            </div>

                            <v-spacer />

                            <div class="d-flex flex-row mt-n2">
                              <div>
                                <AddressBookQuickActionButtons
                                  :address-to-save="getAddressToSave('from')"
                                  @selectAddressBook="selectAddressBookFrom"
                                />
                              </div>
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="shipmentLocal.return_name"
                              v-validate="'required'"
                              :error-messages="errs.collect('return_name')"
                              data-vv-name="return_name"
                              label="Name"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="shipmentLocal.return_company"
                              v-validate="''"
                              :error-messages="errs.collect('return_company')"
                              data-vv-name="return_company"
                              label="Company"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              id="QuickShipFromReturnAddress1"
                              v-model="shipmentLocal.return_address1"
                              v-validate="'required'"
                              :error-messages="
                                errs.collect('return_address_1')
                              "
                              data-vv-name="return_address_1"
                              label="Address 1"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              ref="refReturnAddress2"
                              v-model="shipmentLocal.return_address2"
                              :error-messages="
                                errs.collect('return_address_2')
                              "
                              data-vv-name="return_address_2"
                              label="Address 2"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-select
                              v-model="shipmentLocal.return_country_code"
                              v-validate="'required'"
                              :error-messages="
                                errs.collect('return_country_code')
                              "
                              data-vv-name="return_country_code"
                              label="Country Code"
                              :items="countries"
                              item-text="name"
                              item-value="code"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="shipmentLocal.return_city"
                              v-validate="'required'"
                              :error-messages="errs.collect('return_city')"
                              data-vv-name="return_city"
                              label="City"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-autocomplete
                              v-if="shipmentLocal.return_country_code == 'CA'"
                              v-model="shipmentLocal.return_province_code"
                              v-validate="'required|max:2'"
                              clearable
                              outlined
                              dense
                              :error-messages="
                                errs.collect('return_province_code')
                              "
                              data-vv-name="return_province_code"
                              :items="provinces"
                              label="Province"
                              item-text="name"
                              item-value="code"
                              dusk="sf_province"
                            />

                            <v-autocomplete
                              v-else-if="shipmentLocal.return_country_code == 'US'"
                              v-model="shipmentLocal.return_province_code"
                              v-validate="'required|max:2'"
                              clearable
                              outlined
                              :error-messages="
                                errs.collect('return_province_code')
                              "
                              data-vv-name="return_province_code"
                              :items="states"
                              label="State"
                              item-text="name"
                              item-value="code"
                              dense
                              dusk="sf_province"
                            />

                            <v-text-field
                              v-else
                              v-model="shipmentLocal.return_province_code"
                              clearable
                              outlined
                              dense
                              dusk="sf_province"
                              label="Province/State"
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="shipmentLocal.return_postal_code"
                              v-validate="'required'"
                              :error-messages="
                                errs.collect('return_postal_code')
                              "
                              data-vv-name="return_postal_code"
                              label="Postal Code"
                              dense
                              outlined
                            />
                          </v-col>

                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="shipmentLocal.phone"
                              v-validate
                              :error-messages="errs.collect('phone')"
                              data-vv-name="phone"
                              label="Phone"
                              dense
                              outlined
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card
                        outlined
                        class="pa-5"
                      >
                        <v-row dense>
                          <v-col
                            cols="12"
                            class="d-flex flex-row"
                          >
                            <div>
                              <h3 class="mr-2">
                                To
                              </h3>
                            </div>

                            <v-spacer />

                            <div class="d-flex flex-row mt-n2">
                              <div>
                                <AddressBookQuickActionButtons  
                                  :address-to-save="getAddressToSave('to')"
                                  @selectAddressBook="selectAddressBookTo"
                                />
                              </div>
                            </div>
                          </v-col>

                          <v-col cols="6">
                            <v-text-field
                              v-model="shipmentLocal.name"
                              v-validate="'required'"
                              :error-messages="errs.collect('name')"
                              data-vv-name="name"
                              label="Name"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="shipmentLocal.company"
                              :error-messages="errs.collect('company')"
                              data-vv-name="company"
                              label="Company"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              id="QuickShipToAddress1"
                              v-model="shipmentLocal.address1"
                              v-validate="'required'"
                              :error-messages="errs.collect('address_1')"
                              data-vv-name="address_1"
                              label="Address 1"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              ref="refAddress2"
                              v-model="shipmentLocal.address2"
                              :error-messages="errs.collect('address_2')"
                              data-vv-name="address_2"
                              label="Address 2"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-select
                              v-model="shipmentLocal.country_code"
                              v-validate="'required'"
                              :error-messages="errs.collect('country_code')"
                              data-vv-name="country_code"
                              label="Country Code"
                              :items="countries"
                              item-text="name"
                              item-value="code"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="shipmentLocal.city"
                              v-validate="'required'"
                              :error-messages="errs.collect('city')"
                              data-vv-name="city"
                              label="City"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-autocomplete
                              v-if="shipmentLocal.country_code == 'CA'"
                              v-model="shipmentLocal.province_code"
                              v-validate="'required|max:2'"
                              clearable
                              outlined
                              dense
                              :error-messages="errs.collect('province_code')"
                              data-vv-name="province_code"
                              :items="provinces"
                              label="Province"
                              item-text="name"
                              item-value="code"
                              dusk="sf_province"
                            />

                            <v-autocomplete
                              v-else-if="shipmentLocal.country_code == 'US'"
                              v-model="shipmentLocal.province_code"
                              v-validate="'required|max:2'"
                              clearable
                              outlined
                              :error-messages="errs.collect('province_code')"
                              data-vv-name="province_code"
                              :items="states"
                              label="State"
                              item-text="name"
                              item-value="code"
                              dense
                              dusk="sf_province"
                            />

                            <v-text-field
                              v-else
                              v-model="shipmentLocal.province_code"
                              clearable
                              outlined
                              dense
                              dusk="sf_province"
                              label="Province/State"
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="shipmentLocal.postal_code"
                              v-validate="'required'"
                              :error-messages="errs.collect('postal_code')"
                              data-vv-name="postal_code"
                              label="Postal Code"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="shipmentLocal.email"
                              v-validate="'email'"
                              :error-messages="errs.collect('email')"
                              data-vv-name="email"
                              label="Email"
                              dense
                              outlined
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item :transition="false">
                  <v-row>
                    <v-col cols="6">
                      <v-card
                        outlined
                        class="pa-5"
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <h2>Store Info</h2>
                          </v-col>
                          <v-col
                            cols="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="shipmentLocal.order_id"
                              outlined
                              dense
                              label="Order ID (Optional)"
                            />
                          </v-col>

                          <v-col
                            v-if="userStores.user_stores.length > 0"
                            cols="6"
                            sm="6"
                          >
                            <v-select
                              v-model="shipmentLocal.user_store_id"
                              :items="userStores.user_stores"
                              label="Store (Optional)"
                              single-line
                              item-text="identifier"
                              item-value="id"
                              outlined
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item :transition="false">
                  <v-row>
                    <v-col cols="6">
                      <v-card
                        outlined
                        class="pa-5"
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <h2>Package</h2>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-select
                              v-model="shipmentLocal.package_type_id"
                              v-validate="'required'"
                              clearable
                              :error-messages="errs.collect('package_type')"
                              data-vv-name="package_type"
                              label="Package Type"
                              :items="packageArray"
                              item-text="description"
                              item-value="id"
                              dense
                              outlined
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-text-field
                              v-model="shipmentLocal.weight"
                              v-validate="'required|min_value:0.001'"
                              :hint="
                                isScaleInitialized()
                                  ? 'Read Scale (Ctrl + m)'
                                  : ''
                              "
                              clearable
                              :error-messages="errs.collect('weight')"
                              data-vv-name="weight"
                              label="Weight"
                              dense
                              outlined
                              type="number"
                            >
                              <template
                                v-if="isScaleInitialized()"
                                #append-outer
                              >
                                <AutoScaleButton @weight="setWeight" />
                              </template>
                            </v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-select
                              v-model="shipmentLocal.weight_unit"
                              v-validate="'required'"
                              clearable
                              :error-messages="errs.collect('weight_unit')"
                              data-vv-name="weight_unit"
                              label="Weight Unit"
                              :items="['kg', 'g', 'oz', 'lbs']"
                              dense
                              outlined
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-text-field
                              v-show="
                                [19, 20].includes(shipmentLocal.package_type_id)
                              "
                              v-model="shipmentLocal.length"
                              v-validate="
                                dimensionsRequired ? 'required' : null
                              "
                              clearable
                              :error-messages="errs.collect('length')"
                              data-vv-name="length"
                              label="Length"
                              dense
                              outlined
                              type="number"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-text-field
                              v-show="
                                [19, 20].includes(shipmentLocal.package_type_id)
                              "
                              v-model="shipmentLocal.width"
                              v-validate="
                                dimensionsRequired ? 'required' : null
                              "
                              clearable
                              :error-messages="errs.collect('width')"
                              data-vv-name="width"
                              label="Width"
                              dense
                              outlined
                              type="number"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-text-field
                              v-show="
                                [19, 20].includes(shipmentLocal.package_type_id)
                              "
                              v-model="shipmentLocal.height"
                              v-validate="
                                dimensionsRequired ? 'required' : null
                              "
                              clearable
                              :error-messages="errs.collect('height')"
                              data-vv-name="height"
                              label="Height"
                              dense
                              outlined
                              type="number"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <!-- $sizeUnits from mixin.js  -->
                            <v-select
                              v-show="
                                [19, 20].includes(shipmentLocal.package_type_id)
                              "
                              v-model="shipmentLocal.size_unit"
                              v-validate="
                                dimensionsRequired ? 'required' : null
                              "
                              clearable
                              :error-messages="errs.collect('size_unit')"
                              data-vv-name="size_unit"
                              label="Unit"
                              :items="$sizeUnits()"
                              dense
                              outlined
                            />
                          </v-col>

                     
                          <v-col cols="12">
                            <p>Total Value: {{ getCombinedValue(shipmentLocal?.items) }}</p>
                          </v-col>
             
                        
                       
                          <v-col
                            v-for="(item, index) in shipmentLocal?.items"
                            :key="index"
                            cols="6"
                          >
                            <v-card
                              outlined
                              class="pa-5"
                            >
                              <v-row dense>
                                <v-col cols="6">
                                  <strong>Line {{ index + 1 }}</strong>
                                </v-col>
                                <v-col
                                  cols="6"
                                  class="text-right"
                                >
                                  <v-btn
                                    color="error"
                                    x-small
                                    depressed
                                    @click="removeItem(index)"
                                  >
                                    Remove
                                  </v-btn>
                                </v-col>

                                <v-col cols="12">
                                  <v-text-field
                                    v-if="shipmentLocal.is_fba"
                                    :ref="`asin_${index}`"
                                    v-model="item.sku"
                                    v-validate="'required'"
                                    outlined
                                    dense
                                    :error-messages="errs.collect(`asin_${index}`)"
                                    label="ASIN"
                                    :data-vv-name="`asin_${index}`"
                                    required
                                  />
                                  <v-text-field
                                    v-else
                                    :ref="`sku_${index}`"
                                    v-model="item.sku"
                                    v-validate="''"
                                    outlined
                                    dense
                                    :error-messages="errs.collect(`sku_${index}`)"
                                    label="SKU"
                                    :data-vv-name="`sku_${index}`"
                                    required
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="item.description"
                                    v-validate="'required'"
                                    outlined
                                    dense
                                    :error-messages="
                                      errs.collect(`description_${index}`)
                                    "
                                    label="Description"
                                    :data-vv-name="`description_${index}`"
                                    required
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field
                                    v-model="item.quantity"
                                    v-validate="'required|min_value:1|integer'"
                                    outlined
                                    dense
                                    :error-messages="errs.collect(`quantity_${index}`)"
                                    label="Quantity"
                                    :data-vv-name="`quantity_${index}`"
                                    required
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field
                                    v-model="item.value"
                                    v-validate="'required|min_value:0.00'"
                                    outlined
                                    dense
                                    :error-messages="errs.collect(`value_${index}`)"
                                    label="Unit Value"
                                    :data-vv-name="`value_${index}`"
                                    required
                                  />
                                </v-col>
                                <v-col cols="4">
                                  <!-- $currencyAsFlatArray() from mixin.js  -->
                                  <v-select
                                    v-model="item.currency"
                                    v-validate="'required'"
                                    :error-messages="errs.collect(`currency_${index}`)"
                                    :data-vv-name="`currency_${index}`"
                                    label="Currency"
                                    :items="$currencyAsFlatArray()"
                                    dense
                                    outlined
                                  />
                                </v-col>
                                <v-col
                                  v-if="showCustomsForm(shipmentLocal.country_code, shipmentLocal.province_code)"
                                  cols="12"
                                >
                                  <v-text-field
                                    :ref="`customs_description_${index}`"
                                    v-model="item.customs_description"
                                    v-validate="''"
                                    outlined
                                    dense
                                    :error-messages="errs.collect(`customs_description_${index}`)"
                                    label="Customs Description"
                                    :data-vv-name="`customs_description_${index}`"
                                    required
                                  />
                                </v-col>
            
                                <v-col
                                  v-if="showCustomsForm(shipmentLocal.country_code, shipmentLocal.province_code)"
                                  cols="6"
                                >
                                  <v-text-field
                                    v-model="item.hs_code"
                                    v-validate="''"
                                    outlined
                                    dense
                                    :error-messages="errs.collect(`hs_code_${index}`)"
                                    label="HS Code"
                                    :data-vv-name="`hs_code_${index}`"
                                    required
                                  />
                                </v-col>
                                <v-col
                                  v-if="showCustomsForm(shipmentLocal.country_code, shipmentLocal.province_code)"
                                  cols="6"
                                >
                                  <v-autocomplete
                                    v-model="item.country_of_origin"
                                    v-validate="''"
                                    outlined
                                    dense
                                    :error-messages="
                                      errs.collect(`country_of_origin_${index}`)
                                    "
                                    :data-vv-name="`country_of_origin_${index}`"
                                    :items="countries"
                                    label="Country of Origin"
                                    item-text="name"
                                    item-value="code"
                                  />
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>

                          <v-col
                            cols="12"
                            class="text-right"
                          >
                            <v-btn
                              color="primary"
                              small
                              depressed
                              class="float-right"
                              @click="addItem"
                            >
                              Add Line
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="6">
                      <v-card
                        outlined
                        class="pa-5"
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <h2>Postage</h2>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-select
                              v-model="shipmentLocal.postage_type_id"
                              v-validate=""
                              clearable
                              :error-messages="errs.collect('postage_type')"
                              data-vv-name="postage_type"
                              :items="postageArray"
                              item-text="description"
                              item-value="id"
                              label="Postage Type"
                              dense
                              outlined
                            />
                          </v-col>

                          <!-- <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-select
                              v-model="shipmentLocal.needs_postage"
                              v-validate="'required'"
                              clearable
                              :error-messages="errs.collect('needs_postage')"
                              data-vv-name="needs_postage"
                              :items="[
                                { value: true, text: 'Yes' },
                                { value: false, text: 'No' }
                              ]"
                              label="Need Postage?"
                              dense
                              outlined
                            />
                          </v-col> -->
                          <!-- <v-col
                            cols="12"
                            sm="6"
                            md="3"
                          >
                            <v-select
                              v-model="shipmentLocal.is_fba"
                              v-validate="'required'"
                              clearable
                              :error-messages="errs.collect('is_fba')"
                              data-vv-name="is_fba"
                              :items="[
                                { value: true, text: 'Yes' },
                                { value: false, text: 'No' }
                              ]"
                              label="Is FBA?"
                              dense
                              outlined
                            />
                          </v-col> -->

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-select
                              v-model="shipmentLocal.signature_confirmation"
                              v-validate=""
                              clearable
                              :error-messages="errs.collect('signature')"
                              data-vv-name="signature"
                              :items="[
                                { value: true, text: 'Yes' },
                                { value: false, text: 'No' }
                              ]"
                              dense
                              outlined
                              label="Signature"
                            />
                          </v-col>
                          <v-col
                            v-if="shipmentLocal.needs_postage == false"
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              v-model="shipmentLocal.tracking_code"
                              v-validate="''"
                              clearable
                              outlined
                              dense
                              :error-messages="errs.collect('tracking_code')"
                              data-vv-name="tracking_code"
                              label="Tracking Number"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-select
                              v-model="shipmentLocal.insured"
                              v-validate=""
                              clearable
                              :error-messages="
                                errs.collect('stallion_protection')
                              "
                              data-vv-name="stallion_protection"
                              :items="[
                                { value: true, text: 'Yes' },
                                { value: false, text: 'No' }
                              ]"
                              dense
                              outlined
                              label="Stallion Protection"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            class="text-center"
                          >
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-btn
                                  color="primary"
                                  :loading="rateLoading"
                                  :disabled="rateLoading || purchaseLoading"
                                  :purchase-loading="rateLoading"
                                  @click="getPostageRates()"
                                  v-on="on"
                                >
                                  Get Rates
                                </v-btn>
                              </template>
                              <span>Get Rates (Ctrl + r)</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="
                            shipmentLocal.needs_postage != false &&
                              postageRates.length > 0
                          "
                        >
                          <v-col cols="12">
                            <h2>Rate Estimates</h2>
                          </v-col>
                          <v-col cols="12">
                            <rates-list
                              show-total
                              :postage-rates="postageRates"
                              :shipment="shipmentLocal"
                              :update-rate-handler="updateShipmentPostage"
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <!-- <v-tab-item :transition="false"> -->
                <!-- <v-row dense>
                    <v-col cols="12">
                      <p>Total Value: {{ getCombinedValue(shipmentLocal?.items) }}</p>
                    </v-col>
                  </v-row> -->

                <!-- </v-tab-item> -->
              </v-tabs-items>
            </v-container>
          </v-form>
        </v-card-text>
      </template>
      <template #actions>
        <!-- Start Batch Process Return Label -->
        <div v-if="isBatchProcess">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                color="primary"
                outlined
                class="mx-1"
                :loading="updateLoading || purchaseLoading"
                @click="saveForBatchProcessing()"
                v-on="on"
              >
                Save
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                color="primary"
                :disabled="shipments.length == 0"
                :loading="updateLoading || purchaseLoading"
                type="submit"
                @click="purchaseForBatchProcessing()"
                v-on="on"
              >
                Process
              </v-btn>
            </template>
            <span>Batch Process</span>
          </v-tooltip>
        </div>
        <!-- End Batch Process Return Label -->
        <div v-else>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                color="primary"
                outlined
                class="mx-1"
                :loading="updateLoading || purchaseLoading"
                @click="save()"
                v-on="on"
              >
                Save
              </v-btn>
            </template>
            <span>Save and exit (Ctrl + s)</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                color="primary"
                :loading="updateLoading || purchaseLoading"
                type="submit"
                @click="submitForm()"
                v-on="on"
              >
                Purchase
              </v-btn>
            </template>
            <span>Purchase (Ctrl + p)</span>
          </v-tooltip>
        </div>
      </template>
    </StallionDialog>

    <apply-preset-dialog
      ref="presetDialog"
      v-model="showPresetsDialog"
      dialog-label="Manage Presets"
      :shipments="[shipmentLocal]"
      @initializeHotkeys="initializeHotkeys"
      @update="onApplyPresets"
    />

    <batch-create-labels
      ref="refCreateLabels"
      v-model="show_batch_create_labels"
      @update="updateShipmentHandler"
    />
  </div>
</template>

<script>
import RatesList from '../rates-list.vue';
import ApplyPresetDialog from '../../../main/shipments/BulkEdit.vue';
import AutoScaleButton from '../AutoScaleButton.vue';
import BaseQuickShipForm from './BaseQuickShipForm.vue';
import CONSTANTS from '@/constants';
import ADDRESS_CONSTANTS from '@/constants/address';
import BatchCreateLabels from '../../../main/shipments/action-bar/CreateLabels/MainDialog.vue';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    AutoScaleButton,
    RatesList,
    ApplyPresetDialog,
    BatchCreateLabels
  },
  extends: BaseQuickShipForm,
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      debounceTimer: null,
      prevShipmentLocal: {}, // Add this to track changes manually
    };
  },

  computed: {
    showQuickShipForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    dialogTitle() {
      if (!this.shipmentLocal) {
        return '';
      }

      if(this.shipmentLocal.return_label){
        this.shipmentLocal.needs_postage = true;
        return this.getReturnLabelnfo(this.shipmentLocal);
      }

      return `Quick Ship - ${
        this.shipmentLocal.display_order_id
          ? this.shipmentLocal.display_order_id
          : this.shipmentLocal.order_id
          ? this.shipmentLocal.order_id
          : this.shipmentLocal.ship_code
          ? this.shipmentLocal.ship_code
          : 'New Order'
      } ${this.shipmentLocal.name ? this.shipmentLocal.name : ''}`;
    }
  },
  watch: {
    showQuickShipForm: {
      handler(newValue, oldValue) {
        if (oldValue === false && newValue === true) {
          // parent component BaseQuickShipForm.vue
          this.initializeShipmentLocal();
        }
      }
    },
    'shipmentLocal': {
      handler(newVal, oldValue) {
        const timeout = oldValue ? 500 : 0; // Initial run should be immediate

        const fields = [
          'address1',
          'address2',
          'city',
          'province_code',
          'country_code',
          'postal_code',
          'weight',
          'weight_unit',
          'package_type_id',
          'return_label',
          'signature_confirmation',
          'insured',
        ];

        const dimFields = [
          'length',
          'width',
          'height',
          'size_unit',
        ];

      // console.log('shipmentLocal', newVal);
      const changedKeys = Object.keys(newVal).filter(key => newVal[key] !== this.prevShipmentLocal[key]);
      // console.log('Changed keys:', changedKeys);

        this.prevShipmentLocal = JSON.parse(JSON.stringify(this.shipmentLocal));

        const changedFields = changedKeys.filter(key => fields.includes(key) || dimFields.includes(key));

        if(changedFields.length === 0) {
          return;
        }

        // console.log('Changed fields:', changedFields);

        this.clearPostageRates();

        // Check if the basic required properties are not null
        const basicConditionsMet = this.shipmentLocal.weight > 0 && this.shipmentLocal.weight_unit && this.shipmentLocal.package_type_id && this.shipmentLocal.country_code && this.shipmentLocal.province_code && this.shipmentLocal.postal_code && this.shipmentLocal.address1 && !this.shipmentLocal.return_label;

        // Additional check for package_type_id 20 for dimensions
        const dimensionsConditionMet = this.shipmentLocal.package_type_id !== 20 || (this.shipmentLocal.length > 0 && this.shipmentLocal.width > 0 && this.shipmentLocal.height > 0);

        console.log('basicConditionsMet', basicConditionsMet);
        console.log('dimensionsConditionMet', dimensionsConditionMet);

        if (basicConditionsMet && dimensionsConditionMet) {
          // Clear the previous timer if it exists
          if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
          }
          // Set a new timer
          this.debounceTimer = setTimeout(() => {
            this.getPostageRates();
          }, timeout); // Using 1000ms (1 second) as per your comment, adjust as needed
        }
      },
      deep: true, // Watch for nested properties
      immediate: false, // Do not run immediately on component mount
    },
  },
  created() {},
  mounted() {},
  methods: {
    // Start Batch Processing Return Label
    onSelectedShipmentChange() {
      // update the shipmentLocal with the selected shipment
      this.shipmentLocal = Object.assign({}, this.shipments[this.selectedShipmentIndex]);
      this.$forceUpdate();
    },
    removeShipment() {
      // remove the selected shipment
      this.shipments = this.shipments.filter(
        shipment => shipment._index !== this.selectedShipmentIndex
      );

      // if there are no shipments left, add a new one as default
      if (this.shipments.length === 0) {
        this.isBatchProcess = false;
        this.shipments.push(this.setReturnLabel({}));
      }

      // update the selected shipment index and shipmentLocal
      this.resetShipmentsIndex()
      this.selectedShipmentIndex = this.shipments.length - 1;
      this.shipmentLocal = Object.assign({}, this.shipments[this.selectedShipmentIndex]);
    },
    addShipment() {
      this.shipments.push(this.setReturnLabel({}));
      this.resetShipmentsIndex()
      // update the selected shipment index and shipmentLocal
      this.selectedShipmentIndex = this.shipments.length - 1;
      this.shipmentLocal = Object.assign({}, this.shipments[this.selectedShipmentIndex]);
    },
    getReturnLabelnfo(item) {
      if (!item) {
        return ''
      }

      return `Return Label - ${
          item.display_order_id
            ? item.display_order_id
            : item.order_id
            ? item.order_id
            : item.ship_code
            ? item.ship_code
            : 'New Order'
        } ${item.name ? item.name : ''}`;
        
    },
    // END Batch Processing Return Label ----------------
    removeItem(index) {
      this.shipmentLocal.items.splice(index, 1);
    },
    addItem() {
      this.shipmentLocal.items.push({ ...CONSTANTS.SHIPMENT_ITEM });
    },
    updateShipmentPostage(rate){
      this.shipmentLocal.postage_type_id =
      rate?.postage_type_id || null
      this.shipmentLocal.rate_id = rate?.id || null
    },
    startReturn(shipments) {
      this.isReturnLabel = true;
      if (!Array.isArray(shipments)) {
        shipments = [shipments ? shipments : {}]
      }

      this.shipments = _cloneDeep(shipments).map(shipment => {
        return this.setReturnLabel(shipment);
      });
      this.resetShipmentsIndex();

      if (this.shipments.length > 1) {
        this.isBatchProcess = true;
      }

      // Set the selected shipment index to the first shipment
      this.selectedShipmentIndex = 0;
      this.shipmentLocal = Object.assign({}, this.shipments[this.selectedShipmentIndex]);

      this.$nextTick(() => {
        this.showQuickShipForm = true;
      })
    },
    setReturnLabel(shipment) {
      let ship = { ...shipment };
      let authUser = this.$auth.user;

      // Initialize return address details
      ship.return_name = ship.name || null;
      ship.return_company = ship.company || null;
      ship.return_address1 = ship.address1 || null;
      ship.return_address2 = ship.address2 || null;
      ship.return_city = ship.city || null;
      ship.return_province_code = ship.province_code || null;
      ship.return_postal_code = ship.postal_code || null;
      ship.return_country_code = ship.country_code || null;
      ship.return_phone = ship.phone || null;
      ship.return_email = ship.email || null;

      const defaultAddress = this.$store.getters['main/default_address'] || {};
      // Determine if ship is "empty"
      const isEmpty = !ship.name && !ship.address1 && !ship.city && 
        !ship.province_code && !ship.postal_code && !ship.country_code;

      if (isEmpty) {
        const addressColumns = ADDRESS_CONSTANTS.ADDRESS_BOOK_TO
        // set default address to ship if it exists in the address book
        if (defaultAddress) {
          Object.keys(addressColumns).forEach((key) => {
            // return_name = name
            // return_address1 = address1
            ship[key] = defaultAddress[addressColumns[key]] || null
          })
        }
        // Set all non address properties to null if ship is considered "empty"
        Object.keys(ship).forEach(key => {
          if (!Object.keys(addressColumns).includes(key)) {
            ship[key] = null;
          }
        });
      } else if (ship.return_country_code === 'CA') {
        // Update address to default or authUser details if country code is 'CA'
        ship.name = defaultAddress?.name || authUser.business_name || authUser.name;
        ship.company = defaultAddress?.company || authUser.company;
        ship.address1 = defaultAddress?.address1 || authUser.address1;
        ship.address2 = defaultAddress?.address2 || authUser.address2;
        ship.city = defaultAddress?.city || authUser.city;
        ship.province_code = defaultAddress?.province_code || authUser.province_code;
        ship.postal_code = defaultAddress?.postal_code || authUser.postal_code;
        ship.country_code = defaultAddress?.country_code || authUser.country_code;
        ship.email = defaultAddress?.email || authUser.email;
        ship.phone = authUser.phone;
      } else {
        // Use default address or return details otherwise
        ship.name = defaultAddress?.name || shipment.return_name;
        ship.company = defaultAddress?.company || shipment.return_company;
        ship.address1 = defaultAddress?.address1 || shipment.return_address1;
        ship.address2 = defaultAddress?.address2 || shipment.return_address2;
        ship.city = defaultAddress?.city || shipment.return_city;
        ship.province_code = defaultAddress?.province_code || shipment.return_province_code;
        ship.postal_code = defaultAddress?.postal_code || shipment.return_postal_code;
        ship.country_code = defaultAddress?.country_code || shipment.return_country_code;
        ship.email = defaultAddress?.email || shipment.return_email;
        ship.phone = shipment.return_phone;
      }

      ship.id = null;
      ship.return_label = true;
      ship.status_id = null
      ship.id = null;
      ship.tracking_code = null;
      if (!Array.isArray(ship.items)) {
        ship.items = [];
      }

      return ship;
    },
  }
};
</script>

<style scoped>
h2 {
  margin-bottom: 0px !important;
}

h4 {
  margin-bottom: 0px !important;
}

#QuickShipFromReturnAddress1::placeholder {
  visibility: hidden !important;
}

#QuickShipToAddress1::placeholder {
  visibility: hidden !important;
}
</style>
