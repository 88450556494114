<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <v-text-field
                  v-model="search"
                  pt-0
                  outlined
                  label="Search"
                  prepend-inner-icon="search"
                  clearable
                  class="pa-0"
                  dense
                  @input="submitSearch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <user-search
                  v-model="user_id"
                  @change="submitSearch()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
                style="padding: 0 5px"
              >
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="start_date"
                      dense
                      prepend-inner-icon="event"
                      outlined
                      label="Start Date"
                      readonly
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="start_date"
                    @change="submitSearch()"
                    @input="menu1 = false"
                  />
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="6"
                style="padding: 0 5px"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="end_date"
                      dense
                      prepend-inner-icon="event"
                      outlined
                      label="End Date"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    @change="submitSearch()"
                    @input="menu2 = false"
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="3"
                style="padding: 0 5px"
              >
                <v-select
                  v-model="return_services_selected"
                  class="pa-0"
                  :items="return_services"
                  item-value="id"
                  item-text="description"
                  outlined
                  label="Service"
                  multiple
                  clearable
                  dense
                  @change="submitSearch()"
                >
                  <template #selection="{ item, index }">
                    <span v-if="index === 0">
                      <span>{{ item.description }}</span>
                    </span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >(+{{ return_services_selected.length - 1 }} others)</span>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                md="3"
                style="padding: 0 5px"
              >
                <v-select
                  v-model="return_statuses_selected"
                  class="pa-0"
                  :items="return_statuses"
                  item-value="id"
                  item-text="description"
                  outlined
                  label="Status"
                  multiple
                  clearable
                  dense
                  @change="submitSearch()"
                >
                  <template #selection="{ item, index }">
                    <span v-if="index === 0">
                      <span>{{ item.description }}</span>
                    </span>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                    >(+{{ return_statuses_selected.length - 1 }} others)</span>
                  </template>
                </v-select>
              </v-col>

              <v-col
                cols="12"
                md="3"
                style="padding: 0 5px"
              >
                <v-select
                  v-model="photo_requested"
                  class="pa-0"
                  :items="photo_requested_list"
                  outlined
                  label="Photo Requested"
                  clearable
                  dense
                  @change="submitSearch()"
                >
                  <!-- <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">
                      <span>{{ item }}</span>
                    </span>
                    <span
                      v-if="index === 1"
                      class="grey--text caption"
                    >(+{{ photo_requested.length - 1 }} others)</span>
                  </template>-->
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="3"
                style="padding: 0 5px"
              >
                <v-select
                  v-model="type"
                  class="pa-0"
                  :items="type_list"
                  outlined
                  label="Type"
                  clearable
                  dense
                  @change="submitSearch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <v-select
                  v-model="current_location_id"
                  dense
                  class="pa-0"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                  outlined
                  label="Current Location"
                  clearable
                  @change="submitSearch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <v-select
                  v-model="destination_location_id"
                  dense
                  class="pa-0"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                  outlined
                  label="Destination Location"
                  clearable
                  @change="submitSearch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <v-text-field
                  v-model="cargo_control_number"
                  pt-0
                  outlined
                  label="Cargo Number"
                  prepend-inner-icon="mdi-truck"
                  clearable
                  class="pa-0"
                  dense
                  @input="submitSearch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <v-text-field
                  v-model="description"
                  pt-0
                  outlined
                  label="Description"
                  clearable
                  class="pa-0"
                  dense
                  @input="submitSearch()"
                />
              </v-col>
              
              <v-col
                cols="12"
                class="text-right"
                style="padding: 0 5px"
              >
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      outlined
                      small
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Export
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      :disabled="selected.length === 0"
                      @click="exportSelected"
                    >
                      <v-list-item-title>
                        Export Currently Selected ({{ selected.length }})
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :disabled="totalItems === 0"
                      @click="exportReturns"
                    >
                      <v-list-item-title>
                        Export Current View ({{ totalItems }})
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>

      <!-- <v-flex xs12 text-center>
        <v-options v-model="options.page" :total-visible="7" :length="numPages" circle></v-options>
      </v-flex>-->
      <v-flex xs12>
        <v-data-table
          v-model="selected"
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          show-select
          item-key="id"
          :options.sync="options"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500]
          }"
          :items-per-page="25"
          @click:row="rowClick"
        >
          <template #item.content_photo="{ item }">
            <div class="mt-3 mb-3">
              {{ item.unique_id }}
              <v-img
                aspect-ratio="1"
                contain
                height="100"
                width="100"
                :src="item.thumbnail_image_url"
              >
                <v-layout
                  slot="placeholder"
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  />
                </v-layout>
              </v-img>
            </div>
          </template>

          <template #item.status_id="{ item }">
            <div v-if="item.images_required">
              (Images Required)
            </div>
            <div v-if="item.status_id === 5">
              <v-btn
                small
                outlined
                color="green"
                @click.stop="updateTabletatus(item, true)"
              >
                Approve
              </v-btn>
              <v-btn
                small
                outlined
                color="red"
                @click.stop="updateTabletatus(item, false)"
              >
                Decline
              </v-btn>
            </div>
            <div
              v-else
              v-html="$options.filters.return_status(item.status_id)"
            />
          </template>

       

          <template #item.service_id="{ item }">
            <div v-html="$options.filters.return_service(item.service_id)" />
          </template>

          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
            <br>
            ({{ item.created_at | datetime_from }})
          </template>

          <template #item.invoice="{ item }">
            <v-btn
              v-if="item.support_document_url"
              icon
              color="primary"
              target="_blank"
              :href="item.support_document_url"
              @click.stop
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>

          <template #item.user_id="{ item }">
            <router-link
              v-if="item.user_id"
              router
              :to="{ name: 'admin-user-show', params: { id: item.user_id } }"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ item.user.id }}
                    <br>
                    {{ item.user.email }}
                  </span>
                </template>
                <span>View User</span>
              </v-tooltip>
            </router-link>
          </template>

          <template #item.order_id="{ item }">
            <router-link
              v-if="item.order_id"
              :to="{
                name: 'admin-return-orders-view',
                params: { id: item.order_id }
              }"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.order_id }}</span>
                </template>
                <span>View Order</span>
              </v-tooltip>
            </router-link>
          </template>

          <template #item.edit="{ item }">
            <v-btn
              router
              :to="{ name: 'admin-return-show', params: { id: item.id } }"
              icon
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </span>
                </template>
                <span>Edit Return</span>
              </v-tooltip>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    filter: {
      type: String,
      default: null
    },
    tripNumber: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      return_services_selected: [],
      return_statuses_selected: [],
      photo_requested: null,
      photo_requested_list: [
        {
          value: 0,
          text: 'No'
        },
        {
          value: 1,
          text: 'Yes'
        }
      ],
      type: null,
      type_list: [
        {
          value: 'domestic',
          text: 'Domestic'
        },
        {
          value: 'us',
          text: 'Us'
        }
      ],

      search: null,
      start_date: null,
      end_date: null,
      loading: false,
      menu1: null,
      menu2: null,
      timer: null,
      users: [],
      userIDs: [],
      userEmails: [],
      user_id: null,
      email: null,
      show: null,
      cargo_control_number: null,
      description: null,

      headers: [
        {
          text: 'Email',
          value: 'user_id',
          sortable: false
        },
        {
          text: 'Unique ID',
          value: 'unique_id'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Service',
          value: 'service_id'
        },
        {
          text: 'Type',
          value: 'type'
        },

        {
          text: 'Order #',
          value: 'order_id'
        },
        {
          text: 'US Rack',
          value: 'us_rack'
        },

        {
          text: 'CA Rack',
          value: 'rack'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: '',
          value: 'edit'
        }
      ],
      searchEmail: null,
      drawer: null,
      searchID: null,

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      totalItems: 0,
      items: [],
      cancelSource: null,
      isExporting: false,

      return_statuses: [],
      return_services: [],
      current_location_id: null,
      destination_location_id: null,
      locations: []
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    user_id: function (val) {
      if (val) {
        var index = this.users.map((e) => e.id).indexOf(val);

        this.email = this.users[index]?.email || null;
      } else {
        this.email = null;
      }
    },
    email: function (val) {
      if (val) {
        var index = this.users.map((e) => e.email).indexOf(val);

        this.user_id = this.users[index]?.id || null;
      } else {
        this.user_id = null;
      }
    },
    filter: function () {
      this.options.page = 1;
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.getServices();
    this.getStatuses();
    this.getLocations();
    Event.listen('update-admin-returns', () => {
      this.updateTable();
    });
  },

  methods: {
    getServices() {
      this.$http
        .get(`/return-services`)
        .then((response) => response.data)
        .then((response) => {
          this.return_services = response;
        });
    },
    getLocations() {
      var params = {
        company_location: true
      };

      this.$http
        .request(`/admin/dropoff-locations`, {
          method: 'get',
          params: params
        })
        .then((response) => response.data)
        .then((response) => {
          this.locations = response;
        });
    },
    getStatuses() {
      this.$http
        .get(`/return-statuses`)
        .then((response) => response.data)
        .then((response) => {
          this.return_statuses = response;
        });
    },
    updateTabletatus(item, approve) {
      var r = item;
      if (approve === true) {
        r.status_id = 10;
        r.status.description = 'Ready';
      } else {
        r.status_id = 1;
        r.status.description = 'Pending Details';
        r.item = null;
        r.value = null;
      }

      this.$http
        .put(`/admin/returns/${r.id}`, r)
        .then((response) => response.data)
        .then((response) => {
          item = response;
          Event.fire('update-admin-returns');
        })
        .finally(() => {
          // this.update_loading = false;
          // this.show = false;
        });
    },
    rowClick(item) {
      this.selected = [item];
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },

    updateTable() {
      this.selected = [];
      this.items = [];
      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
        this.numPages = data.pages;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          user_id: this.user_id,
          trip_number: this.tripNumber,

          photo_requested: this.photo_requested,
          service_ids: this.return_services_selected,
          status_ids: this.return_statuses_selected,
          filter: this.filter,
          current_location_id: this.current_location_id,
          destination_location_id: this.destination_location_id,
          cargo_control_number: this.cargo_control_number,
          description: this.description,
          type: this.type
        };

        this.$http
          .request(`/admin/returns`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },

    exportReturns() {
      console.log('exportReturns 2', this);

      this.isExporting = true;

      const { sortBy, sortDesc } = this.options;

      var params = {
        sort_by: sortBy[0],
        desc: sortDesc[0],
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        user_id: this.user_id,
        trip_number: this.tripNumber,
        cargo_control_number: this.cargo_control_number,
        description: this.description,

        photo_requested: this.photo_requested,
        service_ids: this.return_services_selected,
        status_ids: this.return_statuses_selected,
        filter: this.filter
      };

      this.$http
        .request(`/admin/returns/export`, {
          method: 'post',
          params: params
        })
        .then((response) => {
          this.downloadFileUrl(response.data, 'Stallion_Returns.csv', 'text/csv');
        })
        .catch((err) => {
          console.log('Exporting error: ', err);
        })
        .finally(() => {
          this.isExporting = false;
        });
    },

    exportSelected() {
      this.isExporting = true;

      var return_ids = this.selected.map((x) => x.id);

      var params = {
        return_ids: return_ids
      };

      this.$http
          .post('/admin/returns/export', params)
          .then((response) => {
            this.downloadFileUrl(response.data, `Stallion_Returns.csv`, 'text/csv');
          })
          .catch(() => {
            this.errorMessage('Nothing to export');
          })
          .finally(() => {
            this.isExporting = false;
          });
    },
  }
};
</script>
